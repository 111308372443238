<ion-content>
    <app-header></app-header>
    <div class="fixedBox">
        <span class="co2PdfTitle">{{ 'getCO2PDFTitle' | translate }}</span>
        <br>
        <a class="co2PdfDownload" download="" href="{{getCO2PdfLink()}}">Download</a>
    </div>

    <div class="taxiHotel">
        <div class="container">
            <form class="orderForm">
                <mat-form-field class="form-group search">
                    <mat-icon matPrefix>search</mat-icon>
                    <input matInput
                           [(ngModel)]="query"
                           name="searchId">
                </mat-form-field>
            </form>

            <div class="monthhead">
                <ul>
                    <li>
                        <button mat-mini-fab
                                class="customBtn"
                                (click)="prevDate()">
                            <mat-icon>arrow_back</mat-icon>
                        </button>
                    </li>
                    <li>
                        <span class="text">{{controlDate | date:'MMMM y'}}</span>
                    </li>
                    <li>
                        <button mat-mini-fab
                                class="customBtn"
                                (click)="addDate()">
                            <mat-icon>arrow_forward</mat-icon>
                        </button>
                    </li>
                </ul>
            </div>
            <div class="monthdata">
                <div class="group">
                    <div class="item" *ngFor="let trip of trips | searchFilter: query;let i=index">
                        <div class="date"
                             *ngIf="i==0 || ((trip.pickupTime | date:'shortDate') != (trips[i-1]?.pickupTime | date:'shortDate'))">
                            {{trip.pickupTime | date:'EEEE, d.M.yy'}}
                        </div>

                        <ul class="info">
                            <li *ngIf="trip.bookingNumber">{{'resNo' | translate}}: {{trip.bookingNumber}}</li>
                            <li *ngIf="trip.roomNumber">{{'roomNo' | translate}}:
                                {{trip.roomNumber}}</li>
                            <li *ngIf="trip.guestName">{{'guestName' | translate}}:
                                {{trip.guestName}}</li>
                            <li>{{'tel' | translate}}: {{trip.phoneNumber}}</li>
                            <li *ngIf="trip.flightNumber">{{'flightno' | translate }}: {{trip.flightNumber}}</li>
                            <li *ngIf="trip.orderType === orderType.Hotel && trip.receptionistInitials"> {{'receptionistInitialsLabel' | translate}}:
                                {{trip.receptionistInitials}} </li>
                        </ul>

                        <div class="row">
                            <div class="box">
                                <ul>
                                    <li *ngIf="trip.from">
                                        <mat-icon>place</mat-icon> {{'from' | translate}}: {{trip.from?.name}}
                                    </li>
                                    <li *ngIf="!isNotOrderedYet(trip)">
                                        <mat-icon>watch_later</mat-icon> {{'time' | translate}}:
                                        <span *ngIf="lang=='en'">{{trip.pickupTime + 'Z' | date:'shortTime'}}</span>
                                        <span *ngIf="lang!='en'">{{trip.pickupTime + 'Z' | date:'HH:mm'}}</span>
                                    </li>
                                    <li *ngIf="isNotOrderedYet(trip) && isFlightArrivalTimeManuallyEntered(trip)">
                                        <mat-icon>watch_later</mat-icon>
                                        {{ 'flightArrivalTime-unknown-manualPickUpTime' | translate }}:
                                        <span *ngIf="lang=='en'">{{trip.pickupTime + 'Z' | date:'shortTime'}}</span>
                                        <span *ngIf="lang!='en'">{{trip.pickupTime + 'Z' | date:'HH:mm'}}</span>
                                    </li>
                                    <li *ngIf="isNotOrderedYet(trip) && !isFlightArrivalTimeManuallyEntered(trip)">
                                        <mat-icon>watch_later</mat-icon>
                                        {{ 'flightArrivalTime-unknown-autoPickUpTime' | translate }}:
                                        <span *ngIf="lang=='en'">{{trip.pickupTime + 'Z' | date:'shortTime'}}</span>
                                        <span *ngIf="lang!='en'">{{trip.pickupTime + 'Z' | date:'HH:mm'}}</span>
                                    </li>
                                    <li>
                                        <mat-icon>payments</mat-icon>
                                        <span>{{ getTranslationKey(trip) | translate }}</span>
                                    </li>
                                    <li *ngIf="trip.comment">
                                        {{ 'commentToDriverLabel' | translate }}: {{ trip.comment }}
                                    </li>
                                    <li>
                                        {{ formatOptions(trip) }}
                                    </li>
                                </ul>
                            </div>
                            <div class="box">
                                <ul>
                                    <li *ngIf="trip.to">
                                        <mat-icon>place</mat-icon> {{'to' | translate}}: {{trip.to?.name}}
                                    </li>
                                    <li *ngIf="trip.withCustomer != null">
                                        <mat-icon>hourglass_full</mat-icon>
                                        {{'drivingTime' | translate}} {{ trip.withCustomer }} min.
                                    </li>
                                    <li *ngIf="trip.eta != null">
                                        <mat-icon>hourglass_full</mat-icon>
                                        {{ getEtaTranslation(trip.withCustomer) }}
                                    </li>
                                    <li *ngIf="trip.cO2Compensation">
                                        <mat-icon>co2</mat-icon>
                                        {{'co2Compensation' | translate}} {{ trip.cO2Compensation }} kg.
                                    </li>  
                                    <li *ngIf="trip.rating">
                                        <mat-icon>star_rate</mat-icon>
                                        {{'rating' | translate}} {{ trip.rating }} {{'out-of-five' | translate}}
                                    </li>
                                    <li *ngIf="trip.guestComment">
                                        {{ 'guest-comment-label' | translate }}: {{ trip.guestComment }}
                                    </li>
                                    <li *ngIf="trip.receptionComment">
                                        {{ 'reception-comment-label' | translate }}: {{ trip.receptionComment }}
                                    </li> 
                                    <li [ngClass]="{'cancelled': isNotComming(trip)}">
                                        {{ getTaxiStatusText(trip) }}
                                    </li>
                                    <li *ngIf="isOrdered(trip) && trip.updatable && (trip.orderType === orderType.Terminal || trip.orderType === orderType.Hotel || trip.orderType === orderType.Assistant)">
                                        <button mat-stroked-button (click)="updateRide(trip)">
                                            {{'update-ride' | translate}}
                                        </button>
                                    </li>
                                    <li *ngIf="!isTaxiMeterStarted(trip) 
                                            && !isCancelled(trip) 
                                            && !isCompleted(trip) 
                                            && !isUnknown(trip) 
                                            && !isInVain(trip)  
                                            && !isNoShow(trip)  
                                            && !isArrived(trip)
                                            && (trip.orderType === orderType.Terminal || trip.orderType === orderType.Hotel || trip.orderType === orderType.Assistant)">
                                        <button mat-stroked-button (click)="cancelRide(trip)">
                                            {{'cancelTaxi' | translate}}
                                        </button>
                                    </li>
                                    <li>
                                        <button mat-stroked-button color="danger" (click)="openComment(trip.id, trip.receptionComment)" 
                                            *ngIf="(isCompleted(trip) || isCancelled(trip) || isInVain(trip) || isNoShow(trip) || isUnknown(trip))">
                                            {{ 'comment-button-label' | translate }}
                                        </button>
                                    </li>
                                    <li *ngIf="trip.paymentIntentId" class="download-button">
                                        <button mat-stroked-button color="danger" (click)="openReceipt(trip.id)">
                                            {{ 'downloadReciept' | translate }}
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <span class="separator">
                            <b *ngIf="trip.shareRideId != null">{{'ridesharing' | translate}}</b>
                        </span>
                    </div>

                    <div class="loadMore" *ngIf="shouldShowLoadMoreButton()">
                        <button mat-stroked-button (click)="loadMore()">{{ 'load-more' | translate}}</button>
                    </div>

                    <div class="item"
                         *ngIf="trips?.length == 0">
                        No Trips Found
                    </div>
                </div>
            </div>

        </div>
    </div>
</ion-content>