import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-taxi',
  templateUrl: './order-taxi.component.html',
  styleUrls: ['./order-taxi.component.scss']
})
export class OrderTaxiComponent implements OnInit {

  hotelId: any;
  airportId: any;
  flightDate: any;
  bookingNumber: any;
  guestName: any;
  constructor(private router: Router, private translate: TranslateService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hotelId = params['hotelId'];
      this.airportId = params['airportId'];
      this.flightDate = params['flightDate'];
      this.bookingNumber = params['bookingNumber'];
      this.guestName = params['guestName'];
    });

    let lang = localStorage.getItem("lang");
    if (lang == 'en') {
      document.getElementById('button_en')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'da') {
      document.getElementById('button_da')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'no') {
      document.getElementById('button_no')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'se') {
      document.getElementById('button_se')?.classList.add('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'fi') {
      document.getElementById('button_fi')?.classList.add('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
    }
    else {
      document.getElementById('button_de')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
  }

  goToSplash() {
    // this.router.navigateByUrl('/taxi-to-hotel');
    this.router.navigateByUrl('/taxi-to-hotel?hotelId=' + this.hotelId +
      '&airportId=' + this.airportId + '&flightDate=' + this.flightDate + '&bookingNumber=' + this.bookingNumber +
      "&guestName=" + this.guestName);
  }

  useLanguage(lang: any) {
    if (lang == 'en') {
      document.getElementById('button_en')?.classList.add('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'da') {
      document.getElementById('button_da')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'no') {
      document.getElementById('button_no')?.classList.add('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'se') {
      document.getElementById('button_se')?.classList.add('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
      document.getElementById('button_fi')?.classList.remove('btnactive');
    }
    else if (lang == 'fi') {
      document.getElementById('button_fi')?.classList.add('btnactive');
      document.getElementById('button_se')?.classList.remove('btnactive');
      document.getElementById('button_no')?.classList.remove('btnactive');
      document.getElementById('button_en')?.classList.remove('btnactive');
      document.getElementById('button_de')?.classList.remove('btnactive');
      document.getElementById('button_da')?.classList.remove('btnactive');
    }
    this.translate.setDefaultLang(lang);
    localStorage.setItem("lang", lang);
    sessionStorage.setItem("langChosen", "true");
  }
}
