import { loadStripeTerminal, Terminal, StripeTerminal as StripeTerminalPackage, ErrorResponse, DiscoverResult, Reader, ISdkManagedPaymentIntent } from '@stripe/terminal-js';
import { CollectPaymentMethodResponse, TerminalReader } from "src/app/models/payment/terminal.interface";
import { environment } from "src/environments/environment";
import { PaymentTerminal } from "./payment-terminal";
import { IPaymentIntentResponse } from 'src/app/models/payment-intent-response.interface';
import { IStripeLogging } from 'src/app/models/stripe-logging.interface';

export class StripeTerminal extends PaymentTerminal {
    private stripeTerminal?: StripeTerminalPackage;
    private internalTerminal?: Terminal;
    private discoveredReaders: Array<Reader> = [];
    private selectedReaderStripe?: Reader;
    private _selectedReader?: TerminalReader;
    private loggingObject: IStripeLogging = { statusTimer: 0, hasAlreadyBeenDisconnection: false, hotelId: "", terminalLocationId: "", terminalReaderId: "" }

    public async setupTerminal(hotelId: string, terminalLocationId: string, terminalReaderId: string): Promise<void> {
        if (!terminalLocationId || !terminalReaderId) return Promise.reject("Terminal location and reader ids are required for Stripe terminals.");

        this.stripeTerminal = await loadStripeTerminal() ?? undefined;

        if (!this.stripeTerminal) {
            console.error("Stripe terminal did not initialize correctly");
            return;
        }

        this.internalTerminal = this.stripeTerminal?.create({
            onFetchConnectionToken: async () => await this.fetchConnectionToken(hotelId),
            onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
        });

        this.discoverReaderHandler(terminalLocationId).then(
            () => this.connectReaderHandler(this.discoveredReaders, terminalReaderId)
        );

        this.loggingObject.hotelId = hotelId;
        this.loggingObject.terminalLocationId = terminalLocationId
        this.loggingObject.terminalReaderId = terminalReaderId;
        //this.terminalStatusChecker()
    }

    public get selectedReader(): TerminalReader | undefined {
        return this._selectedReader;
    }

    async fetchConnectionToken(hotelId: string): Promise<string> {
        console.log('fetchConnectionToken');
        return await this.paymentApiService.getPaymentToken(hotelId).toPromise().then(dq => dq.secret);
    }

    unexpectedDisconnect(): void {
        console.log('Disconnected from reader');
    }

    async discoverReaderHandler(locationId: string): Promise<void> {
        const config = { simulated: environment.selfServiceStation_IsTerminalSimulated, location: locationId };
        const discoverResult = await this.internalTerminal?.discoverReaders(config);

        if (!discoverResult || (discoverResult as ErrorResponse).error) {
            console.log('Failed to discover: ', (discoverResult as ErrorResponse).error);
        } else if ((discoverResult as DiscoverResult).discoveredReaders.length === 0) {
            console.log('No available readers.');
        } else {
            this.discoveredReaders = (discoverResult as DiscoverResult).discoveredReaders;
            console.log('terminal.discoverReaders', this.discoveredReaders);
        }
    }

    async connectReaderHandler(discoveredReaders: Array<Reader>, terminalReaderId: string): Promise<void> {
        this.selectedReaderStripe = environment.selfServiceStation_IsTerminalSimulated ? discoveredReaders[0] : discoveredReaders.find(el => el.id == (terminalReaderId));
        if (!this.selectedReaderStripe) {
            console.warn("Could not find reader with id: " + terminalReaderId);
            alert("Could not find terminalReader with id: " + terminalReaderId);
            return;
        }

        console.log('selectedReader', this.selectedReaderStripe);

        this._selectedReader = {
            label: this.selectedReaderStripe?.label,
        }

        const connectResult = await this.internalTerminal?.connectReader(this.selectedReaderStripe!);
        if (!connectResult) {
            console.error("Could not connect to reader");
            return Promise.reject("Could not connect to reader");
        }

        if ((connectResult as ErrorResponse).error) {
            console.log('Failed to connect: ', (connectResult as ErrorResponse).error);
        } else {
            console.log('Connected to reader: ', (connectResult as {
                reader: Reader;
            }).reader.label);
            console.log('terminal.connectReader', connectResult);
        }
    }

    public cancelCollectPaymentMethod(): void {
        this.internalTerminal?.cancelCollectPaymentMethod();
    }

    public async collectPayment(paymentIntentData: IPaymentIntentResponse): Promise<CollectPaymentMethodResponse> {
        if (!paymentIntentData.clientSecret) return Promise.reject("No client secret was provided for Stripe integration");

        const collectPaymentMethodResponse = await this.internalTerminal?.collectPaymentMethod(paymentIntentData.clientSecret);

        if (!collectPaymentMethodResponse) {
            return Promise.reject("Could not collect payment method");
        }

        if ((collectPaymentMethodResponse as ErrorResponse).error) {
            return Promise.reject((collectPaymentMethodResponse as ErrorResponse).error);
        }

        const collectPaymentMethodPaymentIntent = (collectPaymentMethodResponse as {
            paymentIntent: ISdkManagedPaymentIntent;
        }).paymentIntent;

        const processPaymentResponse = await this.internalTerminal?.processPayment(collectPaymentMethodPaymentIntent);

        if (!processPaymentResponse) {
            return Promise.reject("Could not process payment");
        }

        if ((processPaymentResponse as ErrorResponse).error) {
            return Promise.reject((processPaymentResponse as ErrorResponse).error);
        }

        return Promise.resolve({ error: false, paymentIntent: { id: collectPaymentMethodPaymentIntent.id } });
    }

    private async terminalStatusChecker() {
        clearTimeout(this.loggingObject.statusTimer);

        var status = this.internalTerminal!.getConnectionStatus();

        if (status.toString() == "not_connected") {
            if (this.loggingObject.hasAlreadyBeenDisconnection) {
                var logObject = {
                    hotelId: this.loggingObject.hotelId,
                    terminalLocationId: this.loggingObject.terminalLocationId,
                    terminalReader: this.loggingObject.terminalReaderId
                }
                this.log.error("Terminal not connected", logObject);
            }
            else
                this.loggingObject.hasAlreadyBeenDisconnection = true;
        }
        else
            this.loggingObject.hasAlreadyBeenDisconnection = false;

        this.loggingObject.statusTimer = window.setTimeout(() => this.terminalStatusChecker(), 900000);
    }
}
