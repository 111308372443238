<div>
    <google-map [center]="center" [zoom]="zoom" [options]="mapOptions" [height]="height" [width]="width">
    <map-marker
            *ngFor="let marker of markers"
            [position]="marker?.position"
            [label]="marker?.label"
            [title]="marker?.title"
            [options]="marker?.options">
    </map-marker>
</google-map></div>
