import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Component({
  selector: 'app-simple-date-picker',
  templateUrl: './simple-date-picker.component.html',
  styleUrls: ['./simple-date-picker.component.scss']
})
export class SimpleDatePickerComponent implements OnInit {

  currentDate = new Date();
  @Output() date: EventEmitter<Date> = new EventEmitter<Date>();

  constructor() { }

  ngOnInit(): void {
  }

  previous() {
    this.currentDate = this.addDays(this.currentDate, -1);
    this.date.emit(this.currentDate);
  }

  next() {
    this.currentDate = this.addDays(this.currentDate, 1);
    this.date.emit(this.currentDate);
  }

  addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

}
