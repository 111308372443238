import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'app-price-of-large-taxi',
  templateUrl: './price-of-large-taxi.component.html',
  styleUrls: ['./price-of-large-taxi.component.scss']
})
export class PriceOfLargeTaxiComponent implements OnDestroy {

  text?: string;

  largeTaxiPriceSubscription: Subscription;

  constructor(priceService: PriceService, translate: TranslateService) {
    this.largeTaxiPriceSubscription = priceService.largeTaxiPrice.subscribe(p => {
      if (p)
        this.text = translate.instant('largeTaxiPrice')
          .replace('{price}', p.price)
          .replace('{currency}', p.currency);
      else
        this.text = '';
    });
  }

  ngOnDestroy(): void {
    this.largeTaxiPriceSubscription.unsubscribe();
  }

}
