import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDestination } from '../../models/destination.interface';
import { EstablishmentType } from 'src/app/services/hotel.service';

export interface InitPopupMobileRestaurantComponentArgs {
  logoSrc?: string;
  locations?: IDestination[] | undefined;
  establishmentType: EstablishmentType;
}

@Component({
  selector: 'app-init-popup-mobile',
  templateUrl: './init-popup-mobile-restaurant.component.html',
  styleUrls: ['./init-popup-mobile-restaurant.component.scss']
})

export class InitPopupMobileRestaurantComponent {

  private _logoSrc: string = 'assets/images/logo_white.svg';
  locations: IDestination[] | undefined = undefined;
  establishmentType: EstablishmentType = 0;

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupMobileRestaurantComponentArgs, public dialogRef: MatDialogRef<InitPopupMobileRestaurantComponent>, private translate: TranslateService) {
    this.logoSrc = data.logoSrc;
    this.locations = data.locations
    this.establishmentType = data.establishmentType;
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  updateComponentData(logoSrc: string | undefined, specialLocations: IDestination[]) {
    this.logoSrc = logoSrc;
    this.locations = specialLocations;
  }
}
