import {Component, OnDestroy, OnInit} from '@angular/core';
import Timeout = NodeJS.Timeout;

@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss']
})
export class TimeoutPopupComponent implements OnInit, OnDestroy{
  seconds = 60;
  timeToReset: ReturnType<typeof setTimeout> | undefined ;
  constructor() {
  }

  ngOnInit(): void {
   this.timeToReset =  setInterval(() => {
      if (this.seconds > 0) { this.seconds--; }
    }, 1000);
  }
  ngOnDestroy(): void {
    if  (this.timeToReset) { clearInterval(this.timeToReset); }
  }
}
