import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-reception-comment',
  templateUrl: './reception-comment.component.html',
  styleUrls: ['./reception-comment.component.scss']
})
export class ReceptionCommentComponent {
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>, public translate: TranslateService, private fb: FormBuilder) {
    this.formGroup = fb.group({
      comment: new FormControl(data.comment)
    });
  }

  get comment() {
    return this.formGroup.get('comment');
  }

  confirm() {
    this.dialogRef.close(this.comment?.value);
  }

  cancel() {
    this.dialogRef.close();
  }
}
