<div class="container">
    <mat-dialog-content class="content">
        <h2>{{ "duplicatePhoneNumberPopup" | translate }}</h2>
        <h2>{{ data.phoneNo }}</h2>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="clean-btn yes" (click)="yes()" cdkFocusInitial>
                <!-- <button class="clean-btn new-session" [mat-dialog-close]="true" cdkFocusInitial> -->
                {{ "yes" | translate }}
            </button>
            <button class="clean-btn no" (click)="no()">{{ "no" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
