import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CancelledByDriver, TaxiStatus } from 'src/app/models/enums';
import { IRide, IRideCancelledByDriver } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-cancelled-by-driver',
  templateUrl: './cancelled-by-driver.component.html',
  styleUrls: ['./cancelled-by-driver.component.scss']
})

export class CancelledByDriverComponent {
  rides: any[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<CancelledByDriverComponent>) {
    this.rides = data;
  }

  public close() {
    this.dialogRef.close();
  }

  statusInVain(ride: any) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusUnknown(ride: any) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  statusCancelled(ride: any) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  statusNoShow(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  getIsReOrdered(ride: IRideCancelledByDriver) {
    return ride.cancelledByDriver == CancelledByDriver.ReOrdered;
  }
}
