import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gdpr-popup',
  templateUrl: './gdpr-popup.component.html',
  styleUrls: ['./gdpr-popup.component.scss']
})
export class GdprPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
