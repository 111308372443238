import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.scss']
})

export class VoucherComponent {

  voucherFG: FormGroup;

  maxValue: number = 100;
  stepvalue: number = 0.1;

  constructor(private fb: FormBuilder) {
    this.voucherFG = fb.group({
      valueSlider: [0]
    });

    this.valueSlider?.valueChanges.subscribe(data => {
      this.valueSliderCheck(data);
    });
  }

  valueSliderCheck(data: number) {
    if (data == this.maxValue)
      console.log("Hit");
    else
      console.log("Didnt hit")
  }

  get valueSlider() {
    return this.voucherFG.get('valueSlider');
  }
}


