import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class InactivityService implements OnDestroy {
    private inactivityTimeout: any;
    private titleSwitchInterval: any;
    private readonly INACTIVITY_DURATION = 4 * 60 * 60 * 1000; // 4 hours in milliseconds
    private readonly TITLE_SWITCH_INTERVAL = 30000; // 30 seconds
    private originalTitle = document.title;
    private isShowingOriginalTitle = true;
    //private originalFavicon: string; //Removed the icon as it wasnt wanted

    constructor() {
        //this.originalFavicon = this.getFaviconHref();

        // Set up activity listeners
        this.resetInactivityTimer();
        this.addActivityListeners();
        this.addUnloadListener();
    }

    ngOnDestroy(): void {
        this.clearInactivityTimer();
        this.clearTitleSwitchInterval();
        this.removeActivityListeners();
        this.removeUnloadListener();
    }

    private addActivityListeners(): void {
        ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
            window.addEventListener(event, this.onUserActivity.bind(this));
        });
    }

    private removeActivityListeners(): void {
        ['mousemove', 'keydown', 'mousedown', 'touchstart'].forEach((event) => {
            window.removeEventListener(event, this.onUserActivity.bind(this));
        });
    }

    private onUserActivity(): void {
        // User activity detected, reset to original state
        this.resetToOriginalState();
        this.resetInactivityTimer(); // Restart the inactivity timer
    }

    private addUnloadListener(): void {
        window.addEventListener('beforeunload', this.resetToOriginalState.bind(this));
    }

    private removeUnloadListener(): void {
        window.removeEventListener('beforeunload', this.resetToOriginalState.bind(this));
    }

    private resetInactivityTimer(): void {
        this.clearInactivityTimer();
        this.clearTitleSwitchInterval(); // Stop switching titles if the user is active

        this.inactivityTimeout = setTimeout(() => {
            this.onInactivity();
        }, this.INACTIVITY_DURATION);
    }

    private clearInactivityTimer(): void {
        if (this.inactivityTimeout) {
            clearTimeout(this.inactivityTimeout);
            this.inactivityTimeout = null;
        }
    }

    private onInactivity(): void {
        this.startTitleSwitching();
    }

    private startTitleSwitching(): void {
        this.clearTitleSwitchInterval(); // Ensure no previous interval is running

        this.titleSwitchInterval = setInterval(() => {
            if (this.isShowingOriginalTitle) {
                this.changeTabTitle('You’ve been away for a while!');
                //this.changeFavicon('assets/images/taxilogo-blue.svg'); // Path to the "away" favicon
            } else {
                this.changeTabTitle(this.originalTitle);
                //this.changeFavicon(this.originalFavicon);
            }
            this.isShowingOriginalTitle = !this.isShowingOriginalTitle;
        }, this.TITLE_SWITCH_INTERVAL);
    }

    private clearTitleSwitchInterval(): void {
        if (this.titleSwitchInterval) {
            clearInterval(this.titleSwitchInterval);
            this.titleSwitchInterval = null;
        }
    }

    private changeTabTitle(newTitle: string): void {
        document.title = newTitle;
    }

    /*private changeFavicon(newFaviconPath: string): void {
        const faviconElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        if (faviconElement) {
            faviconElement.href = newFaviconPath;
        }
    }

    private getFaviconHref(): string {
        const faviconElement = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
        return faviconElement?.href || '';
    }*/

    public resetToOriginalState(): void {
        // Restore the original title, favicon, and clear any intervals
        this.clearTitleSwitchInterval();
        this.changeTabTitle(this.originalTitle);
        //this.changeFavicon(this.originalFavicon);
    }
}
