<div class="container">
    <app-station-header [logoSrc]="hotelInfo.logoSrc" [language]="hotelInfo.language"></app-station-header>
    <!--Standard-->
    <div class="main" *ngIf="whatToShow==0">
        <form [formGroup]="taxiOrderForm">
            <div class="vertical-flexer">
                <div class="box right-margin">
                    <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <div class="current-location-container">
                        <ion-input disabled="true"
                                class="border current-location input-shadow-readonly"
                                [value]="hotelInfo.name">
                            <mat-icon class="map">location_on</mat-icon>
                        </ion-input>
                        <span class="current-location-address">{{ hotelInfo.address }}</span>
                    </div>
        
                    <div *ngIf="noDestinationAllowed && payInTaxiAllowed" class="margin-top-10">
                        <mat-checkbox color="primary"
                        (change)="onNoDestination($event)"
                        class="asap checkbox">{{ "noDestination" | translate }}</mat-checkbox>
                    </div>
        
                    <div class="title">{{ "selfServiceStation_destinationTitle" | translate }}</div>
                    <div class="error-message"
                        *ngIf="taxiOrderForm.controls['destination'].invalid">{{ "selfServiceStation_destinationError" |
                        translate }}</div>
        
                    <div class="destination">
                        <app-destination-selector class="control destination"
                                                formControlName="destination"
                                                [showErrors]="this.taxiOrderForm.controls['destination'].value?.name === undefined && isSubmit"
                                                [hotelInfo]="hotelInfo"
                                                [fromHotel]="false"
                                                [disabled]="noDestinationSelected"></app-destination-selector>
                    </div>
                    
                    <div *ngIf="!noDestinationSelected" class="price">
                        <div class="m-0 title">{{ priceText }}</div>
                        <div>
                            <ion-input readonly="true"
                                class="border price-ddk input-shadow-bottom"
                                [value]='getPrice()'></ion-input>
                            <ion-input readonly="true"
                                class="border price-euro input-shadow"
                                [value]='getEuroPrice()'></ion-input>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="title m-t-8">{{ "selfServiceStation_dateTimeTitle" | translate }}</div>
                    <div class="pick-up">
                        <app-date-time-picker [maxDate]='maxDate'
                                            formControlName="pickupDateTime"
                                            class="date-time-picker"
                                            [autoIncrementTime]="true"
                                            (click)="usingDatePicker()"
                                            (change)="dateChange()"></app-date-time-picker>
                        <mat-checkbox color="primary"
                                    (change)="onAsap($event)"
                                    formControlName="isAsap"
                                    class="asap checkbox">{{ "selfServiceStation_asapText" | translate }}</mat-checkbox>
                    </div>
        
                    <div class="title">{{ "selfServiceStation_fullNameTitle" | translate }}</div>
                    <ion-input class="border control input-shadow full-name"
                                formControlName="fullName"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_fullNamePlaceHolder' | translate}}"></ion-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['fullName'].invalid && isSubmit">{{ "selfServiceStation_fullNameError" |
                        translate }}
                    </div>
        
                    <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
                    <app-phone-number-input class="phone-number"
                                            (onPressNumber)="phoneSelect($event)"
                                            (click)="scrollToBottom()"
                                            [hotelId]="hotelId"></app-phone-number-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                        "selfServiceStation_phoneNumberError" | translate }}
                    </div>
        
                    <div *ngIf="!payInTaxi">
                        <div class="title">{{ "selfServiceStation_emailTitle" | translate }}</div>
                        <ion-input type="email"
                                class="border email control input-shadow"
                                formControlName="email"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_emailPlaceholder' | translate}}"
                                email></ion-input>
                        <div class="error-message"
                            *ngIf="taxiOrderForm.controls['email'].invalid">{{ "selfServiceStation_emailError" | translate }}</div>
                    </div>  
                </div>
            </div>


            <div *ngIf="payInTaxiAllowed" class="payInTaxiContainer">
                <div class="title m-t-8">{{ "paymentOptions" | translate }}</div>
                <mat-checkbox color="primary"
                    (change)="onPayInTaxiSelected($event)"
                    class="payInTaxi checkbox width"                    
                    [checked]="payInTaxi"
                    [disabled]="noDestinationSelected || onlyPayInTaxi">{{ "payInTaxi" | translate }}</mat-checkbox>
            </div>

            <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="onClick()"
                        color="primary">{{ getOrderButtonText() }}</button>
            </div>

            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>
        </form>
    </div>
        
    <!--Here and now-->
    <div class="main" *ngIf="whatToShow==1">
        <form [formGroup]="taxiOrderForm">
            <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
            <app-phone-number-input class="phone-number"
                                    (onPressNumber)="phoneSelect($event)"
                                    (click)="scrollToBottom()"
                                    [hotelId]="hotelId"></app-phone-number-input>
            <div class="error-message"
                    *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                "selfServiceStation_phoneNumberError" | translate }}
            </div>

            <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="orderHereAndNow()"
                        color="primary">{{ getOrderButtonText() }}</button>
            </div>

            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>
        </form>
    </div>

    <!--ToAirport-->
    <div class="main" *ngIf="whatToShow==2">
        <form [formGroup]="taxiOrderForm">
            <div class="vertical-flexer">
                <div class="box right-margin">
                    <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <div class="current-location-container" style="margin-bottom: 25px">
                        <ion-input disabled="true"
                                class="border current-location input-shadow-readonly"
                                [value]="hotelInfo.name">
                            <mat-icon class="map">location_on</mat-icon>
                        </ion-input>
                        <span class="current-location-address">{{ hotelInfo.address }}</span>
                    </div>

                    <div class="airport-group">
                        <div *ngFor="let airport of airports">
                            <mat-checkbox #checkBox [value]="airport.id" (change)="airportChange(checkBox)" [checked]="airport.id === checkedAirportId" class="checkbox" color="primary" >  
                                {{airport.name}} 
                        </mat-checkbox>
                        </div>
                    </div>

                    <div class="price">
                        <div class="title">{{ priceText }}</div>
                        <div>
                            <ion-input readonly="true"
                                class="border price-ddk input-shadow-bottom"
                                [value]='getPrice()'></ion-input>
                            <ion-input readonly="true"
                                class="border price-euro input-shadow"
                                [value]='getEuroPrice()'></ion-input>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="title m-t-8">{{ "selfServiceStation_dateTimeTitle" | translate }}</div>
                    <div class="pick-up">
                        <app-date-time-picker [maxDate]='maxDate'
                                            formControlName="pickupDateTime"
                                            class="date-time-picker"
                                            [autoIncrementTime]="true"
                                            (click)="usingDatePicker()"
                                            (change)="dateChange()"></app-date-time-picker>
                        <mat-checkbox color="primary"
                                    (change)="onAsap($event)"
                                    formControlName="isAsap"
                                    class="asap checkbox">{{ "selfServiceStation_asapText" | translate }}</mat-checkbox>
                    </div>

                    <div class="title">{{ "selfServiceStation_fullNameTitle" | translate }}</div>
                    <ion-input class="border control input-shadow full-name"
                                formControlName="fullName"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_fullNamePlaceHolder' | translate}}"></ion-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['fullName'].invalid && isSubmit">{{ "selfServiceStation_fullNameError" |
                        translate }}
                    </div>

                    <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
                    <app-phone-number-input class="phone-number"
                                            (onPressNumber)="phoneSelect($event)"
                                            (click)="scrollToBottom()"
                                            [hotelId]="hotelId"></app-phone-number-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                        "selfServiceStation_phoneNumberError" | translate }}
                    </div>

                    <div *ngIf="!payInTaxi">
                        <div class="title">{{ "selfServiceStation_emailTitle" | translate }}</div>
                        <ion-input type="email"
                                class="border control input-shadow"
                                formControlName="email"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_emailPlaceholder' | translate}}"
                                email></ion-input>
                        <div class="error-message"
                            *ngIf="taxiOrderForm.controls['email'].invalid">{{ "selfServiceStation_emailError" | translate }}</div>
                    </div>
                </div>
            </div>

            <div *ngIf="payInTaxiAllowed" class="payInTaxiContainer">
                <div class="title m-t-8">{{ "paymentOptions" | translate }}</div>
                <mat-checkbox color="primary"
                    (change)="onPayInTaxiSelected($event)"
                    class="payInTaxi checkbox width"                    
                    [checked]="payInTaxi"
                    [disabled]="noDestinationSelected || onlyPayInTaxi">{{ "payInTaxi" | translate }}</mat-checkbox>
            </div>

            <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="onClick()"
                        color="primary">{{ getOrderButtonText() }}</button>
            </div>

            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>
        </form>
    </div>

    <!--ToSpecialLocation-->
    <div class="main" *ngIf="whatToShow==3">
        <form [formGroup]="taxiOrderForm">
            <div class="vertical-flexer">
                <div class="box right-margin">
                    <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <div class="current-location-container">
                        <ion-input disabled="true"
                                class="border current-location input-shadow-readonly"
                                [value]="hotelInfo.name">
                            <mat-icon class="map">location_on</mat-icon>
                        </ion-input>
                        <span class="current-location-address">{{ hotelInfo.address }}</span>
                    </div>
        
                    <div *ngIf="noDestinationAllowed && payInTaxiAllowed" class="margin-top-10">
                        <mat-checkbox color="primary"
                        (change)="onNoDestination($event)"
                        class="asap checkbox">{{ "noDestination" | translate }}</mat-checkbox>
                    </div>
        
                    <div class="title">{{ "selfServiceStation_destinationTitle" | translate }}</div>
                    <div class="error-message"
                        *ngIf="taxiOrderForm.controls['destination'].invalid">{{ "selfServiceStation_destinationError" |
                        translate }}</div>
        
                    <div class="destination">
                        <app-destination-selector class="control destination"
                                                formControlName="destination"
                                                [showErrors]="this.taxiOrderForm.controls['destination'].value?.name === undefined && isSubmit"
                                                [hotelInfo]="hotelInfo"
                                                [fromHotel]="false"
                                                [disabled]="noDestinationSelected"></app-destination-selector>
                    </div>
                    
                    <div *ngIf="!noDestinationSelected" class="price">
                        <div class="m-0 title">{{ priceText }}</div>
                        <div>
                            <ion-input readonly="true"
                                class="border price-ddk input-shadow-bottom"
                                [value]='getPrice()'></ion-input>
                            <ion-input readonly="true"
                                class="border price-euro input-shadow"
                                [value]='getEuroPrice()'></ion-input>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="title m-t-8">{{ "selfServiceStation_dateTimeTitle" | translate }}</div>
                    <div class="pick-up">
                        <app-date-time-picker [maxDate]='maxDate'
                                            formControlName="pickupDateTime"
                                            class="date-time-picker"
                                            [autoIncrementTime]="true"
                                            (click)="usingDatePicker()"
                                            (change)="dateChange()"></app-date-time-picker>
                        <mat-checkbox color="primary"
                                    (change)="onAsap($event)"
                                    formControlName="isAsap"
                                    class="asap checkbox">{{ "selfServiceStation_asapText" | translate }}</mat-checkbox>
                    </div>
        
                    <div class="title">{{ "selfServiceStation_fullNameTitle" | translate }}</div>
                    <ion-input class="border control input-shadow full-name"
                                formControlName="fullName"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_fullNamePlaceHolder' | translate}}"></ion-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['fullName'].invalid && isSubmit">{{ "selfServiceStation_fullNameError" |
                        translate }}
                    </div>
        
                    <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
                    <app-phone-number-input class="phone-number"
                                            (onPressNumber)="phoneSelect($event)"
                                            (click)="scrollToBottom()"
                                            [hotelId]="hotelId"></app-phone-number-input>
                    <div class="error-message"
                            *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                        "selfServiceStation_phoneNumberError" | translate }}
                    </div>
        
                    <div *ngIf="!payInTaxi">
                        <div class="title">{{ "selfServiceStation_emailTitle" | translate }}</div>
                        <ion-input type="email"
                                class="border email control input-shadow"
                                formControlName="email"
                                (click)="scrollToBottom()"
                                placeholder="{{'selfServiceStation_emailPlaceholder' | translate}}"
                                email></ion-input>
                        <div class="error-message"
                            *ngIf="taxiOrderForm.controls['email'].invalid">{{ "selfServiceStation_emailError" | translate }}</div>
                    </div>  
                </div>
            </div>


            <div *ngIf="payInTaxiAllowed" class="payInTaxiContainer">
                <div class="title m-t-8">{{ "paymentOptions" | translate }}</div>
                <mat-checkbox color="primary"
                    (change)="onPayInTaxiSelected($event)"
                    class="payInTaxi checkbox width"                    
                    [checked]="payInTaxi"
                    [disabled]="noDestinationSelected || onlyPayInTaxi">{{ "payInTaxi" | translate }}</mat-checkbox>
            </div>

            <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="onClick()"
                        color="primary">{{ getOrderButtonText() }}</button>
            </div>

            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>
        </form>
    </div>
</div>