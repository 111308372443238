import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { TranslateService } from '@ngx-translate/core';
import { ReceiptService } from 'src/app/services/receipt.service';
import { PaymentOption } from 'src/app/services/hotel.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-payments-alert',
  templateUrl: './payments-alert.component.html',
  styleUrls: ['./payments-alert.component.scss']
})
export class PaymentsAlertComponent implements OnInit, OnDestroy {

  isCancelledManual = false;

  @Input() anonymousView: boolean = false;
  infoText = '';

  hotelId: any;
  accessToken: any;
  airportId: any;

  hasUnappliedBills: boolean = false;
  numberOfUnappliedBills: number = 0;

  receiptsForCurrentMonthSubscription = new Subscription();
  translationSubscription?: Subscription;
  private languageChangeSubscription?: Subscription;

  constructor(public service: GetTaxiService, private parameterService: ParameterService,
    private translate: TranslateService, private receiptService: ReceiptService) {
  }

  ngOnInit(): void {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
      this.airportId = params.airportId;
    });

    this.receiptsForCurrentMonthSubscription = this.receiptService.connectReceipts().subscribe(data => {
      let unAppliedCounter = 0;

      for (let receipt of data) {
        if (receipt.applied !== true && receipt.deleted !== true && !((receipt.paymentOption & PaymentOption.Prepay) === PaymentOption.Prepay))
          unAppliedCounter++;

        if (receipt.deleted) {
          this.isCancelledManual = true;
        }
      }

      this.numberOfUnappliedBills = unAppliedCounter;
      this.hasUnappliedBills = unAppliedCounter > 0;

      this.setInfoText();

      this.languageChangeSubscription = this.translate.onDefaultLangChange.subscribe(() => {
        this.setInfoText();
      });
    });


  }

  ngOnDestroy(): void {
    this.receiptsForCurrentMonthSubscription.unsubscribe();
    this.translationSubscription?.unsubscribe();
    this.languageChangeSubscription?.unsubscribe();
  }

  private setInfoText() {
    var textKey: string;
    if (this.numberOfUnappliedBills === 0)
      textKey = 'payment-alert-apply-bill';
    else if (this.numberOfUnappliedBills === 1)
      textKey = 'payment-alert-apply-bill-one';
    else
      textKey = 'payment-alert-apply-bills';

    if (this.translationSubscription) {
      this.translationSubscription.unsubscribe();
    }

    this.translationSubscription = this.translate.get(textKey).subscribe(value => this.infoText = value.replace('{nrOfBills}', this.numberOfUnappliedBills))
  }
}
