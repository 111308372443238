import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChooseAComment } from 'src/app/services/models/driverComments.model';

export interface ChooseWhichCommentComponentArgs {
  chooseACommentList: ChooseAComment[];
}

@Component({
  selector: 'app-choose-which-comment',
  templateUrl: './choose-which-comment.component.html',
  styleUrls: ['./choose-which-comment.component.scss']
})

export class ChooseWhichCommentComponent {
  chooseACommentList: ChooseAComment[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChooseWhichCommentComponentArgs, private dialogRef: MatDialogRef<ChooseWhichCommentComponent>) {
    this.chooseACommentList = data.chooseACommentList;
  }

  public click(indexNumber: number): void {
    this.dialogRef.close(this.chooseACommentList[indexNumber].comment);
  }
}
