import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-order-popup',
  templateUrl: './error-order-popup.component.html',
  styleUrls: ['./error-order-popup.component.scss']
})
export class ErrorOrderPopupComponent implements OnInit {

  textTranslation?: string;

  constructor(private translate: TranslateService, @Inject(MAT_DIALOG_DATA) data: ErrorOrderPopupArguments) {
    this.textTranslation = this.translate.instant("selfServiceStation_errorOrderPopupDescription").replace('{{taxiName}}', data.taxiName).replace('{{taxiHotelNumber}}', data.taxiHotelNumber);
  }

  ngOnInit(): void {
  }

}


interface ErrorOrderPopupArguments {
  taxiName: string;
  taxiHotelNumber: string;
}