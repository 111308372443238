<div class="container">
    <mat-dialog-content class="content">
        <h2>
            {{ "specialHotelInfoPopuptTitle" | translate }}
        </h2>
        <li *ngFor="let information of informationList">
            {{information}}
        </li>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="continue" (click)="clickedContinue()">
                Ok
            </button>
        </div>
    </mat-dialog-actions>
</div>
