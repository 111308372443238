import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

export interface RoomNumberIncorrectDialogArgs {
  description: string;
}

@Component({
  selector: 'app-room-number-incorrect-popup',
  templateUrl: './room-number-incorrect-popup.component.html',
  styleUrls: ['./room-number-incorrect-popup.component.scss']
})

export class RoomNumberIncorrect {
  constructor(private dialogRef: MatDialogRef<RoomNumberIncorrect>) {
  }

  public ok(): void {
    this.dialogRef.close();
  }
}
