import { Component, OnInit } from '@angular/core';
import { DefaultLangChangeEvent, LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/services/page.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import {GetTaxiService} from '../../services/get-taxi.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  supportText = '';
  public sopBookingAdditionalInfo = '';

  constructor(
      private translate: TranslateService,
      private taxiConfigurationService: TaxiConfigurationService,
      private pageService: PageService, public taxiService: GetTaxiService) {
    translate.onDefaultLangChange.subscribe((params: DefaultLangChangeEvent) => {
      this.ReloadTranslations();
    });
  }

  ReloadTranslations(): void {
    this.translate.get('supportText').subscribe((translated: string) => {
      this.supportText = translated
        .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
        .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);

      this.taxiConfigurationService.taxiName.subscribe(_ => {
        this.supportText = translated
          .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
          .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
      })
    });

    this.pageService.currentHeaderTranslationKey = 'support';
  }

  ngOnInit(): void {
    this.taxiService.getSopBookingAdditionalInfo().subscribe((data) => {
      this.sopBookingAdditionalInfo = data.html;
    });
    this.ReloadTranslations();
  }

}
