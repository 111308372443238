<div *ngIf="!isInitialized">
</div>

<div class="text-center"
     *ngIf="isInitialized && rides.length === 0">
    {{ 'upcoming-rides-none-planned' | translate }}
</div>
<div *ngIf="rides.length > 0">
    <!--{{ 'upcoming-rides-planned' | translate }}-->

    <div *ngFor="let ride of rides">
        <div class="header">{{(ride.rideType == 0 ? "taxi-arrivals" : "taxi-rides") | translate}}&nbsp;{{
            ride.pickupTime +
            'Z' | date:'HH:mm' }}&nbsp;</div>
        <br>
        <div class="content">
            <span *ngIf="ride.to">
                {{ 'upcoming-rides-to' | translate }} {{ ride.to.name }}
            </span>
            <span *ngIf="!ride.to">
                {{ 'upcoming-rides-unknown-destination' | translate }}
            </span>
            <span *ngIf="statusOnItsWay(ride) && ride.etaOfTaxi !== null"
                  class="status on-its-way">{{ 'upcoming-rides-on-its-way' | translate }} - ETA: {{ride.etaOfTaxi}} min.</span>
            <span *ngIf="statusOnItsWay(ride) && ride.etaOfTaxi == null"
                  class="status on-its-way">{{ 'upcoming-rides-on-its-way' | translate }}</span>
            <span *ngIf="statusTaximeterStarted(ride)"
                  class="status taximeter-started">{{ 'upcoming-rides-taximeter-started' | translate }}</span>
            <span *ngIf="statusInVain(ride)"
                  class="status in-vain">{{ 'upcoming-rides-in-vain' | translate }}</span>
            <span *ngIf="statusNoShow(ride)"
                  class="status in-vain">{{ 'upcoming-rides-no-show' | translate }}</span>
            <span *ngIf="statusInVain(ride)"
                  class="status text">{{ inVainContactText }}</span>

            <br *ngIf="ride.roomNumber"> 
            <div *ngIf="ride.roomNumber">
                {{ 'upcoming-rides-room-number' | translate }} {{ ride.roomNumber }}  
            </div>

            <br *ngIf="ride.taxiCarId"> 
            <div *ngIf="ride.taxiCarId">
                {{ 'upcoming-rides-taxi-car-id' | translate }} {{ ride.taxiCarId }}  
            </div>

            <br *ngIf="ride.guestName && !anonymousView"> 
            <div *ngIf="ride.guestName && !anonymousView">
                {{ 'upcoming-rides-guest-name' | translate }} {{ ride.guestName }}
            </div>

            <div *ngIf="showCancel">
                <li *ngIf="!statusTaximeterStarted(ride) 
                    && !statusCancelled(ride) 
                    && !statusCompleted(ride) 
                    && !statusUnknown(ride) 
                    && !statusInVain(ride)  
                    && !statusNoShow(ride)">
                    <button mat-stroked-button
                            color="danger"
                            (click)="cancelRide(ride)">
                        {{'cancelTaxi' | translate}}
                    </button>
                </li>
            </div>
        </div>
    </div>
</div>