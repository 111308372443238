<div class="container">
    <app-station-header [logoSrc]="hotelInfo.logoSrc" [language]="hotelInfo.language"></app-station-header>
    
    <div *ngIf="hasMultipleTaxiCompanies()" class="taxi-choose">
        <mat-radio-group color="primary" class="button-section" [(ngModel)]="selectedTaxiCompany" (ngModelChange)="selectedTaxiCompany = $event">
            <mat-radio-button *ngFor="let taxiName of taxiNames; let i = index" 
            [value]="taxiName" 
            [ngClass]="{ 'first': i === 0, 'last': i === taxiNames.length - 1 }"
            (change)="onTaxiBoxClick(taxiName)"
            class="custom-radio-box">
                <div class="taxi-item">
                <!-- Image element with dynamic src -->
                    <img *ngIf="isLogoAvailable[taxiName]" 
                        [src]="'assets/images/taxilogos/' + taxiName + '.png'" 
                        alt="{{ taxiName }} logo" 
                        class="taxi-logo" 
                        (error)="isLogoAvailable[taxiName] = false" />
                    <h4 class="taxi-name">{{ taxiName }}</h4>
                </div>
            </mat-radio-button>
        </mat-radio-group>
    </div>

    <!--Standard-->
    <div *ngIf="whatToShow==0">
        <form [formGroup]="taxiOrderForm" class="main">
            <div class="mobile-container"
                *ngIf="!hotelInfo.lockedMobileRoutes">
                <div class="from">
                     <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <app-destination-selector class="control"
                                              [showErrors]="this.taxiOrderForm.controls['fromLocation'].value?.name === undefined && isSubmit"
                                              [hotelInfo]="hotelInfo"
                                              [fromHotel]="true"
                                              [rounding]="true"
                                              formControlName="fromLocation"
                                              class="destination"></app-destination-selector>
                </div>

                <div>
                    <mat-checkbox color="primary" (change)="fromHotelChange($event)" class="checkBox">
                        {{ getFromTranslations() }}
                    </mat-checkbox>
                </div>

                <div class="to">
                    <div class="title">{{ "selfServiceStation_destinationTitle" | translate }}</div>
                    <app-destination-selector class="control destination"
                                              formControlName="destination"
                                              [showErrors]="this.taxiOrderForm.controls['destination'].value?.name === undefined && isSubmit"
                                              [hotelInfo]="hotelInfo"
                                              [fromHotel]="false"
                                              [rounding]="true"
                                              [disabled]="noDestinationSelected"></app-destination-selector>
                </div>

                <div *ngIf="noDestinationAllowed && payInTaxiAllowed">
                    <mat-checkbox color="primary" (change)="onNoDestinationChange($event)" class="checkBox">
                        {{ "noDestination" | translate }}
                    </mat-checkbox>
                </div>
            </div>

            <div class="locked-destinations" *ngIf="hotelInfo.lockedMobileRoutes">
                <div>
                    <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <mat-form-field class="hotel-form-field" appearance="outline">
                        <mat-select  [(value)]="hotelInfo"
                        panelClass="selector-panel-class"
                        disabled>
                            <mat-option class="selector-options" [value]="hotelInfo">
                                {{hotelInfo.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="route-address">{{hotelInfo.address}}</div>
                </div>

                <div>
                    <div class="title">{{ "to" | translate }}</div>
                    <mat-form-field class="chooser" appearance="outline" class="mat-form-field">
                        <mat-select  
                        placeholder='{{ "chooseDestination" | translate }}'
                        (selectionChange)="routeSelectorChange($event.value)"
                        panelClass="selector-panel-class input-shadow-bottom"
                        formControlName="destination">
                            <mat-option class="selector-options" *ngFor="let route of lockedRoutes" [value]="route">
                                {{route.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="route-address">{{currentRouteAddress}}</div>
                </div>
            </div>
            
            <div class="error-message"
                 *ngIf="taxiOrderForm.controls['destination'].invalid">{{ "selfServiceStation_destinationError" |
                translate }}</div>

            <div class="title m-t-8">{{ "selfServiceStation_dateTimeTitle" | translate }}</div>
            <div class="pick-up">
                <app-date-time-picker [maxDate]='maxDate'
                                    [rounding]="true"
                                    formControlName="pickupDateTime"
                                    class="date-time-picker"
                                    [autoIncrementTime]="true"
                                    (click)="usingDatePicker()"
                                    (change)="dateChange()"></app-date-time-picker>
                <mat-checkbox color="primary"
                              (change)="onAsap($event)"
                              formControlName="isAsap"
                              class="asap">{{ "selfServiceStation_asapText" | translate }}</mat-checkbox>
            </div>

            <div *ngIf="showApplyToBill && !noDestinationSelected">
                <div class="roomNumber">
                    <ion-label class="title">{{ "roomNo" | translate }}</ion-label>
                    <ion-input type="text"
                               placeholder="e.g. “253“"
                               class="roomNumber-input input-placeholder"
                               formControlName="roomNo"></ion-input>
                </div>         
                <div class="error-message" *ngIf="taxiOrderForm.controls['roomNo'].invalid">{{ "roomNumberMissing" | translate }}</div>

                <app-apply-to-bill-box
                    class="app-apply-to-bill-box"
                    formControlName="paymentOption"></app-apply-to-bill-box>
            </div>

            <div class="title">{{ "selfServiceStation_fullNameTitle" | translate }}</div>
            <ion-input class="border control input-shadow full-name"
                        formControlName="fullName"
                        placeholder="{{'selfServiceStation_fullNamePlaceHolder' | translate}}"></ion-input>
            <div class="error-message"
                    *ngIf="taxiOrderForm.controls['fullName'].invalid && isSubmit">{{ "selfServiceStation_fullNameError" |
                translate }}
            </div>

            <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
            <app-phone-number-input class="phone-number"
                                    (onPressNumber)="phoneSelect($event)"
                                    [hotelId]="hotelId"
                                    [rounding]="true"></app-phone-number-input>
            <div class="error-message"
                *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                "selfServiceStation_phoneNumberError" | translate }}
            </div>

            <div class="title m-t-8 margin-top">{{ "paymentOptions" | translate }}</div>
            <div class="order-submit-wrapper">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="onClick()"
                        color="primary">
                    <div>
                        <div class="btn_text">{{ getOrderButtonText() }}</div>
                        <div class="spinner" *ngIf="btnLoader"></div>
                    </div>
                    <div *ngIf="showPaymentOptions()">
                        <img src="/assets/images/PaymentOptions.png" class="paymentOptionImage"/>
                    </div>
                    <div *ngIf="showPrice()" class="flextext_btn top">
                        <ion-input readonly="true"
                            [value]=localPrice></ion-input>
                        <div>/</div>
                        <ion-input readonly="true"
                            [value]=euroPrice style="text-align: left;"></ion-input>
                    </div>
                </button>
            </div>

            <div *ngIf="payInTaxiAllowed" class="payInTaxiContainer">
                <mat-checkbox color="primary"
                    (change)="onPayInTaxiSelected($event)"
                    class="asap checkBox_margin"
                    [checked]="payInTaxi"
                    [disabled]="noDestinationSelected">{{ "payInTaxi" | translate }}</mat-checkbox>
            </div>  

            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>

            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
        </form>
    </div>

    <!--Here and now-->
    <div *ngIf="whatToShow==1">
        <form class="main mobile-container"
              [formGroup]="taxiOrderForm">

            <div class="mobile-container__switcher here-and-now"
                *ngIf="!hotelInfo.lockedMobileRoutes">
                <div class="from">
                     <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
                    <app-destination-selector class="control"
                                              [showErrors]="this.taxiOrderForm.controls['fromLocation'].value?.name === undefined && isSubmit"
                                              [hotelInfo]="hotelInfo"
                                              [fromHotel]="false"
                                              [rounding]="true"
                                              formControlName="fromLocation"
                                              class="destination"></app-destination-selector>
                </div>
            </div>

            <div>
                <mat-checkbox color="primary" (change)="fromHotelChange($event)" class="checkBox">
                    {{ getFromTranslations() }}
                </mat-checkbox>
            </div>

            <div class="title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</div>
            <app-phone-number-input class="phone-number"
                                    (onPressNumber)="phoneSelect($event)"
                                    [hotelId]="hotelId"
                                    [rounding]="true"></app-phone-number-input>
            <div class="error-message"
                *ngIf="taxiOrderForm.controls['phoneNumber'].invalid && isSubmit">{{
                "selfServiceStation_phoneNumberError" | translate }}
            </div>

            <div class="order-submit-wrapper margin-top">
                <button mat-flat-button
                        class="order-submit"
                        [disabled]="!taxiOrderForm.valid"
                        (click)="onClick()"
                        color="primary">
                    <div>
                        <div class="btn_text">{{ getOrderButtonText() }}</div>
                        <div class="spinner" *ngIf="btnLoader"></div>
                    </div>
                </button>
            </div>
            <div class="cancelContainer">
                <button mat-flat-button
                    class="cancelButton"
                    (click)="resetInterface()">
                    {{ 'selfServiceStation_deleteOrderButton' | translate }}
                </button>
            </div>
            <div class="bottom-text">{{ "selfServiceStation_bottomText" | translate }}</div>
        </form>
    </div>
</div>