<div class="gfg">
    <img id="screensaver" src="../../../../assets/images/Screensaver.png" alt="" />

    <div *ngIf="getWhatToDisplay() == 0" class="flex-container">
        <img  id="logo" [src]=logoSrc />
        <h3 class="under-logo-text">Taxi Booking</h3>
        <h3 class="middle-txt " [innerHtml]="'selfServiceStation_topText' | translate "></h3>
    </div>

    <div *ngIf="getWhatToDisplay() == 1" class="flex-container">
        <img id="logo" [src]=logoSrc />
        <h3 class="middle-txt " [innerHtml]="'selfServiceStation_secondText' | translate "></h3>
    </div>

    <div class="button-box">
        <div class="button-row">
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(1)" color="primary">{{ 'selfServiceStation_orderTaxiNow' | translate }}</button>
            <button *ngIf="showLocationsOverAirport() == 0" mat-flat-button class="continue-button" (click)="continue(2)" color="primary">
                {{ 'selfServiceStation_orderTaxiAirport' | translate }} 
                <br *ngIf="airportPrice"/>        
                {{ getAirportPrice() }} 
            </button>
            <button *ngIf="showLocationsOverAirport() == 1" mat-flat-button class="continue-button" (click)="continue(4)" color="primary"> 
                {{ getLocationTranslation(1) }}
                <br *ngIf="secondPrice"/>        
                {{ getLocationPrice(1) }}
            </button>
        </div>
        <div class="button-row">
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(0)" color="primary"> {{ 'selfServiceStation_orderTaxiFixedPrice' | translate }} </button>
            <button *ngIf="locations" mat-flat-button class="continue-button" (click)="continue(3)" color="primary"> 
                {{ getLocationTranslation(0) }}  
                <br *ngIf="firstPrice"/>           
                {{ getLocationPrice(0) }} 
            </button>
        </div>
    </div>
</div>
