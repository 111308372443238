<div class="form-group">
    <ion-label>{{ "phoneNoLabel" | translate }}</ion-label>
    <ion-input type="tel" [value]="phoneNumber" [placeholder]="translate.instant('guest_number_placeholder')" class="form-controls" (change)="onChangePhoneNo($event)"></ion-input>

    <div *ngIf="ngControl && formSubmitted" class="alert alert-danger">
        <div *ngIf="ngControl?.errors?.required">
            {{ "phoneNoRequired" | translate }}
        </div>
        <div *ngIf="ngControl?.errors?.minlength">
            {{ "phoneNoMinimum" | translate }}
        </div>
        <div *ngIf="ngControl?.errors?.pattern">
            {{ "phoneNoValid" | translate }}
        </div>
    </div>
</div>
