import { AbstractControl } from '@angular/forms';

export class AddressValidator {

    public static detailedAddressValidator(formControl: AbstractControl): any {
        if (!formControl.parent) {
            return null;
        }

        const detailedAddress = formControl.value.detailedAddress;

        if (detailedAddress != undefined && (!detailedAddress?.zipCode)) {
            return { invalidAddress: { value: formControl.value } };
        }

        return null;
    }
}
