<div class="initialsContainer"
     [class.validationError]="!isValid"
     [hidden]="!showComponent">
       <div class="initialsElement">
              <ion-label>{{ "receptionistInitialsLabel" | translate }}</ion-label>
       </div>
       <div class="initialsElement">
              <ion-input [(ngModel)]="initials"
                         (keyup)="onChange()"
                         type="text"
                         placeholder="e.g. “JT”"
                         class="form-control smallInput"> </ion-input>
       </div>
</div>