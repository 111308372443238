import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class SelfServiceStationInterceptor implements HttpInterceptor {

  constructor() {}

  // tslint:disable-next-line:typedef
  intercept(req: HttpRequest<any>, next: HttpHandler) {

      const noCacheReq = req.clone({
        setHeaders: {
          'Cache-Control': 'max-age=86400',
        }
      });
      return next.handle(noCacheReq);
  }
}
