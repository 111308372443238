<div class="dropdown" (mouseleave)="closeDropdown()">
    <button class="dropbtn" (click)="toggleDropdown()"><img src="assets/images/translate.png" alt="Button Icon" /></button>
    <div class="dropdown-content" [class.show]="dropdownOpen">
        <button mat-button
                matTooltip="English"
                id="button_en"
                (click)="useLanguage('en')">
            <img src="assets/images/Translation_Flags/flag-icn-en.png"
                alt="">
        </button>

        <button mat-button
                matTooltip="Danish"
                id="button_da"
                (click)="useLanguage('da')"
                *ngIf="language == 'da'">
            <img src="assets/images/Translation_Flags/flag-icn-da.png"
                alt="">
        </button>

        <button mat-button
                matTooltip="German"
                id="button_de"
                (click)="useLanguage('de')"
                *ngIf="language == 'de'">
            <img src="assets/images/Translation_Flags/flag-icn-de.png"
                alt="">
        </button>
        <button mat-button
                matTooltip="Norwegian"
                id="button_no"
                (click)="useLanguage('no')"
                *ngIf="language == 'no'">
            <img src="assets/images/Translation_Flags/flag-icn-no.png"
                alt="">
        </button>
        <button mat-button
                matTooltip="Swedish"
                id="button_se"
                (click)="useLanguage('se')"
                *ngIf="language == 'se'">
            <img src="assets/images/Translation_Flags/flag-icn-se.png"
                alt="">
        </button>
        <button mat-button
                matTooltip="Finish"
                id="button_fi"
                (click)="useLanguage('fi')"
                *ngIf="language == 'fi'">
            <img src="assets/images/Translation_Flags/flag-icn-fi.png"
                alt="">
        </button>
    </div>
</div>