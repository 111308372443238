<div class="header">
    <h1 mat-dialog-title>Update</h1>
    <button mat-dialog-action mat-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container">
  <div class="left-container"> 
    <div class="form-group">
      <label>Payment Options:</label>
      <div *ngFor="let setting of paymentOptionsKeys">
        <input type="checkbox" [checked]="isPaymentOptionSet(setting)" (change)="togglePaymentOption(setting)">
        {{ setting }}
      </div>
    </div>

    <div *ngIf="selectedHotelSystemIntegrations!=0" class="form-group">
      <label>Hotel system integration:</label>
      <div *ngFor="let setting of hotelSystemIntegrationsKeys">
        <input type="checkbox" [checked]="isHotelIntegrationSet(setting)" (change)="toggleHotelIntegration(setting)">
        {{ setting }}
      </div>
    </div>

    <div class="form-group">
      <label>No Destination Settings:</label>
      <div *ngFor="let setting of noDestinationSettingsKeys">
        <input type="radio" name="noDestinationSetting" [value]="setting" [checked]="selectedNoDestinationSetting" (change)="setNoDestinationSetting(setting)">
        {{ setting }}
      </div>
    </div>
  </div>

  <div class="right-container">
    <label>Taxi Company Name:</label>
    {{hotelSettings?.taxiCompanyName}}
    <div style="height: 10px;"></div>
    <label>TaxiOptions:</label>
    {{hotelSettings?.tcAvailableOptions}}
  </div>
</div>

<button mat-button (click)="saveSettings()" class="button" color="primary">Update</button>