<div class="container">
    <div class="continue-payment">
        <button class="clean-btn p-btn continue-payment-terminal" (click)="continuePayAtTerminal()" cdkFocusInitial>
            {{ "payInTaxiPopup_continueTerminalPayment_button" | translate }}
        </button>
    </div>

    <mat-dialog-content class="content">
        <h2>
            {{ translatedDescription_beforePrice }}<b>{{ translatedDescription_priceSection }}</b
            >{{ translatedDescription_afterPrice }}
        </h2>
    </mat-dialog-content>

    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="clean-btn p-btn pay-in-taxi" (click)="continuePaidInTaxi()" cdkFocusInitial>
                {{ "newPayInTaxiPopup_sendInfoButton" | translate }}
            </button>
            <button class="clean-btn p-btn cancel" (click)="cancelBooking()">{{ "cancelTaxi" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
