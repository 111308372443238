import { Component } from '@angular/core';
import { ICoordinate } from '../../models/coordinate.interface';
import { ReceptionMapperService } from '../../shared/mappers/reception-mapper.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-tracking-order-map',
    templateUrl: './tracking-order-map.component.html',
    styleUrls: ['./tracking-order-map.component.scss']
})
export class TrackingOrderMapComponent {

    taxiCars: ICoordinate[] = [];
    markerPosition: ICoordinate | undefined;

    constructor(private getTaxiService: GetTaxiService, private route: ActivatedRoute) {
        this.route.queryParams.subscribe(async (params) => {
            if (params.trackingCode) {
                this.trackLocation(params.trackingCode);

                setInterval(() => {
                    this.trackLocation(params.trackingCode);
                }, 30000);
            }
        });
    }

    trackLocation(trackingCode: string): void {
        this.getTaxiService.trackTaxiLocation(trackingCode).subscribe((data: TrackTaxiLocation) => {
            this.markerPosition = data.markerPosition ? ReceptionMapperService.mapTaxiMapFormToICoordinate(data.markerPosition) : undefined;
            this.taxiCars = data.taxiCoordinate ? [ReceptionMapperService.mapTaxiMapFormToICoordinate(data.taxiCoordinate)] : [];
        }, (error: TrackingCodeError) => {
            alert(error.error.message)
        });
    }
}

interface TrackingCodeError {
    error: {
        message: string
        errorCode: number
    }
}

interface TrackTaxiLocation {
    taxiCoordinate: ICoordinate[];
    markerPosition: ICoordinate | undefined;
}