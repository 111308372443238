<ion-content>
    <app-header></app-header>

    <div class="content">
        <img [src]="airport?.imagePath"
             alt="">

        <p> {{ airport.guestInfoAboutAirport }} </p>
    </div>

</ion-content>