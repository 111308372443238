import { Component, Input } from '@angular/core';
import { GdprPopupComponent } from '../gdpr-popup/gdpr-popup.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent {

  @Input() logoSrc: any;
  @Input() language: any;
  @Input() gdpr: boolean = false;

  constructor(private dialog: MatDialog) {
  }

  public openGDPRPopUp(): void {
    this.dialog.open(GdprPopupComponent, {
      height: '90%',
      width: '90%'
    });
  }

}
