import { Component, OnInit } from '@angular/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-info-airport',
  templateUrl: './info-airport.component.html',
  styleUrls: ['./info-airport.component.scss']
})
export class InfoAirportComponent implements OnInit {

  // airport variable is set up with default values to be compatible with old SMS's without airportId in URL
  airport: any = {
    iataAirportCode: 'cph',
    guestInfoAboutAirport: 'The driver awaits with your name.\n\nIf you are more than 30 min. Delayed, please take a Dantaxi in lane 2.'
  };

  constructor(getTaxiService: GetTaxiService, parameterService: ParameterService) {
    parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      var airportId = params.airportId;

      if (airportId)
        getTaxiService.getAirportInfo(airportId).subscribe(data => {
          data.imagePath = 'assets/images/' + data.iataAirportCode + '.png';
          this.airport = data;
        });
    });
  }

  ngOnInit(): void {
  }

}
