import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService, PaymentOptionsAllowed } from 'src/app/services/get-taxi.service';
import { PageService } from 'src/app/services/page.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-fixed-destination-list',
  templateUrl: './fixed-destination-list.component.html',
  styleUrls: ['./fixed-destination-list.component.scss']
})
export class FixedDestinationListComponent implements OnInit {
  loading!: HTMLIonLoadingElement;

  displayedColumns: string[] = ['description', 'guestPrice', 'guestPriceToHotel', 'paymentOptionsAllowed'];
  fixedDestinations: any;

  hotelId?: string;
  airportId?: string;
  accessToken?: string;

  constructor(private getTaxiService: GetTaxiService, private router: Router, private parameterService: ParameterService, private translate: TranslateService, public loadingController: LoadingController, private pageService: PageService) {
    this.presentLoading();

    getTaxiService.getFixedDestinations().subscribe(data => {
      data = data.reduce((accumulator: any[], currentValue: { destination: string, destinationName: string, fixedDestinations: any }) => {
        accumulator.push({ destination: currentValue.destination, destinationName: currentValue.destinationName });

        accumulator.push((currentValue.fixedDestinations.map((fixedDestinations: any[]) => {
          var acc = {
            isLargeTaxi: fixedDestinations[0].isLargeTaxi,
            isSharedTaxi: fixedDestinations[0].isSharedTaxi,
            hotelPrice: fixedDestinations[0].hotelPrice,
            taxiPrice: fixedDestinations[0].taxiPrice,
            paymentOptionsAllowed: fixedDestinations[0].paymentOptionsAllowed,
            fixedDestinationIds: [fixedDestinations[0].id]
          };

          if (fixedDestinations.length > 1) {
            (<any>acc).hotelPriceToHotel = fixedDestinations[1].hotelPrice;
            (<any>acc).taxiPriceToHotel = fixedDestinations[1].taxiPrice;
            (<any>acc).fixedDestinationIds.push(fixedDestinations[1].id);
          }

          return acc;
        }, { fixedDestinationIds: [] })));
        return (<any>accumulator).flat();
      }, []);
      this.fixedDestinations = data;

      this.hideLoading();
    });

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
    });
  }

  ngOnInit(): void {
    this.pageService.currentHeaderTranslationKey = 'fixedDestination-title';
  }

  fixedDestinationSelected(row: { fixedDestinationIds: string[] }) {
    this.router.navigate(['setup', 'prices', 'update'], {
      queryParams: {
        'hotelId': this.hotelId,
        'accessToken': this.accessToken,
        'airportId': this.airportId,
        'fixedDestinationIds': row.fixedDestinationIds
      }
    });
  }

  isGroup(index: any, item: any) {
    return item.destination !== undefined;
  }

  destinationDescription(fixedDestination: any) {
    if (fixedDestination.isSharedTaxi)
      return this.translate.instant('fixedDestination-sharedRideDescription');

    if (fixedDestination.isLargeTaxi)
      return this.translate.instant('fixedDestination-largeTaxiDescription');

    return this.translate.instant('fixedDestination-normalTaxiDescription');
  }

  create() {
    this.router.navigate(['setup', 'prices', 'create'], {
      queryParams: {
        'hotelId': this.hotelId,
        'accessToken': this.accessToken,
        'airportId': this.airportId
      }
    });
  }

  getPaymentOptionsAllowedString(value: number) {
    if ((value & PaymentOptionsAllowed.PrepayAllowed) == PaymentOptionsAllowed.PrepayAllowed
      && (value & PaymentOptionsAllowed.PayInTaxiAllowed) == PaymentOptionsAllowed.PayInTaxiAllowed)
      return this.translate.instant('Begge');
    else if ((value & PaymentOptionsAllowed.PrepayAllowed) == PaymentOptionsAllowed.PrepayAllowed)
      return this.translate.instant('Prepay');
    else if ((value & PaymentOptionsAllowed.PayInTaxiAllowed) == PaymentOptionsAllowed.PayInTaxiAllowed)
      return this.translate.instant('I taxi');
    else
      return this.translate.instant('Unknown');
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

}
