import { OrderType } from "src/app/enums/order-type.enum";
import { IPaymentIntentResponse } from "src/app/models/payment-intent-response.interface";
import { CollectPaymentMethodResponse, TerminalReader } from "src/app/models/payment/terminal.interface";
import { environment } from "src/environments/environment";
import { PaymentTerminal } from "./payment-terminal";

export class NmiTerminal extends PaymentTerminal {

    public get selectedReader(): TerminalReader | undefined {
        return;
    }

    public setupTerminal(hotelId: string, terminalLocationId: string, terminalReaderId: string): Promise<void> {
        return Promise.resolve(console.log("NMI Terminal setup not needed."));
    }

    intentId: string | undefined;

    public cancelCollectPaymentMethod(): void {
        this.intentId = "cancelled";
    }

    public async collectPayment(paymentIntentData: IPaymentIntentResponse): Promise<CollectPaymentMethodResponse> {
        this.intentId = undefined;

        if (!paymentIntentData.authStatusGuid || !paymentIntentData.hotelId) return Promise.reject("No asyncStatusGuid and/or hotelId was provided for the payment intent in the NMI integration.");

        while (!this.intentId) {
            const intentIdResponse: GetPaymentIntentIdResponse = await this.paymentApiService.getPaymentIntentId(paymentIntentData.hotelId!, paymentIntentData.authStatusGuid!).toPromise();
            if (intentIdResponse.success) this.intentId = intentIdResponse.intentId;
            await new Promise((res) => setTimeout(res, environment.nmiTerminalCheckForAsyncStatusUpdateIntervalMs));
        }

        if (this.intentId === "cancelled") return Promise.reject("Payment was cancelled.");

        return Promise.resolve({ error: false, paymentIntent: { id: this.intentId! } });
    }
}

interface GetPaymentIntentIdResponse {
    success: boolean;
    intentId: string;
}