<div>
    <div class="applyToBillBox">
        <mat-checkbox class="colored-button green" (change)="checkboxCheckChange($event)" [checked]="applyToBillChecked" [disabled]="disabled">
            {{ "apply-to-bill-automatic" | translate }}
        </mat-checkbox>
    </div>

    <div *ngIf="showErrors" class="alert alert-danger">
        <div *ngIf="!selectedValue">
            {{ "pleaseSelectOption" | translate }}
        </div>
    </div>
</div>