import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent {
  hotelId: any;
  rating: any;

  constructor(private parameterService: ParameterService, private getTaxiService: GetTaxiService, private translateService: TranslateService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;

      this.getTaxiService.getRatingForHotel(this.hotelId)
        .subscribe(data => {
          if (data)
            this.rating = data;
        })
    });
  }
}
