import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { HotelSystemIntegration, NoDestinationSettings, PaymentOption } from 'src/app/services/hotel.service';

export interface HotelData {
  hotelName: string;
  id: string;
  accessToken: string;
  airportId?: string;
  terminalReaderId?: string;
}

export interface HotelSettings {
  noDestinationSettings: NoDestinationSettings;
  paymentOptions: PaymentOption;
  integrationOptions?: HotelSystemIntegration;
  taxiCompanyName: string;
  tcAvailableOptions: string;
}

@Component({
  selector: 'app-update-hotel-settings-dialog',
  templateUrl: './update-hotel-settings-dialog.component.html',
  styleUrls: ['./update-hotel-settings-dialog.component.scss']
})
export class UpdateHotelSettingsDialogComponent {

  paymentOptions = PaymentOption;
  hotelSystemIntegrations = HotelSystemIntegration;
  noDestinationSettings = NoDestinationSettings;

  selectedPaymentOptions: PaymentOption = 0;
  selectedHotelSystemIntegrations: HotelSystemIntegration = 0;
  selectedNoDestinationSetting: NoDestinationSettings = 0;

  hotelSettings?: HotelSettings;
  hotelId: string;

  noDestinationSettingsKeys = Object.keys(NoDestinationSettings).filter(key => isNaN(Number(key)));
  hotelSystemIntegrationsKeys = Object.keys(HotelSystemIntegration).filter(key => isNaN(Number(key)));
  paymentOptionsKeys = Object.keys(PaymentOption).filter(key => isNaN(Number(key)));

  constructor(public dialog: MatDialogRef<UpdateHotelSettingsDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: HotelData, public getTaxiService: GetTaxiService) {
    this.hotelId = data.id;
    this.getTaxiService.getHotelSettings(data.id).subscribe((fetchedData: HotelSettings) => {
      this.hotelSettings = fetchedData;
      this.selectedPaymentOptions = fetchedData.paymentOptions;
      this.selectedHotelSystemIntegrations = fetchedData.integrationOptions ?? 0;
      this.selectedNoDestinationSetting = fetchedData.noDestinationSettings;
    })
  }

  isPaymentOptionSet(option: string): boolean {
    const value = PaymentOption[option as keyof typeof PaymentOption];
    return (this.selectedPaymentOptions & value) !== 0;
  }

  togglePaymentOption(option: string) {
    var value = PaymentOption[option as keyof typeof PaymentOption];
    this.selectedPaymentOptions ^= value; // Toggle the bit for the option
  }

  isHotelIntegrationSet(option: string): boolean {
    const value = HotelSystemIntegration[option as keyof typeof HotelSystemIntegration];
    return (this.selectedHotelSystemIntegrations & value) !== 0;
  }

  toggleHotelIntegration(option: string) {
    var value = HotelSystemIntegration[option as keyof typeof HotelSystemIntegration];
    this.selectedHotelSystemIntegrations ^= value; // Toggle the bit for the option
  }

  setNoDestinationSetting(option: string) {
    var value = NoDestinationSettings[option as keyof typeof NoDestinationSettings];
    this.selectedNoDestinationSetting = value;
  }

  saveSettings() {
    const settings = {
      paymentOptions: this.selectedPaymentOptions,
      integrationOptions: this.selectedHotelSystemIntegrations,
      noDestinationSetting: this.selectedNoDestinationSetting
    };
    this.getTaxiService.updateHotelSettings(settings, this.hotelId).subscribe(() => {
      this.dialog.close();
    });
  }

  onNoClick() {
    this.dialog.close();
  }
}
