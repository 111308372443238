<form [formGroup]="form"
      (submit)="submit()"
      class="container">
    <a (click)="navigateToTaxiOverview()">{{'taxiOverview' | translate}}</a>

    <div class="forms search">
        <div class="form-group">
            <ion-label>{{ 'setup-adminPassword' | translate }}</ion-label>
            <ion-input type="string"
                       class="form-control"
                       formControlName="adminPassword"></ion-input>
            <div *ngIf="adminPassword && formSubmit"
                 class="alert alert-danger">
                <div *ngIf="adminPassword.errors?.required">
                    {{ 'setup-adminPasswordRequired' | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="action">
        <button mat-flat-button
                color="primary"
                type="submit">{{ 'setup-verifyPassword' | translate }} </button>
    </div>

</form>