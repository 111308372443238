import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AreYouSureDialogArgs {
  description: string;
}

@Component({
  selector: 'app-are-you-sure-popup',
  templateUrl: './are-you-sure-popup.component.html',
  styleUrls: ['./are-you-sure-popup.component.scss']
})

export class AreYouSurePopupComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: AreYouSureDialogArgs, private dialogRef: MatDialogRef<AreYouSurePopupComponent>, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

  public no(): void {
    this.dialogRef.close(false);
  }

  public yes(): void {
    this.dialogRef.close(true);
  }

}
