<div class="container">
    <mat-dialog-content class="content">
        <h2>{{ "confirmCorrectGuest" | translate }}</h2>
        <h2>{{ data.guestName }}</h2>
        <h2>{{ data.phoneNo }}</h2>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="button yes" (click)="yes()">
                {{ "yes" | translate }}
            </button>
            <button class="button no" (click)="no()">
                {{ "no" | translate }}
            </button>
        </div>
    </mat-dialog-actions>
</div>
