<div class="container">
    <mat-dialog-content class="content">
        <h2>{{ 'roomNumberIncorrectPopupDescription' | translate }}</h2>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-button">
            <button class="ok" (click)="ok()">{{ "roomNumberIncorrectPopupOk" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
