<div class="form-group"
     *ngIf="airports && airports.length > 1">
    <ion-label>{{ label | translate }}</ion-label><br />

    <mat-select class="form-control"
                [(value)]="selectedAirportId"
                (selectionChange)="selected()">
        <mat-option *ngFor="let airport of airports"
                    [value]="airport.id">{{airport.name}}</mat-option>
    </mat-select>
</div>