import { Component, OnInit } from '@angular/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-average-time',
  templateUrl: './average-time.component.html',
  styleUrls: ['./average-time.component.scss']
})

export class AvergeTimeComponent implements OnInit {
  private hotelId?: string;
  private accessToken?: string;

  avargeTime: number = 0;
  show: boolean = false;
  updateTimer: number = 0;

  loading!: HTMLIonLoadingElement;

  constructor(private getTaxiService: GetTaxiService, parameterService: ParameterService) {
    parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });
  }

  ngOnInit(): void {
    if (this.hotelId !== undefined && this.accessToken !== undefined)
      this.update();
    else
      setTimeout(this.update, 3000);
  }

  update() {
    this.getTaxiService.getAvergeTime(this.hotelId!)
      .subscribe(async data => {
        if (data != null)
          this.show = true
        else
          this.show = false

        this.avargeTime = data;

        const date = new Date();

        if (new Date().getHours() > 12) {
          date.setHours(12);
          date.setDate(date.getDate() + 1);
          this.updateTimer = date.getTime() - new Date().getTime();
        }
        else {
          date.setHours(12);
          this.updateTimer = date.getTime() - new Date().getTime();
        }

        setTimeout(this.update, this.updateTimer);
      });
  }
}