import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { TaxiStatus, RideStatus } from 'src/app/models/enums';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { IRide } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-arrivals-of-the-day',
  templateUrl: './arrivals-of-the-day.component.html',
  styleUrls: ['./arrivals-of-the-day.component.scss']
})
export class ArrivalsOfTheDayComponent implements OnInit, OnDestroy {

  upcomingRidesSubscription: Subscription = new Subscription();
  upcomingRidesTimer?: number;

  isInitialized = false;
  rides: IRide[] = [];

  @Input() onlyLatest = false;

  _date: Date = new Date();

  @Input() set date(value: Date) {
    this.isInitialized = false;

    this._date = value;

    this.updateRides();
  }

  constructor(private getTaxiService: GetTaxiService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.updateRides();
  }

  updateRides() {
    this.upcomingRidesSubscription.unsubscribe();
    clearTimeout(this.upcomingRidesTimer);

    this.upcomingRidesSubscription = this.getTaxiService.getArrivalsOfTheDay(this._date)
      .subscribe(
        (rides: IRide[]) => {
          this.isInitialized = true;

          const millisecondsPerMinute = 60000;
          // If we should only show the latest arrivals we filter out everything that happend more than 2,5 hours (150 minutes) ago
          var earliestPickupTime = new Date((<any>new Date()) - millisecondsPerMinute * 150);

          this.rides = rides
            .filter(r =>
              !this.onlyLatest || new Date(r.pickupTime) >= earliestPickupTime)
            .filter(r =>
              r.taxiStatus === TaxiStatus.Ordered ||
              r.taxiStatus === TaxiStatus.OnItsWay ||
              r.taxiStatus === TaxiStatus.TaximeterStarted ||
              r.taxiStatus === TaxiStatus.InVain ||
              r.taxiStatus === TaxiStatus.Completed ||
              r.taxiStatus === TaxiStatus.NotOrderedYet ||
              r.taxiStatus === TaxiStatus.NoShow ||
              (r.taxiStatus === TaxiStatus.Cancelled && r.rideStatus === RideStatus.CancelledBecauseTheFlightHasBeenDelayed));

          this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 60000);
        },
        error => {
          this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 60000);
        });
  }

  ngOnDestroy(): void {
    this.upcomingRidesSubscription.unsubscribe();
    clearTimeout(this.upcomingRidesTimer);
  }

  statusOnItsWay(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.OnItsWay;
  }

  statusTaximeterStarted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  statusInVain(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusCompleted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  statusNoShow(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  statusCancelledBecauseOfADelayedFlight(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Cancelled && ride.rideStatus === RideStatus.CancelledBecauseTheFlightHasBeenDelayed;
  }

}
