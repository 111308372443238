import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-fixed-destination-edit-price',
  templateUrl: './fixed-destination-edit-price.component.html',
  styleUrls: ['./fixed-destination-edit-price.component.scss']
})
export class FixedDestinationEditPriceComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() formSubmit: boolean;

  constructor(private fb: FormBuilder, private translate: TranslateService) {
    this.form = fb.group({
      hotelPrice: [null],
      taxiPrice: [null]
    });
    this.formSubmit = false;
  }

  ngOnInit(): void {
  }

  get hotelPrice() { return this.form?.get('hotelPrice'); }

  get taxiPrice() { return this.form?.get('taxiPrice'); }
}
