import { Component, OnDestroy } from '@angular/core';
import { HotelService } from 'src/app/services/hotel.service';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';

@Component({
    selector: 'receptionist-initials',
    templateUrl: './receptionist-initials.component.html',
    styleUrls: ['./receptionist-initials.component.scss']
})
export class ReceptionistInitialsComponent implements OnDestroy {

    public initials: string = "";
    public showComponent = false;
    public isValid = false;

    private initialsService: ReceptionistInitialsService;
    constructor(receptionistService: ReceptionistInitialsService, hotelService: HotelService) {
        this.initialsService = receptionistService;

        if (this.initialsService.isInitialsSet()) {
            var initialsSaved = this.initialsService.getInitials();
            if (initialsSaved) {
                this.initials = initialsSaved;
            }
        }
        this.updateValidity();

        this.showComponent = hotelService.receptionistInitialsRequiredValue;
        hotelService.receptionistInitialsRequired.subscribe(v => this.showComponent = v);
    }

    public onChange(): void {
        this.initialsService.setInitials(this.initials);
        this.updateValidity();
    }

    public updateValidity() {
        this.isValid = this.initialsService.isInitialsSet();
    }

    ngOnDestroy(): void {

    }

}