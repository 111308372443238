<mat-form-field appearance="fill" 
                [ngClass]="{'rounding-20': rounding}"
                class="date-time-picker input-shadow-date-time-picker">
       <input matInput
              type="datetime-local"
              [min]="minDate"
              [max]="maxDate"
              [formControl]="_formControlDate"
              (change)="updatingValue($event)"
              required
              class="date-time-input"
              [ngClass]="{'rounding-20': rounding}">

              <!-- 
                     Comment in if rush hour warning is wanted again
                     <app-rush-hour-warning>[date]="date"</app-rush-hour-warning>
              -->
</mat-form-field>