<div class="container">
    <h2 mat-dialog-title class="title">{{ "receipt_largeTaxiPopUpTitle" | translate }}</h2>
    <mat-dialog-content class="content">
        {{ textTranslation }}
        {{ taxiHotelNumberTranslation }}
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="clean-btn new-session" [mat-dialog-close]="true" cdkFocusInitial>{{ "receipt_largeTaxiPopUpButton" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
