import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-taxi-overview',
  templateUrl: './taxi-overview.component.html',
  styleUrls: ['./taxi-overview.component.scss']
})
export class TaxiOverviewComponent implements OnInit {

  constructor(private pageService: PageService) { }

  ngOnInit(): void {
    this.pageService.currentHeader = '';
  }

}
