<div class="container">
    <h2 mat-dialog-title class="title">{{ "selfServiceStation_paidPopupTitle" | translate }}</h2>
    <mat-dialog-content class="content">
        <div [innerHTML]="'selfServiceStation_paidPopupDescription' | translate"></div>
    </mat-dialog-content>

    <form>
        <div [formGroup]="formGroup">
            <div class="email-title">{{ "selfServiceStation_emailTitle" | translate }}</div>
            <ion-input type="email"
                    class="email-input"
                    formControlName="email"
                    placeholder="{{'selfServiceStation_emailPlaceholder' | translate}}"
                    email></ion-input>
            <div class="error-message"
                *ngIf="formGroup.controls['email'].invalid">{{ "selfServiceStation_emailError" | translate }}</div>
    
            <button mat-flat-button
                class="order-submit"
                [disabled]="!formGroup.valid"
                (click)="onClick()"
                color="primary">{{ "selfServiceStation_getMail" | translate }}</button>
        </div>
    </form>

    <mat-dialog-actions>
            <button class="clean-btn" mat-dialog-close>{{ "selfServiceStation_paidPopupButton" | translate }}</button>
    </mat-dialog-actions>
</div>
