<ion-content>
  <app-pause-screen #pauseScreen (onResume)="handleResume()" [logoSrc]="hotelInfo?.logoSrc" [language]="hotelInfo?.language"></app-pause-screen>
  <div class="container"> 
    <app-new-header [logoSrc]="hotelInfo?.logoSrc" [language]="hotelInfo?.language"></app-new-header>

    <div class="center" [ngClass]="{'hide-top': hideTop}">
      <div class="taxiHotel">
        <div class="tabs">
          <form [formGroup]="orderFormGroup"
            #orderFormGroupForm="ngForm"
            class="orderOptions">
            <div class="title">
              <label>{{ "ready-for-order" | translate }}</label>
            </div>

            <div class="inline">
              <div class="pick-time-and-price">
                <div>
                  <label class="small-title">{{ "pick-up-time" | translate }}</label>
                  <app-new-date-time-picker formControlName="pickupTime"
                                            [autoIncrementTime]="true"
                                            (change)="onChangeDate()"></app-new-date-time-picker>
                </div>
              </div>  

              <div class="identicfication">
                <ion-label class="small-title">{{ "name-optional" | translate }}</ion-label>
                <ion-input formControlName="guestName"
                          type="text"
                          [placeholder]="translate.instant('name-optional-placeholder')"
                          class="form-controls" input-placeholder></ion-input>
              </div>
            </div>

              <div class="inline padding-top">
                <div *ngIf="showPhone()">
                  <ion-label class="small-title">{{ "selfServiceStation_phoneNumberTitle" | translate }}</ion-label>
                  <app-phone-number-input-pc 
                    type="button"
                    (onPressNumber)="phoneSelect($event)"
                    [hotelId]="hotelId">
                  </app-phone-number-input-pc>
                </div>

                <div class="order-button">
                  <div class="order-submit-wrapper">
                    <button mat-flat-button
                            class="order-submit"
                            [disabled]="!orderFormGroup.valid"
                            (click)="submit()"
                            color="primary">{{ "order-taxi" | translate }}</button>
                  </div>      
                </div>
              </div>
          </form>

          <div class="upcomming-rides-div" [ngClass]="{'hide-bottom': hideBottom}">
            <label class="title">{{ "departures" | translate }}</label>
            <div class="upcomming-rides">
              <div class="ride" *ngFor="let ride of rides">
                <div class="ride-info">
                  <div class="small-box left-box">
                    <span>{{ 'pick-up-time' | translate }}: </span> 
                    <strong>{{ getPickUpTime(ride.pickupTime) }}</strong>
                  </div>
                  <div class="small-box">
                    <span>{{ 'eta-title' | translate }}: </span> 
                    <strong>{{ getEtaText(ride.etaOfTaxi) }}</strong>
                  </div>
                  <div class="small-box left-box">
                    <span>{{ 'name' | translate }}: </span> 
                    <strong>{{ ride.guestName }}</strong>
                  </div>
                  <div class="small-box left-box">
                    <span>{{ 'status' | translate }}: </span> 
                    <strong [ngClass]="getTaxiStatusClass(ride)">{{ getTaxiStatusText(ride) }}</strong>
                  </div>
                </div>
                <button mat-stroked-button *ngIf="activeCancel(ride)" class="button-base cancel-active" (click)="cancelRide(ride)">
                  {{'cancelTaxi' | translate}}
                </button>
                <button mat-stroked-button disabled *ngIf="!activeCancel(ride)" class="button-base cancel-not-active">
                  {{'cancelTaxi' | translate}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>