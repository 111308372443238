import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  private _currentHeader: BehaviorSubject<string> = new BehaviorSubject('');

  get currentHeader() {
    return this._currentHeader.value;
  }

  set currentHeader(header: string) {
    this._currentHeader.next(header);
  }

  set currentHeaderTranslationKey(translationKey: string) {
    this.translate.get(translationKey).subscribe(value => this.currentHeader = value);
  }

  constructor(private translate: TranslateService) { }

  connectCurrentHeader(): Observable<string> {
    return this._currentHeader.pipe();
  }

}
