import { Component, OnInit } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';

@Component({
  selector: 'app-cancel-taxi',
  templateUrl: './cancel-taxi.component.html',
  styleUrls: ['./cancel-taxi.component.scss']
})
export class CancelTaxiComponent implements OnInit {

  loading!: HTMLIonLoadingElement;
  statusLoaded = false;
  isCancelled = false;
  errorOnCancel = false;

  taxiCouldnotBeCancelledByGuest = '';

  constructor(private service: GetTaxiService, private translate: TranslateService, private parameterService: ParameterService, public loadingController: LoadingController, taxiConfigurationService: TaxiConfigurationService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: params.rideId
      }

      await this.presentLoading()

      this.service.getCountryRide(params.rideId!).subscribe(async (data) => {
        let lang = "en";
        switch (data.country) {
          case "Denmark":
            lang = "da"
            break;
          case "Norway":
            lang = "no"
            break;
          case "Germany":
            lang = "de"
            break;
          case "Sweden":
            lang = "en"
            break;
          case "Finland":
            lang = "fi"
            break;
        }
        this.translate.setDefaultLang(lang);
      });

      this.service.getRideInfo(body).subscribe(async (data) => {
        await this.hideLoading()

        this.statusLoaded = true;
        this.isCancelled = data.taxiIsCancelled === true;
      }, async (error) => {
        await this.hideLoading()
      });

      this.taxiCouldnotBeCancelledByGuest = this.translate.instant('taxiCouldnotBeCancelledByGuest').replace('{taxiGuestNumber}', taxiConfigurationService.taxiGuestNumberValue)
    });
  }

  ngOnInit(): void {
  }

  cancelRide() {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: params.rideId
      }

      await this.presentLoading()

      this.service.cancelRide(body).subscribe(async (data) => {
        await this.hideLoading()

        this.isCancelled = data.taxiIsCancelled === true;
        if (!this.isCancelled)
          this.errorOnCancel = true;
      }, async (error) => {
        await this.hideLoading()
      });
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}
