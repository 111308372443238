import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-chooser',
  templateUrl: './language-chooser.component.html',
  styleUrls: ['./language-chooser.component.scss']
})
export class LanguageChooserComponent {

  @Input() language: any;

  constructor(private translate: TranslateService) { }

  useLanguage(lang: any) {
    this.translate.setDefaultLang(lang);
    localStorage.setItem('lang', lang);
    sessionStorage.setItem("langChosen", "true");
  }
}
