import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { PaymentOption } from 'src/app/services/hotel.service';
import { Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ErrorOrderPopupComponent } from 'src/app/shared/components/error-order-popup/error-order-popup.component';
import { ParameterService } from 'src/app/services/parameter.service';
import { OrderType } from 'src/app/enums/order-type.enum';
import { AttributesToTaxi } from 'src/app/services/ride.service';
import { LogService } from 'src/app/services/log.service';
import { IHotelInfo } from 'src/app/models/hotel-info.interface';
import { HotelResponseMapperService } from 'src/app/mappers/hotel-response-mapper.service';
import { IDirectTaxiRequest } from 'src/app/models/ride/directtaxi.interface';
import { ICoordinate } from 'src/app/models/coordinate.interface';
import { ReceptionMapperService } from 'src/app/shared/mappers/reception-mapper.service';
import { takeUntil } from 'rxjs/operators';
import { TaxiStatus } from 'src/app/models/enums';
import { IRide } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-fast-order',
  templateUrl: './fast-order.component.html',
  styleUrls: ['./fast-order.component.scss']
})
export class FastOrderComponent implements OnInit, OnDestroy {
  loading!: HTMLIonLoadingElement;

  paymentOptionsSubscription?: Subscription;
  hotelInfo?: IHotelInfo;
  subscr$ = new Subject();

  public orderFormGroup: FormGroup;
  hotelId: any;
  accessToken: any;
  logoSrc?: string;
  taxiCars: ICoordinate[] = [];
  hotelLocation: ICoordinate | undefined;
  availableOptions: string[] = [];

  rides: IRide[] = [];
  upcomingRidesTimer?: number;
  subject = new Subject();

  width: string = "45vw";
  height: string = "84vh";

  constructor(private fb: FormBuilder, public dialog: MatDialog, private alertController: AlertController, public translate: TranslateService, private getTaxiService: GetTaxiService, private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private parameterService: ParameterService, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, private log: LogService, private readonly changeDetectorRef: ChangeDetectorRef) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;

      this.getTaxiService.getHotelInfo(params.hotelId)
        .subscribe(data => {
          this.hotelInfo = HotelResponseMapperService.mapHotelInfoToIHotelInfo(data)

          this.logoSrc = this.hotelInfo.logoSrc;
          this.hotelLocation = { lng: this.hotelInfo.longitude, lat: this.hotelInfo.latitude, minutesAway: 0, additionalGuestInfo: '' }

          if (sessionStorage.getItem("langChosen") != "true") {
            if (this.hotelInfo.language == 'se')
              this.translate.setDefaultLang('en');
            else
              this.translate.setDefaultLang(this.hotelInfo.language);
            localStorage.setItem("lang", this.hotelInfo.language);
          }
        });

      getTaxiService.getTaxiLocations().subscribe(dataCoordinate => {
        this.taxiCars = dataCoordinate?.length ? dataCoordinate
          .map((el: any) => ReceptionMapperService.mapTaxiMapFormToICoordinate(el)) : [];
      });

      setInterval(() => {
        getTaxiService.getTaxiLocations().subscribe(dataCoordinate => {
          this.taxiCars = dataCoordinate?.length ? dataCoordinate
            .map((el: any) => ReceptionMapperService.mapTaxiMapFormToICoordinate(el)) : [];
        });
      }, 30000);

    });

    this.orderFormGroup = fb.group({
      from: [null],
      guestName: [null],
      pickupTime: [new Date(), Validators.required],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      numberOfGuests: [3],
      paymentOption: [PaymentOption.PayInTaxi, Validators.required],
      taxiPrice: [0],
      largeTaxi: [false],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]]
    });

    this.numberOfCars?.valueChanges.subscribe(_ => {
      this.priceService.setNumberOfCars(this.numberOfCars?.value);
    })

    priceService.taxiPrice.subscribe(price => {
      this.taxiPrice?.setValue(0);
      if (price === undefined) { }
      else {
        this.taxiPrice?.setValue(price.customerPrice);
      }
    });

    this.taxiConfigurationService.taxiAvailableOptions.subscribe((data) => {
      this.availableOptions = data;
    });
  }

  ngOnInit(): void {
    this.priceService.setManualPrice(this.orderFormGroup.value.taxiPrice);

    this.priceService.setFromHotelAddress();

    this.changeDetectorRef.detectChanges();

    this.updateRides();
  }

  ngOnDestroy(): void {
    this.paymentOptionsSubscription?.unsubscribe();

    this.subscr$.next();
    this.subscr$.complete();

    clearTimeout(this.upcomingRidesTimer);
  }

  formSubmit: boolean = false;

  async submit() {
    this.log.information("Ordering taxi with hotelId: " + this.hotelId);
    await this.presentLoading();

    // I am saving the initials no matter what. Even if the validation (or request) fails,
    // I expect that the same receptionst will be present

    this.formSubmit = true;

    this.paymentOption!.updateValueAndValidity();
    if (!this.orderFormGroup.valid) {
      await this.hideLoading();
      return;
    }

    var numberOfGuests = this.largeTaxi ? 7 : 1;

    const body: IDirectTaxiRequest = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      roomNumber: undefined,
      guestName: this.guestName?.value,
      numberOfCars: 1,
      phoneNumber: this.phoneNo?.value,
      pickupTime: this.pickupTime!.value.toISOString(),
      stationCar: false,
      paymentOption: PaymentOption.PayInTaxi,
      largeTaxi: this.largeTaxi?.value,
      orderType: OrderType.Mobile,
      attributesToTaxi: AttributesToTaxi.None,
      numberOfGuests: numberOfGuests
    }

    this.saveTaxiService.directTaxiRide(body).subscribe(async (data) => {
      await this.hideLoading();
      window.location.reload();
    }, async (error) => {
      await this.hideLoading();
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });
  }

  updateRides() {
    const upcomingRidesSubscription = this.getTaxiService.getRestaurantRides(this.hotelId);

    upcomingRidesSubscription.pipe(takeUntil(this.subject)).subscribe((rides: Array<any>) => {
      this.rides = rides;

      this.rides.sort((a, b) => a.pickupTime > b.pickupTime ? 1 : -1);

      this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 30000);
    },
      error => {
        this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 60000);
      });
  }

  async cancelRide(ride: IRide) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: ride.id
      }

      await this.presentLoading()

      this.getTaxiService.cancelRide(body).subscribe(async (data) => {
        await this.hideLoading()

        var textKey = this.translate.instant('backendStatusCodeTaxiCouldNotBeCancelled').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
        if (data.taxiIsCancelled) {
          textKey = this.translate.instant('taxiHasBeenCancelled');
          ride.taxiStatus = TaxiStatus.Cancelled;
        }

        const alert = await this.alertController.create({
          header: this.translate.instant('taxiStatusHeader'),
          subHeader: textKey,
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();

        const result = await alert.onDidDismiss();
        if (result)
          window.location.reload();

      }, async (error) => {
        await this.hideLoading()
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const alert = await this.alertController.create({
            header: "Error Occurred",
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ],
            backdropDismiss: false
          });
          await alert.present();
        }
      })
    });
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  getEtaText(eta: number | undefined): string {
    if (eta)
      return eta + " min."
    else return this.translate.instant('shortTaxiStatusUnknown')
  }

  statusOnItsWay(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.OnItsWay;
  }

  statusTaximeterStarted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  statusInVain(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusUnknown(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  statusArrived(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Arrived;
  }

  statusCancelled(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  statusNoShow(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  statusCompleted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  statusOrdered(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Ordered;
  }

  get phoneNo() {
    return this.orderFormGroup.get('phoneNo');
  }

  get numberOfCars() {
    return this.orderFormGroup.get('numberOfCars');
  }

  get from() {
    return this.orderFormGroup.get('from');
  }

  get pickupTime() {
    return this.orderFormGroup.get('pickupTime');
  }

  get largeTaxi() {
    return this.orderFormGroup.get('largeTaxi');
  }

  get paymentOption() {
    return this.orderFormGroup.get('paymentOption');
  }

  get taxiPrice() {
    return this.orderFormGroup.get('taxiPrice');
  }

  get guestName() {
    return this.orderFormGroup.get("guestName");
  }

  get largeTaxiTranslation() {
    if (this.hotelInfo?.country === 'Norway') {
      const now = new Date("2024-08-25");
      const day = now.getUTCDay(); // 0 (Sunday) to 6 (Saturday)
      const hour = now.getUTCHours(); // Hours in UTC

      // Check if it's between Friday 15:00 and Monday 08:00 UTC
      if (
        (day === 5 && hour >= 15) || // Friday after 15:00
        (day === 6) || // Saturday
        (day === 0) || // Sunday
        (day === 1 && hour < 8) // Monday before 08:00
      ) {
        return this.translate.instant('largeTaxiDescription-norway');
      }
    }
    return this.translate.instant('fixedDestination-largeTaxiDescription');
  }

  showPhone(): boolean {
    if (this.hotelInfo?.country != 'Finland')
      return false;

    const userAgent = navigator.userAgent;

    // Regular expression to detect mobile devices (phone/tablet)
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

    // If not mobile, then it's likely a desktop
    return !isMobile;
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  paymentOptionFilter(option: PaymentOption) {
    if ((option & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal) {
      return PaymentOption.PayByTerminal
    }
    return option;
  };

  paymentOptionFilterIntegration(option: PaymentOption) {
    if ((option & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill) {
      return PaymentOption.ApplyToBill
    }
    return option;
  };

  public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
    this.dialog.open(ErrorOrderPopupComponent, {
      data: {
        taxiName,
        taxiHotelNumber
      }
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  onChangePrice() {
    this.priceService.setManualPrice(this.orderFormGroup.value.taxiPrice);
  }

  public onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }
}