import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-pay-in-taxi-popup',
  templateUrl: './new-pay-in-taxi-popup.component.html',
  styleUrls: ['./new-pay-in-taxi-popup.component.scss']
})
export class NewPayInTaxiPopupComponent implements OnInit {
  translatedDescription_beforePrice: string;
  translatedDescription_priceSection: string;
  translatedDescription_afterPrice: string;

  constructor(private dialogRef: MatDialogRef<NewPayInTaxiPopupComponent>, public dialog: MatDialog, public translateService: TranslateService, @Inject(MAT_DIALOG_DATA) public data: { price: number, currency: string }) {
    const translatedInstant = this.translateService.instant('newPayInTaxiPopup_description');
    this.translatedDescription_beforePrice = translatedInstant.split("{{fixedPrice}}")[0];
    this.translatedDescription_priceSection = `${this.data.price} ${this.data.currency}`;
    this.translatedDescription_afterPrice = translatedInstant.split("{{fixedPrice}}")[1];
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public continuePayAtTerminal(): void {
    //close the dialog if the user wants to pay at the terminal 
    //and then return false because the user "did not" want to pay in taxi

    this.dialogRef.close(false);
  }

  public continuePaidInTaxi(): void {
    this.dialogRef.close(true);
  }

  public cancelBooking(): void {
    //This refreshes the page which also clears the terminal
    this.reloadWindow();
  }

  public reloadWindow(): void {
    window.location.reload();
  }
}
