import { IPaymentIntentResponse } from "src/app/models/payment-intent-response.interface";
import { CollectPaymentMethodResponse, TerminalReader } from "src/app/models/payment/terminal.interface";
import { PaymentApiService } from "../payment-api.service";
import { LogService } from "../../log.service";

export abstract class PaymentTerminal {

    constructor(public paymentApiService: PaymentApiService, public log: LogService, hotelId: string, terminalLocationId?: string, terminalReaderId?: string) {
        this.setupTerminal(hotelId, terminalLocationId, terminalReaderId);
    }

    abstract setupTerminal(hotelId: string, terminalLocationId?: string, terminalReaderId?: string): Promise<void>;
    public abstract get selectedReader(): TerminalReader | undefined;

    public abstract cancelCollectPaymentMethod(): void;
    public abstract collectPayment(paymentIntentData: IPaymentIntentResponse): Promise<CollectPaymentMethodResponse>;
}
