import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { StripeTerminal } from './payment-integrations/stripe';
import { NmiTerminal } from './payment-integrations/nmi';
import { PaymentTerminal } from './payment-integrations/payment-terminal';
import { CollectPaymentMethodResponse } from 'src/app/models/payment/terminal.interface';
import { IPaymentIntentResponse } from 'src/app/models/payment-intent-response.interface';
import { ICreateCheckoutRequest } from 'src/app/models/payment/createCheckoutRequest.interface';
import { ICreateCheckoutResponse } from 'src/app/models/payment/createCheckoutResponse.interface';
import { IPaymentInfo } from 'src/app/models/payment/paymentInfo.interface';
import { ICreatePaymentIntentRequest } from 'src/app/models/payment/createIntentRequest.interface';
import { LogService } from '../log.service';

@Injectable({
    providedIn: 'root'
})

export class PaymentApiService {
    public apiUrl: string = environment.api;

    constructor(public http: HttpClient, public log: LogService) {
    }

    public getPaymentToken(hotelId: string): Observable<any> {
        return this.http.get(this.apiUrl + environment.selfServiceStationGetPaymentToken.replace('{hotelId}', hotelId));
    }
    public createIntent(body: ICreatePaymentIntentRequest): Observable<any> {
        return this.http.post(this.apiUrl + environment.selfServiceStationCreateIntent, body);
    }
    public createCheckout(body: ICreateCheckoutRequest): Observable<ICreateCheckoutResponse> {
        return this.http.post<ICreateCheckoutResponse>(this.apiUrl + environment.selfServiceStationMobileCreateCheckout, body);
    }
    public getCheckout(checkoutId: string): Observable<IPaymentInfo> {
        return this.http.get<IPaymentInfo>(this.apiUrl + environment.selfServiceStationMobileGetCheckoutData.replace('{checkoutId}', checkoutId));
    }

    public getPaymentIntentId(hotelId: string, authStatusId: string): Observable<any> {
        const body: any = {
            hotelId: hotelId,
            authStatusId: authStatusId
        }

        return this.http.post(this.apiUrl + environment.getPaymentIntentId, body);
    }

    private getPaymentServiceVariant(hotelId: string): Observable<any> {
        return this.http.get(this.apiUrl + environment.getPaymentServiceVariant.replace('{hotelId}', hotelId));
    }

    private terminal?: PaymentTerminal;

    public async collectPayment(paymentIntentData: IPaymentIntentResponse): Promise<CollectPaymentMethodResponse> {
        if (!this.terminal) {
            console.warn("Terminal not setup when trying to collect payment");
            return Promise.reject("Terminal not setup so collect paymentPromise rejected");
        }

        return this.terminal.collectPayment(paymentIntentData);
    }

    public cancelCollectPaymentMethod(): void {
        if (!this.terminal) {
            console.warn("Terminal not setup when trying to cancel collect payment method");
            return;
        }

        this.terminal.cancelCollectPaymentMethod();
    };

    public async setupTerminal(hotelId: string, terminalLocationId?: string, terminalReaderId?: string): Promise<void> {
        if (this.terminal) {
            console.log("Terminal already setup");
            return;
        }

        let paymentServiceVariant: string;
        try {
            paymentServiceVariant = (await this.getPaymentServiceVariant(hotelId).toPromise()).variant;
        } catch (error) {
            console.error("Failed to get payment service variant");
            throw new Error("Failed to get payment service variant");
        }

        switch (paymentServiceVariant.toLowerCase()) {
            case 'stripe':
                this.terminal = new StripeTerminal(this, this.log, hotelId, terminalLocationId, terminalReaderId);

                break;
            case 'nmi':
                this.terminal = new NmiTerminal(this, this.log, hotelId, terminalLocationId, terminalReaderId);

                break;
            default:
                console.error("Could not find payment service variant: " + paymentServiceVariant);
                throw new Error("Could not find payment service variant: " + paymentServiceVariant);
        }
    }
}