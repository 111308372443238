import { Component, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-phone-number-form-group',
  templateUrl: './phone-number-form-group.component.html',
  styleUrls: ['./phone-number-form-group.component.scss'],
})
export class PhoneNumberFormGroupComponent implements OnInit, ControlValueAccessor {

  constructor(@Optional() public ngControl: NgControl, public translate: TranslateService) {
    if (this.ngControl != null)
      this.ngControl.valueAccessor = this;
  }

  @Input() formSubmitted: boolean = false;

  onChange: any = () => { };

  phoneNumber: string | null = null;

  onChangePhoneNo(e: any) {
    const val: string = e.target.value || null;

    this.onChange(val);
    this.phoneNumber = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  writeValue(obj: string): void {
    this.phoneNumber = obj;
  }
  registerOnTouched(fn: any): void { }
  ngOnInit(): void { }

}
