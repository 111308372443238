import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-language-chooser',
  templateUrl: './new-language-chooser.component.html',
  styleUrls: ['./new-language-chooser.component.scss']
})
export class NewLanguageChooserComponent {

  @Input() language: any;
  dropdownOpen = false;

  constructor(private translate: TranslateService) { }

  useLanguage(lang: any) {
    this.translate.setDefaultLang(lang);
    localStorage.setItem('lang', lang);
    sessionStorage.setItem("langChosen", "true");
  }

  toggleDropdown(): void {
    this.dropdownOpen = !this.dropdownOpen;
  }

  closeDropdown(): void {
    this.dropdownOpen = false;
  }
}
