<app-station-header></app-station-header>
<form [formGroup]="voucherFG">
    <div class="flex-container">
        <div class="text">
            TESTING THIS
            TEST TEST TEST
        </div>
        <div class="slider-container">
            <mat-slider 
                class="slider"
                min="0" 
                (max)="maxValue" 
                (step)="stepvalue" 
                value="0" 
                matSliderThumb 
                formControlName="valueSlider" 
                #slider
            ></mat-slider>
        </div>
    </div>
</form>