<ion-content>
    <app-header></app-header>

    <div class="body">
        <div class="container">
            <div class="sops-container">
                <h1>SOPs</h1>
                <mat-accordion>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Airport Pickup</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="doc-container">
                            <span class="title">Standard Operating Procedure  Airport pickup</span>
                            <span class="blue-title">Purpose</span>
                            <p class="small-text">To establish guidelines for operating the CommuteApp interface when
                                booking pickup at the airport</p>
                            <p class="small-text">By using CommuteApp, the hotel can arrange pickup from the airport, at
                                a fixed price paid at the hotel.</p>
                            <p class="small-text">The CommuteApp guest link is available in booking confirmation /
                                welcome letter.</p>
                            <p class="small-text">The system automatically calculates and communicates taxi pick up time
                                to both the taxi driver and guest. General pick-up time is 40 minutes after landing.
                                With delays, the system automatically communicates with the taxi driver.</p>
                            <span class="blue-title">Definitions</span>
                            <p class="small-text">n/a</p>
                            <span class="blue-title">Procedure</span>
                            <p class="small-text">Booking of pickup via the airport can be both via the link in the
                                booking confirmation, or via direct communication with the reception</p>
                            <p class="small-text">When booking pickup via the CommuteApp interface</p>
                            <ol class="small-text">
                                <li>Input the guests flight number, hotel reservation number, and name
                                <ol type="a"><li>Also input phone number and/or email address so the guests gets notifications on the status of their taxi</li></ol> </li>
                            <li>Enter the expected flight arrival time
                            <ol type="a"><li>The system will automatically notify you and the guest via email, and the taxi drivers vie their own system, if the flight is delayed</li></ol></li>
                            <li>Select any relevant options</li>
                            <li>Select payment method
                            <ol type="a"><li>If room bill is chosen, choose if the guest want to rideshare
                            </li><li>Finish the order as normal</li></ol></li>
                            </ol>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Booking & Billing</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="doc-container">
                            <span class="title">Standard Operating Procedure Booking a taxi</span>
                            <span class="blue-title">Purpose</span>
                            <p class="small-text">To establish guidelines for operating the CommuteApp interface when
                                booking a taxi for a customer.</p>
                            <span class="blue-title">Definitions</span>
                            <p class="small-text">n/a</p>
                            <span class="blue-title">Procedure</span>
                            <span class="subtitle">When booking a taxi for a guest</span>
                            <div [innerHTML]="sopBookingAdditionalInfo"></div>
                            <ol>
                                <li>Select one of the options for booking a taxi, Destination, To Airport, From
                                    Airport
                                </li>
                                <li>Enter the destination that the guest would like to go to
                                    <ol type="a">
                                        <li>When selecting a destination that is not a fixed price destination, only
                                            payment in the taxi is available
                                        </li>
                                    </ol>
                                </li>
                                <li>Select the payment option
                                    <ol type="a">
                                        <li>Room bill will have to be added to the guest’s room bill later
                                            <ol type="i">
                                                <li>This is also shown in the Bills tab.</li>
                                                <li>See also point 9.</li>
                                            </ol>
                                        </li>
                                        <li>Credit Card payment will be paid at the hotel
                                            <ol type="i">
                                                <li>Hand out a receipt to the guest afterwards
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                                <li>Select the time of pickup.
                                    <ol type="a">
                                        <li>Here you can specify date and time
                                        </li>
                                    </ol>
                                </li>
                                <li>Add guest information such as Room Number, Name and Phone number
                                    <ol type="a">
                                        <li>This information is sent to the taxi company, so they know who to pick up.
                                        </li>
                                    </ol>
                                </li>
                                <li>Select any additional options and add comments
                                    <ol type="a">
                                        <li>E.g. note down how many people are in the taxi, and how much luggage they
                                            are bringing
                                        </li>
                                    </ol>
                                </li>
                                <li>Press the ‘Order taxi’ button
                                </li>
                                <li>Inform the guest that it is important to tell the driver your name and room number,
                                    when meeting the driver.
                                    <ol type="a">
                                        <li>This ensures the guest gets the right prepaid taxi. 
                                        </li>
                                    </ol>
                                </li>
                                <li>If ‘Room bill’ was selected, add the price unto the room bill, then go to the bills
                                    tab and confirm that the ride has been billed to the room, as seen below:
                                    <p class="small-text"><br/>Every time you see a red color there is a ride to be
                                        charged
                                        the room bill</p>
                                    <img src="assets/images/SOPs/sops-bill-one-room.png" width="130" alt=""/>
                                    <p class="small-text">Press the Bill button and you will be taken to the billing
                                        overview</p>
                                    <p class="small-text">All details of the booking is stated under the respective
                                        booking. After you have charged the ride to the room bill, press the ‘Confirm
                                        the amount is added to the room’ to let the system know that the ride has been
                                        paid</p>
                                    <img src="assets/images/SOPs/sops-billing-detail.png" width="400" alt=""/>
                                </li>
                                <li> If ride is paid by the hotel
                                    <img src="assets/images/SOPs/sops-billing-options.png" width="400" alt=""/>
                                    <ol type="a">
                                        <li>When using this function, the hotel will be charged the ride</li>
                                        <li>Only use this if e.g. an employee needs a taxi to a meeting or if you want
                                            to compensate a guest for various reasons
                                        </li>
                                    </ol>
                                </li>
                                <li> Creditcard payment at the hotel & ensuring the guest gets the correct taxi
                                    <ol type="a">
                                        <li>If it is a fixed price trip, the amount will be visible in the CommuteApp
                                            system when ordering the taxi.
                                        </li>
                                        <li>Receive payment by credit card on your “kiosk system”. Hand out a receipt to
                                            the guest afterwards.
                                        </li>
                                        <li>Inform the guest that it is important to tell the driver your name and room
                                            number, when meeting the driver. This ensures the guest gets the right
                                            prepaid taxi
                                            <ol type="i">
                                                <li>Note: if you put in a comment to the taxi driver such as “Bring
                                                    whiteboard and write the guest’s name and room number” you can also
                                                    ensure that the guest can easily find their taxi. Contact your taxi
                                                    partner under ‘Taxi Support’ for help
                                                </li>
                                            </ol>
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <b>Ridesharing</b>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="doc-container">
                            <span class="title">Standard Operating Procedure Ridesharing</span>
                            <span class="blue-title">Purpose</span>
                            <p class="small-text">To establish guidelines for operating the CommuteApp interface when
                                enabling the ridesharing function</p>
                            <span class="blue-title">Definitions</span>
                            <p class="small-text">n/a</p>
                            <span class="blue-title">Procedure</span>
                            <span class="subtitle">When booking a taxi for a guest</span>
                            <ol>
                                <li>If the guest wants to share their taxi with another guest, the CommuteApp interface
                                    can coordinate ridesharing between guests
                                </li>
                                <li>If the guest is going to, or being picked up at, the airport, the guest can opt-in
                                    to sharing their taxi ride with another guest
                                </li>
                                <li>When booking a ride for the guest, you can enable the ridesharing feature, after
                                    selecting the ‘Room bill’ payment option.
                                    <ol type="a">
                                        <li>The guests that partake in ridesharing, can save up to 150dkk on their ride
                                            when ridesharing
                                        </li>
                                        <li>The guest might have to leave 15 minutes earlier when ridesharing
                                        </li>
                                    </ol>
                                </li>
                                <li>Specify how much luggage the guest is bringing
                                    <ol type="a">
                                        <li>Note, max 2 bags per guest when ridesharing</li>
                                    </ol>
                                </li>
                                <li>Add the guests phone number, so they get informed if their ridesharing request is
                                    fulfilled
                                </li>
                                <li>Complete the booking like normal</li>
                            </ol>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="content">
                <p [innerHtml]="supportText"></p>
            </div>
        </div>
    </div>
</ion-content>
