import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AdminService } from './admin.service';
import { ParameterService } from './parameter.service';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export abstract class TaxiService {
  public api_url: string = environment.api;

  hotelId: string = "";
  accessToken: string | undefined = "";

  constructor(public http: HttpClient, private parameterService: ParameterService, private adminService: AdminService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });
  }

  post<T>(urlSuffix: string, body: any, includeAccessToken: boolean = false, includeAdminPassword = false) {
    let headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')

    var params = new HttpParams();
    if (includeAccessToken) {
      params = params.set('hotelId', this.hotelId);

      if (this.accessToken)
        params = params.set('accessToken', this.accessToken);
    }
    params = params.set('version', VersionService.currentVersion);

    if (includeAdminPassword) {
      if (!this.adminService.isLoggedIn)
        debugger;

      body['adminPassword'] = this.adminService.adminPassword;
    }

    return this.http.post<T>(this.api_url + urlSuffix, body, { headers: headers, params: params });
  }

}
