import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface CheckIfCorrectGuestComponentArgs {
  guestName: string;
  phoneNo: string;
}

@Component({
  selector: 'app-check-if-correct-guest',
  templateUrl: './check-if-correct-guest.component.html',
  styleUrls: ['./check-if-correct-guest.component.scss']
})

export class CheckIfCorrectGuestComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: CheckIfCorrectGuestComponentArgs, private dialogRef: MatDialogRef<CheckIfCorrectGuestComponent>) {
  }

  public no(): void {
    this.dialogRef.close(false);
  }

  public yes(): void {
    this.dialogRef.close(true);
  }

}
