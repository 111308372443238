import { Component, OnInit } from '@angular/core';
import { GetTaxiService } from '../../services/get-taxi.service';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { PageService } from 'src/app/services/page.service';
import { PaymentOption } from 'src/app/services/hotel.service';
import { OrderType } from '../../enums/order-type.enum';
import { ParameterService } from 'src/app/services/parameter.service';
import { AdminService } from 'src/app/services/admin.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ReceptionCommentComponent } from 'src/app/shared/components/reception-comment/reception-comment.component';
import { AttributesToTaxi } from 'src/app/services/ride.service';
import { UpdateRideDialogComponent } from '../update-ride-dialog/update-ride-dialog.component';
import { CancelledByDriver, TaxiStatus } from 'src/app/models/enums';

@Component({
  selector: 'app-control-trips',
  templateUrl: './control-trips.component.html',
  styleUrls: ['./control-trips.component.scss']
})
export class ControlTripsComponent implements OnInit {
  controlDate: Date;

  hotelId: any;
  accessToken: any;
  query: any;
  public searchFilter: any = '';
  loading!: HTMLIonLoadingElement;
  lang: any;

  isAdmin: boolean = false;
  hasMoreRides: boolean = false;
  pagingNumber: number = 0;

  constructor(public service: GetTaxiService, private alertController: AlertController, private parameterService: ParameterService, private translate: TranslateService, public loadingController: LoadingController, private taxiConfigurationService: TaxiConfigurationService, private pageService: PageService, private adminService: AdminService, private dialog: MatDialog) {
    let today = new Date();
    this.controlDate = new Date(today.getFullYear(), today.getMonth(), 2, 0, 0, 0, 0);
    this.lang = localStorage.getItem("lang");

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });

    this.isAdmin = this.adminService.isLoggedIn;
  }

  trips: any[] = [];
  orderType = OrderType;

  ngOnInit(): void {
    this.getRides(this.controlDate, false);
    this.pageService.currentHeaderTranslationKey = 'tripAdmin';
  }

  addDate() {
    this.controlDate = new Date(
      this.controlDate.getFullYear(),
      this.controlDate.getMonth() + 1,
      this.controlDate.getDate());
    this.pagingNumber = 0;
    this.getRides(this.controlDate, false);
  }

  prevDate() {
    this.controlDate = new Date(
      this.controlDate.getFullYear(),
      this.controlDate.getMonth() - 1,
      this.controlDate.getDate());
    this.getRides(this.controlDate, false);
  }

  isCancelled(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  isTaxiMeterStarted(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  isCompleted(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  isUnknown(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  isNoShow(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  isArrived(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.Arrived;
  }

  isInVain(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  isNotOrderedYet(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.NotOrderedYet;
  }

  isOrdered(ride: { taxiStatus: number }) {
    return ride.taxiStatus === TaxiStatus.Ordered;
  }

  isFlightArrivalTimeManuallyEntered(ride: { flightArrivalTimeManuallyEntered: boolean }) {
    return ride.flightArrivalTimeManuallyEntered === true;
  }

  isNotComming(ride: { taxiStatus: number }) {
    if (ride.taxiStatus === TaxiStatus.Cancelled)
      return true;
    if (ride.taxiStatus === TaxiStatus.InVain)
      return true;
    if (ride.taxiStatus === TaxiStatus.NoShow)
      return true;
    return false;
  }

  getTaxiStatusText(ride: { taxiStatus: number, cancelledByDriver: number }) {
    if (ride.cancelledByDriver != CancelledByDriver.False)
      return this.translate.instant('taxiStatusInVain')

    if (ride.taxiStatus === TaxiStatus.Ordered)
      return this.translate.instant('taxiStatusOrdered');

    if (ride.taxiStatus === TaxiStatus.OnItsWay)
      return this.translate.instant('taxiStatusOnItsWay');

    if (ride.taxiStatus === TaxiStatus.TaximeterStarted)
      return this.translate.instant('taxiStatusTaximeterStarted');

    if (ride.taxiStatus === TaxiStatus.Cancelled)
      return this.translate.instant('taxiHasBeenCancelled');

    if (ride.taxiStatus === TaxiStatus.Completed)
      return this.translate.instant('taxiStatusCompleted');

    if (ride.taxiStatus === TaxiStatus.InVain)
      return this.translate.instant('taxiStatusInVain');

    if (ride.taxiStatus === TaxiStatus.NoShow)
      return this.translate.instant('taxiStatusNoShow');

    if (ride.taxiStatus === TaxiStatus.Arrived)
      return this.translate.instant('shortTaxiStatusArrived');

    if (ride.taxiStatus === TaxiStatus.Unknown)
      return this.translate.instant('shortTaxiStatusUnknown');

    return '';
  }

  async getRides(tripDate: Date, appendRides: boolean) {
    console.log(tripDate.toString());
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      date: this.controlDate.toISOString(),
      pagingNumber: this.pagingNumber
    }
    await this.presentLoading()
    this.service.getRides(body).subscribe(async (data) => {
      for (let i = 0; i < data.rides.length; i++) {
        for (let j = 0; j < data.rides.length; j++) {
          if (data.rides[i].id == data.rides[j].shareRideId) {
            this.arraymove(data.rides, j, i + 1)
            i++;
          }
        }
      }

      if (appendRides)
        this.trips = [...this.trips, ...data.rides];
      else
        this.trips = data.rides;

      this.hasMoreRides = data.hasMoreRides;

      console.log(data);
      await this.hideLoading()
    }, async (error) => {
      await this.hideLoading()
      const alert = await this.alertController.create({
        header: "Error Occurred",
        subHeader: this.translate.instant('500errorMsg'),
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    })
  }

  async getRideInfo(trip: { id: string, taxiStatus: TaxiStatus }) {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      id: trip.id
    }
    await this.presentLoading()
    this.service.getRideInfo(body).subscribe(async (data) => {
      await this.hideLoading()

      console.log(data);

      var textKey = '';
      if (data.taxiOrdered) {
        textKey = this.translate.instant('taxiStatusOrdered');
        trip.taxiStatus = TaxiStatus.Ordered;
      }
      else if (data.taximeterStarted) {
        textKey = this.translate.instant('taxiStatusTaximeterStarted');
        trip.taxiStatus = TaxiStatus.TaximeterStarted;
      }
      else if (data.taxiIsCancelled) {
        textKey = this.translate.instant('taxiStatusCancelled');
        trip.taxiStatus = TaxiStatus.Cancelled;
      }
      else if (data.taxiRideCompleted) {
        textKey = this.translate.instant('taxiStatusCompleted');
        trip.taxiStatus = TaxiStatus.Completed;
      }
      else if (data.taxiInVain) {
        textKey = this.translate.instant('taxiStatusInVain');
        trip.taxiStatus = TaxiStatus.InVain;
      }
      else if (data.taxiNoShow) {
        textKey = this.translate.instant('taxiStatusNoShow');
        trip.taxiStatus = TaxiStatus.NoShow;
      }
      else if (data.taxiOnItsWay) {
        if (data.taxiTooLateSoCallCompanyForFortherInfo)
          textKey = this.translate.instant('taxiStatusTaxiTooLateSoCallCompanyForFortherInfo').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
        else if (data.expectedTimeOfTaxiArrival)
          textKey = this.translate.instant('taxiStatusExpectedTimeOfTaxiArrival').replace('{minutes}', data.expectedTimeOfTaxiArrival);
        else
          textKey = this.translate.instant('taxiStatusOnItsWay');
      }
      else
        textKey = this.translate.instant('taxiStatusUnknown').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);

      const alert = await this.alertController.create({
        header: this.translate.instant('taxiStatusHeader'),
        subHeader: textKey,
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    }, async (error) => {
      await this.hideLoading()
      const alert = await this.alertController.create({
        header: "Error Occurred",
        subHeader: this.translate.instant('500errorMsg'),
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    })
  }

  async openReceipt(rideId: string) {
    window.open(location.origin + environment.showReceiptUrl.replace("{rideId}", rideId), "_blank");
  }

  async cancelRide(trip: { id: string, taxiStatus: number, paymentIntentId: string }) {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      id: trip.id,
      paymentIntentId: trip.paymentIntentId
    }
    await this.presentLoading()
    this.service.cancelRide(body).subscribe(async (data) => {
      await this.hideLoading()

      var textKey = this.translate.instant('backendStatusCodeTaxiCouldNotBeCancelled').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
      if (data.taxiIsCancelled) {
        textKey = this.translate.instant('taxiHasBeenCancelled');
        trip.taxiStatus = TaxiStatus.Cancelled;
      }

      const alert = await this.alertController.create({
        header: this.translate.instant('taxiStatusHeader'),
        subHeader: textKey,
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    }, async (error) => {
      await this.hideLoading()
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const alert = await this.alertController.create({
          header: "Error Occurred",
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();
      }
    })
  }

  async openComment(rideId: string, receptionComment: string) {
    const rideComment = this.dialog.open(ReceptionCommentComponent, {
      disableClose: true, data: {
        comment: receptionComment
      }
    });
    rideComment.afterClosed().subscribe((receptionComment) => {
      if (receptionComment) {
        this.presentLoading();
        this.service.updateRidesReceptionComment({ rideId: rideId, receptionComment: receptionComment }).subscribe(
          async () => {
            this.hideLoading();
            window.location.reload();
          },
          async () => {
            this.hideLoading();
            const alert = await this.alertController.create({
              header: "Error Occurred",
              subHeader: this.translate.instant('500errorMsg'),
              cssClass: 'alertmessage',
              buttons: [
                {
                  text: 'Okay',
                  handler: () => {
                  }
                }
              ],
              backdropDismiss: false
            });
            await alert.present();
          })
      }
    })
  }

  loadMore(): void {
    this.pagingNumber++;
    this.getRides(this.controlDate, true);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  arraymove(arr: any[], fromIndex: any, toIndex: any) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
  }

  formatOptions(ride: any) {
    let formatBoolean = (translationKey: string, value: boolean) => {
      if (value === true)
        return this.translate.instant(translationKey);

      return '';
    };

    let formatNumber = (translationKey: string, translationKeyOnlyOne: string, value: number) => {
      if (value === 1)
        return this.translate.instant(translationKeyOnlyOne).replace('{value}', value);

      if (value)
        return this.translate.instant(translationKey).replace('{value}', value);

      return '';
    };

    let formatAsString = (strings: string[]) => {
      var result = '';

      strings
        .filter(s => s)
        .forEach(s => {
          if (result === '')
            result = s;
          else
            result += `, ${s}`;
        });

      return result;
    };

    let containsAttribute = (translationKey: string, value: AttributesToTaxi, compared: AttributesToTaxi) => {
      if ((value & compared) === compared)
        return this.translate.instant(translationKey);

      return '';
    };

    return formatAsString([
      formatNumber('taxi-overview-number-of-cars', 'taxi-overview-number-of-cars-only-one', ride.numberOfCars),
      formatBoolean('taxi-overview-large-taxi', ride.largeTaxi),
      formatBoolean('taxi-overview-stationCar', ride.stationCar),
      containsAttribute('taxi-overview-animal', ride.attributesToTaxi, AttributesToTaxi.Animal),
      containsAttribute('taxi-overview-bike', ride.attributesToTaxi, AttributesToTaxi.Bike),
      containsAttribute('taxi-overview-electricCar', ride.attributesToTaxi, AttributesToTaxi.ElectricCar),
      containsAttribute('oneBoosterSeat', ride.attributesToTaxi, AttributesToTaxi.OneBoosterSeat),
      containsAttribute('twoBoosterSeats', ride.attributesToTaxi, AttributesToTaxi.TwoBoosterSeats),
      containsAttribute('childSeat', ride.attributesToTaxi, AttributesToTaxi.ChildSeat),
      containsAttribute('stationCar', ride.attributesToTaxi, AttributesToTaxi.EstateCar),
      containsAttribute('hybridCar', ride.attributesToTaxi, AttributesToTaxi.HybridCar),
    ]);
  }

  shouldShowLoadMoreButton(): boolean {
    // Show "Load More" if there are more entries in the current month and no entries in the previous month
    return this.hasMoreRides;
  }

  isAtBottom() {
    return (window.innerHeight + window.scrollY) >= (document.body.offsetHeight - 10); // Check with a small margin
  }

  payInTaxi(ride: any) {
    return (ride.paymentOption & PaymentOption.PayInTaxi) && PaymentOption.PayInTaxi;
  }

  hotelPays(ride: any) {
    return (ride.paymentOption & PaymentOption.HotelPays) && PaymentOption.HotelPays;
  }

  applyToBill(ride: any) {
    return (ride.paymentOption & PaymentOption.ApplyToBill) && PaymentOption.ApplyToBill;
  }

  prepay(ride: any) {
    return (ride.paymentOption & PaymentOption.Prepay) && PaymentOption.Prepay;
  }

  payByTerminalInReception(ride: any) {
    return (ride.paymentOption & PaymentOption.PayByTerminal) && ride.orderType === OrderType.Hotel;
  }

  payByTerminalOnTerminal(ride: any) {
    return (ride.paymentOption & PaymentOption.PayByTerminal) && ride.orderType === OrderType.Terminal;
  }

  payByTerminalOnMobile(ride: any) {
    return (ride.paymentOption & PaymentOption.PayByTerminal) && ride.orderType === OrderType.Mobile;
  }

  payByPaymentLink(ride: any) {
    return (ride.paymentOption & PaymentOption.PayByTerminal) && ride.orderType === OrderType.PaymentLink;
  }

  getTranslationKey(ride: any) {
    if (this.payInTaxi(ride))
      return 'paymentInTaxi';

    if (this.hotelPays(ride))
      return 'paymentHotelPays';

    if (this.applyToBill(ride))
      return 'paymentAtHotel';

    if (this.prepay(ride))
      return 'isPrepaid';

    if (this.payByTerminalInReception(ride))
      return 'isPaidByStripeReception';

    if (this.payByTerminalOnTerminal(ride))
      return 'isPaidByTerminal';

    if (this.payByTerminalOnMobile(ride))
      return 'isPaidByMobile';

    if (this.payByPaymentLink(ride))
      return 'isPaidByPaymentLink'

    return 'N/A (' + ride.paymentOption + ')';
  }

  getEtaTranslation(eta: number) {
    return this.translate.instant('eta').replace('{minutes}', eta);
  }

  getCO2PdfLink() {
    var azureCode = environment.getCO2Pdf.split('code=')[1];
    var year = this.controlDate.getFullYear();
    var month = this.controlDate.getMonth();
    month++;
    var url = `${environment.api}/GetCO2PDF/${this.hotelId}/${year}/${month}?code=${azureCode}`
    return url;
  }

  updateRide(ride: any): void {
    const dialogRef = this.dialog.open(UpdateRideDialogComponent, {
      width: '80%',
      data: ride,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result)
        window.location.reload();
    });
  }
}
