<div>
    <h2 class="font_2" style="margin-left:40px; font-size:28px;">Taxi Privacy Policy</h2>

    <h1 class="font_0" style="margin-left:40px; font-size:22px;">CommuteApp</h1>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span class="wixGuard">​</span></p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">1.Information on the processing of personal data&nbsp;</span></p>

    <p class="font_8" style="font-size:18px;">Your data security is important to us, and we therefore make it a high priority that your data is handled in a responsible manner. Below, you can read how CommuteApp ApS (hereafter "CommuteApp", "we", "us" or "our") process your personal data when we act as a data controller. You can also read about your rights in relation to our processing of your data.&nbsp;</p>

    <p class="font_8" style="font-size:18px;"><span style="font-weight:bold;"><span class="wixGuard">​</span></span></p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">2. Commuteapp’s Role as the data controller&nbsp;</span></p>

    <p class="font_8" style="font-size:18px;">In connection with our company’s operation, we process an amount of personal data. We do so in order for us to service you in the best possible way. We collect and process mostly general (non-sensitive) data.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">If you have any questions about our processing of your personal data, please contact CommuteApp here:&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">CommuteApp ApS&nbsp;</p>

    <p class="font_8" style="font-size:18px;">Hobro Landvej 129&nbsp;</p>

    <p class="font_8" style="font-size:18px;">8830 Tjele&nbsp;</p>

    <p class="font_8" style="font-size:18px;">CVR-no. 40118233&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">+45 40 500 720&nbsp;</p>

    <p class="font_8" style="font-size:18px;"><a data-auto-recognition="true" href="mailto:support@commuteapp.dk">support@commuteapp.dk</a>&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">4. What personal data we collect and for what purpose&nbsp;</span></p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">4.1. Ordering a taxi via CommuteApp&nbsp;</span></p>

    <p class="font_8" style="font-size:18px;">CommuteApp process personal information about you when a hotel is ordering a taxi to you via our app. We only process non-sensitive personal information about you, including your name, e-mail, telephone number and location information related to the route.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">We process your personal information in order to make the services available, including administration of taxi orders, invoicing, statistics and quality management as well as providing overall service and sales.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">Our basis for processing is our legitimate interests in being able to book a taxi for you (Article 6(1)(f) of the General Data Protection Regulation).&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">CommuteApp deletes or anonymizes the information when it is no longer necessary for the purpose. Personal data relevant for bookkeeping is stored in 5 years in accordance with the Danish Bookkeeping Act (in Danish: Bogføringsloven).&nbsp;</p>

    <p class="font_8" style="font-size:18px;">Information about location is stored in [insert storage period], after which the information is deleted or anonymized after.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">However, the information can be stored for a longer period if we have a legitimate need for longer storage, e.g., if the processing is necessary for the establishment, exercise, or defence of a legal claim. &nbsp;&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">5. Information we collect from other sources</span>&nbsp;</p>

    <p class="font_8" style="font-size:18px;">We collect your personal data from others, including the hotel who orders the taxi on your behalf via CommuteApp. &nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">6. Sharing yor data with others&nbsp;</span></p>

    <p class="font_8" style="font-size:18px;">CommuteApp may disclose your personal data internally within the group, to suppliers and/or service providers in connection with the general operation of our business. This also includes disclosing personal data to the taxa company in order to provide the services.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">CommuteApp may also disclose your personal data to a public authority in situations where we are specifically bound to disclose your personal data in accordance with the laws and notification obligations to which we are subject.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">We try to limit the disclosure of personal data in personally identifiable form and thus the disclosure of information that can be attributed to you personally.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">CommuteApp also entrusts your personal data to data processors, e.g. in connection with the administration of our IT systems and in connection with our marketing. Our data processors process your personal data solely for our purposes and on our instructions.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">7. Transfer of personal data to countries outside the eu/eea</span>&nbsp;</p>

    <p class="font_8" style="font-size:18px;">In connection with our processing of your personal data, we may transfer the information to countries outside the EU/EEA. Data protection legislation in these countries may be less restrictive than it is in Denmark and the rest of the EU/EEA. In some countries, however, the European Commission has determined that the data protection level is in line with the level of protection in the EU/EEA. &nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">If we transfer personal data to countries where this is not the case, the transfer of your personal data to these countries outside of the EU/EEA will be based on the Standard Contractual Clauses (SCC) drawn up by the European Commission or another similar transfer basis specifically designed to ensure an adequate level of protection.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">You can read more about the transfer of personal data to countries outside the EU/EEA on the European Commission's website.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">For further information about our transfer of personal data to countries outside EU/EEA, please contact <a data-auto-recognition="true" href="mailto:support@commuteapp.dk">support@commuteapp.dk</a>.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">8. Storage period, data integrity and security&nbsp;</span></p>

    <p class="font_8" style="font-size:18px;">Your personal data is stored no longer than is necessary to meet the purposes for which it has been collected. When your data is no longer necessary, we will ensure that it is deleted in a safe manner.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">It is our policy to protect personal data by taking adequate technical and organisational security measures.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">We have implemented security measures that will ensure data protection for all the personal data we process. We regularly conduct internal follow-ups in relation to the adequacy of and compliance with policies and measures.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="margin-left:40px; font-size:18px;"><span style="font-weight:bold;">9. Your rights</span>&nbsp;</p>

    <p class="font_8" style="font-size:18px;">As a data subject, you have a number of rights under the General Data Protection Regulation. Please contact us if you want to exercise your rights.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">You may withdraw any consent unconditionally and at any time. This can be done by sending an e-mail to us (see the e-mail address above). Withdrawal of your consent will not have any negative impact. However, it may mean that we cannot honour specific requests from you in the future. Withdrawal of your consent will not affect the lawfulness of processing on the basis of your consent before it is withdrawn. Furthermore, it will not affect any processing performed on another legal basis.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">In addition, you may – unreservedly and at any time – raise objections to our processing when it is based on our legitimate interest.&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <p class="font_8" style="font-size:18px;">Your rights also include the following:&nbsp;</p>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to access: You have the right to gain access to the personal data we process about you.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to rectification: You have the right to have incorrect personal data about yourself corrected and incomplete personal data completed.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to erasure (right to be forgotten): Under certain circumstances, you have the right to have your personal data erased prior to the time when we normally delete it.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to restriction of processing: Under certain circumstances, you have the right to have the processing of your personal data restricted. If you are entitled to restricted processing, we will from that time on only process the data – with the exception of storage – with your consent or for the establishment, exercise or defence of legal claims, for the protection of the rights of another natural or legal person or for reasons of important public interest.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to objection: You have the right to object to our processing of your personal data under certain circumstances – and always if the processing is for direct marketing purposes.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to data portability: Under certain circumstances, you have the right to receive your personal data in a structured, commonly used and machine-readable format and to transmit this personal data from one data controller to another.&nbsp;</p>
        </li>
    </ul>

    <p class="font_8" style="font-size:18px;">&nbsp;</p>

    <ul class="font_8" style="font-size:18px;">
        <li>
            <p class="font_8" style="font-size:18px;">Right to lodge a complaint: You can lodge a complaint with the Danish Data Protection Agency at any time regarding our processing of personal data. For more information, see <span style="text-decoration:underline;"><a href="http://www.datatilsynet.dk" target="_blank" rel="noreferrer noopener">www.datatilsynet.dk</a></span> where you can also find further information about your rights as a data subject.&nbsp;</p>
        </li>
    </ul></div>
<mat-dialog-actions>
    <button class="ok-button" mat-dialog-close>{{ "selfServiceStation_gdprPopupButton" | translate }}</button>
</mat-dialog-actions>
