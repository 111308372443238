<form [formGroup]="form"
      (submit)="submit()"
      class="container">
    <div class="forms search">
        <h3>{{ 'fixedDestination-destinationTitle' | translate }}</h3>
        <div class="form-group"
             *ngIf="!editMode">
            <mat-checkbox (change)="handleAirportDestinationChange($event)"
                          color="primary">{{ 'fixedDestination-airportDestination' | translate }}</mat-checkbox>

            <app-airport-chooser (airportSelected)="airportSelected($event)"
                                 *ngIf="locationToAddressIsAirport?.value"></app-airport-chooser>
        </div>
        <div class="form-group"
             *ngIf="!editMode && !locationToAddressIsAirport?.value">
            <mat-icon class="map">location_on</mat-icon>
            <input appGooglePlace
                   #destinationDropdown
                   (onAddressChange)="handleAddressChange($event)"
                   type="text"
                   placeholder="{{ 'fixedDestination-chooseDestination' | translate }}"
                   class="form-control"
                   formControlName="destination"
                   [options]="destinationOptions">
            <mat-icon class="srch">search</mat-icon>
            <div *ngIf="formSubmit && !destination?.value && !locationToAddressIsAirport?.value"
                 class="alert alert-danger">
                {{ 'fixedDestination-destinationRequired' | translate }}
            </div>
        </div>
        <div class="form-group"
             *ngIf="editMode">
            <mat-icon class="map">location_on</mat-icon>
            <input type="text"
                   class="form-control"
                   formControlName="destination"
                   [attr.disabled]="true">
            <mat-icon class="srch">search</mat-icon>
        </div>

        <div class="taxiInfo">
            <h2>{{'fixedDestination-large' | translate}}</h2>
            <mat-checkbox (change)="handleLargeTaxiChange($event)"
                          formControlName="isLargeTaxi"
                          color="primary"
                          [disabled]="editMode">{{'yes' | translate}}</mat-checkbox>
        </div>

        <div class="taxiInfo">
            <h2>{{'fixedDestination-shared' | translate}}</h2>
            <mat-checkbox (change)="handleSharedTaxiChange($event)"
                          formControlName="isSharedTaxi"
                          color="primary"
                          [disabled]="editMode">{{'yes' | translate}}</mat-checkbox>
        </div>

        <div class="form-group">
            <mat-icon class="comment">comment</mat-icon>
            <input type="text"
                    (ngModelChange)="handlePrefixedCommentToTaxiDriverChange($event)"
                    class="form-control"
                    formControlName="prefixedCommentToTaxiDriver"
                    placeholder="{{'fixedDestinationEditComponent_defaultCommentToDriver' | translate}}">
        </div>

        <div class="form-group ">
            <h2>{{'fixedDestination-paymentOptionsAllowed' | translate}}</h2>
            <mat-radio-group class="paymentOptionsAllowed" (change)="paymentOptionsAllowedChanged($event)">
                <mat-radio-button value=1> Pay In Taxi </mat-radio-button>
                <mat-radio-button value=2> Prepay </mat-radio-button>
                <mat-radio-button value=3> Both </mat-radio-button>
            </mat-radio-group>

        </div>

    </div>

    <div formArrayName="prices"
         class="forms">
        <div *ngFor="let price of prices.controls; let i = index">
            <h3>{{ (i === 0 ? 'fixedDestination-fromHotel' : 'fixedDestination-toHotel') | translate }}</h3>
            <app-fixed-destination-edit-price [form]="getPricesFormGroupAt(i)"
                                              [formSubmit]="formSubmit"></app-fixed-destination-edit-price>
        </div>
    </div>

    <div class="action">
        <button mat-flat-button
                color="primary"
                type="submit">{{ 'save' | translate }} </button>
        <button mat-flat-button
                color="warn"
                type="button"
                (click)="delete()"
                *ngIf="editMode">{{ 'delete' | translate }} </button>
    </div>

</form>