<div class="taxiHotel" *ngIf="show">
    <span class="title"><b>{{'cancelledApplyToBillTitle' | translate}}</b></span>
    <div class="container">
        <div class="item" *ngFor="let ride of rides">
            <div>
                {{'name' | translate}}: {{ride.guestName}}
                {{'roomNumber' | translate}}: {{ride.roomNumber}}
            </div>
            <div class="buttonBox">
                <button class="button" (click)="removeRideButtonClick(ride)">{{'confirmed' | translate}}</button>
            </div>
        </div>
    </div>
</div>