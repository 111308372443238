import { Injectable } from '@angular/core';
import { TaxiService } from './taxi.service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GeolocationService {

  constructor(private taxiService: TaxiService) { }

  getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject('geolocationNotSupported');
        return;
      }

      // Try to get the position with high accuracy
      navigator.geolocation.getCurrentPosition(resolve, (error) => {
        // If high accuracy fails, try again with low accuracy
        navigator.geolocation.getCurrentPosition(resolve, (error) => {
          reject(error);
        }, { maximumAge: 600000, timeout: 5000, enableHighAccuracy: false });
      }, { maximumAge: 600000, timeout: 5000, enableHighAccuracy: true });
    });
  }

  public getClosetsAddress(latitude: number, longitude: number): Observable<any> {
    return this.taxiService.post(environment.getAddressByCords, { latitude, longitude });
  }
}