<ion-content>
    <app-header></app-header>
    <div class="center" *ngIf="monthlyRides.length == 0">
        <p>CO2 reporting coming soon.</p>
    </div>
    <div class="content" *ngIf="monthlyRides.length != 0">
        <div class="months" *ngFor="let months of monthlyRides">
            <div class="monthsBox">
                <div class="monthsYear">
                    {{months.year}}
                </div>
                <div class="monthsMonth">
                    {{getMonthName(months.month)}}
                </div>
            </div>
            <div class="downloadBox">
                <a class="co2PdfDownload" download="" href="{{getCO2PdfLink(months)}}">Download</a>
            </div>
            <hr>
        </div>
    </div>
</ion-content>
