<div class="container">
    <div class="average-time-circle" *ngIf="rating">
        {{ rating }}
    </div> 
    <div class="average-time-circle" *ngIf="!rating">
        {{ "noRatingFound" | translate }}
    </div> 
    <div class="bendingText">
        <span>R</span>
        <span>A</span>
        <span>T</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
        </div>
</div>