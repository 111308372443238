import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-pause-screen',
  templateUrl: './pause-screen.component.html',
  styleUrls: ['./pause-screen.component.scss']
})
export class PauseScreenComponent {
  isVisible = false;
  taxiLogo = 'assets/images/taxi-ordering.png';

  @Input() logoSrc?: string;
  @Input() language?: string;
  @Output() onResume = new EventEmitter<void>();

  show(): void {
    this.isVisible = true;
  }

  hide(): void {
    this.isVisible = false;
  }

  resume(): void {
    this.hide();
    this.onResume.emit();
  }
}
