<form [formGroup]="sendLink">
    <app-phone-number-form-group formControlName="phoneNo" [formSubmitted]="formSubmitted"></app-phone-number-form-group>

    <button mat-flat-button color="primary" (click)="sendSms()">
        <ng-container *ngIf="!isSendingSms; then sendSmsText; else spinner"> </ng-container>
        <ng-template #sendSmsText>
            {{ "sendPaymentLinkToCustomer_sendBtn" | translate }}
        </ng-template>
        <ng-template #spinner>
            <ion-spinner></ion-spinner>
        </ng-template>
    </button>
</form>
