import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-receipt',
    templateUrl: './receipt.component.html',
    styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {

    private rideId: string = "";
    rideInfo: any;
    subscr$ = new Subject();

    constructor(private getTaxiService: GetTaxiService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.route.queryParams.subscribe(async (params) => {
            this.rideId = params['rideId'];
        })
        this.getTaxiService.getModelPDF(this.rideId).pipe(takeUntil(this.subscr$))
            .subscribe(data => {
                this.rideInfo = data;
            });
    }

    ngOnDestroy(): void {
        this.subscr$.next();
        this.subscr$.complete();
    }

    getUrl(): string {
        return `${environment.api}/receiptpdf/${this.rideId}?code=${this.getCodeAzure()}`;
    }

    getCodeAzure(): string {
        return environment.getReciptPDF.split('code=')[1];
    }
}
