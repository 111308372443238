import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, OnDestroy {

  @Input() logoSrc?: string;
  @Input() language?: string;
  @Input() name?: string;

  loginRequired = true;
  isSelfServiceStation = true;
  constructor(private location: Location) { }

  ngOnInit(): void {
    this.loginRequired = !this.includesAny(this.location.path(), [
      'taxi-overview',
      'taxi-to-hotel',
      'info-airport',
      'info-shared-ride',
      'cancel-taxi',
      'self-service-station']);

    this.isSelfServiceStation = this.includesAny(this.location.path(), [
      'self-service-station']);
  }

  includesAny(text: string, searchFor: string[]) {
    for (var i = 0; i < searchFor.length; i++)
      if (text.includes(searchFor[i]))
        return true;

    return false;
  }

  ngOnDestroy(): void {
  }

  showName(): boolean {
    return this.name !== undefined
  }
}
