import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  logEndpoint = environment.api + environment.log;

  constructor(public http: HttpClient, public location: Location) { }

  private log(level: string, message: string, additionalData?: string) {
    var currentUrl = this.location.path();
    var additionalDataSerialized = JSON.stringify(additionalData);

    var body = {
      currentUrl,
      level,
      message,
      data: additionalDataSerialized,
      version: VersionService.currentVersion
    };

    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json; charset=utf-8')

    this.http.post(this.logEndpoint, body, { headers: headers }).subscribe(r => { }, e => { });
  }

  information(message: string, additionalData?: string): void {
    this.log('information', message, additionalData);
  }

  error(message: string, additionalData?: any): void {
    this.log('error', message, additionalData);
  }

}
