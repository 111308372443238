import { TaxiToHotelComponent } from './taxi-to-hotel/taxi-to-hotel.component';
import { OrderTaxiComponent } from './order-taxi/order-taxi.component';
import { SplashComponent } from './splash/splash.component';
import { HeaderComponent } from './header/header.component';
import { MaterialModule } from './../modules/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogoComponent } from './logo/logo.component';
import { BookTaxiComponent } from './book-taxi/book-taxi.component';
import { AirportComponent } from './airport/airport.component';
import { SecondDestinationComponent } from './second-destination/second-destination.component';
import { HomeComponent } from './home/home.component';
import { ControlTripsComponent } from './control-trips/control-trips.component';
import { BillHistoryComponent } from './bill-history/bill-history.component';
import { BillComponent } from './bill/bill.component';
import { RouterModule } from '@angular/router';
import { FromAirportComponent } from './from-airport/from-airport.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { IonicModule } from "@ionic/angular";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SearchFilterPipe } from '../pipes/search-filter.pipe';
import { InfoAirportComponent } from './info-airport/info-airport.component';
import { InfoSharedRideComponent } from './info-shared-ride/info-shared-ride.component';
import { CancelTaxiComponent } from './cancel-taxi/cancel-taxi.component';
import { ReplacePipe } from '../pipes/replace.pipe';
import { SupportComponent } from './support/support.component';
import { PriceComponent } from './price/price.component';
import { PriceAsTextComponent } from './price-as-text/price-as-text.component';
import { PriceOfLargeTaxiComponent } from './price-of-large-taxi/price-of-large-taxi.component';
import { FixedDestinationEditComponent } from './setup/fixed-destination-edit/fixed-destination-edit.component';
import { MainComponent } from './setup/main/main.component';
import { FixedDestinationEditPriceComponent } from './setup/fixed-destination-edit-price/fixed-destination-edit-price.component';
import { FixedDestinationListComponent } from './setup/fixed-destination-list/fixed-destination-list.component';
import { MatTableModule } from '@angular/material/table';
import { AdminLoginComponent } from './setup/admin-login/admin-login.component';
import { AdminHotelUrlDashboard } from './setup/admin-hotel-url-dashboard/admin-hotel-url-dashboard.component';
import { AirportChooserComponent } from './airport-chooser/airport-chooser.component';
import { HotelPaymentComponent } from './hotel-payment/hotel-payment.component';
import { UpcomingRidesComponent } from './upcoming-rides/upcoming-rides.component';
import { PaymentsAlertComponent } from './payments-alert/payments-alert.component';
import { ReceptionistInitialsComponent } from './receptionist-initials/receptionist-initials.component';
import { TaxiOverviewComponent } from './taxi-overview/taxi-overview.component';
import { TaxiContactComponent } from './taxi-contact/taxi-contact.component';
import { ArrivalsOfTheDayComponent } from './arrivals-of-the-day/arrivals-of-the-day.component';
import { TaxiArrivalsComponent } from './taxi-arrivals/taxi-arrivals.component';
import { SimpleDatePickerComponent } from './simple-date-picker/simple-date-picker.component';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { LanguageChooserComponent } from './language-chooser/language-chooser.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { OrderButtonComponent } from './order-button/order-button.component';
import { PaymentOptionComponent } from './payment-option/payment-option.component';
import { GooglePlaceDirective } from '../directives/google-place/google-place.directive';
import { DestinationSelectorComponent } from './destination-selector/destination-selector.component';
import { TaxiStatusMessageComponent } from './taxi-status-message/taxi-status-message.component';
import { MatExpansionModule } from "@angular/material/expansion";
import { CoinCounterContainerComponent } from './coin-tree-counter/coin-tree-counter.container';
import { CoinTreeCounterComponent } from './coin-tree-counter/coin-tree-counter.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { RushHourWarningComponent } from './rush-hour-warning/rush-hour-warning.component';
import { SelfServiceStationComponent } from './self-service-station/self-service-station.component';
import { MobileComponent } from './mobile/mobile.component';
import { PhoneNumberInputComponent } from '../shared/components/phone-number-input/phone-number-input.component';
import { PhoneNumberOnlyDirective } from '../shared/components/phone-number-input/phone-number-input.directive';
import { StationHeaderComponent } from '../shared/components/station-header/station-header.component';
import { TimeoutPopupComponent } from '../shared/components/timeout-popup/timeout-popup.component';
import { LargeTaxiPopupComponent } from './large-taxi-popup/large-taxi-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PaidPopupComponent } from '../shared/components/paid-popup/paid-popup.component';
import { PrepaidPopupComponent } from '../shared/components/pre-paid-popup/pre-paid-popup.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapComponent } from './google-map/google-map.component';
import { ErrorOrderPopupComponent } from '../shared/components/error-order-popup/error-order-popup.component';
import { CollectPaymentPopupComponent } from '../shared/components/collect-payment-popup/collect-payment-popup.component';
import { GdprPopupComponent } from '../shared/components/gdpr-popup/gdpr-popup.component';
import { TrackingOrderMapComponent } from './tracking-order-map/tracking-order-map.component';
import { InitPopupComponent } from '../shared/components/init-popup/init-popup.component';
import { ReceiptComponent } from './receipt/receipt.component';
import { InputHotelJsonDialogComponent } from './setup/input-hotel-json-dialog/input-hotel-json-dialog.component';
import { NewUpdatePopupComponent } from '../shared/components/new-update-popup/new-update-popup.component';
import { PayInTaxiPopupComponent } from '../shared/components/pay-in-taxi-popup/pay-in-taxi-popup.component';
import { AreYouSurePopupComponent } from '../shared/components/are-you-sure-popup/are-you-sure-popup.component';
import { SendLinkPopupComponent } from '../shared/components/send-link-popup/send-link-popup.component';
import { PhoneNumberFormGroupComponent } from './phone-number-form-group/phone-number-form-group.component';
import { NewPayInTaxiPopupComponent } from '../shared/components/new-pay-in-taxi-popup/new-pay-in-taxi-popup.component';
import { SpecialHotelInformationPopup } from '../shared/components/special-hotel-info-popup/special-hotel-info-popup.component';
import { PayComponent } from './pay/pay.component';
import { DuplicatePhonenumberComponent } from '../shared/components/duplicate-phonenumber-popup/duplicate-phonenumber.component';
import { RoomNumberIncorrect } from '../shared/components/room-number-incorrect-popup/room-number-incorrect-popup.component';
import { ApplyToBillBoxComponent } from '../shared/components/apply-to-bill-box/apply-to-bill-box.component';
import { CancelledRidesApplytobillRemoveComponent } from './cancelled-rides-applytobill-remove/cancelled-rides-applytobill-remove.component';
import { CheckIfCorrectGuestComponent } from '../shared/components/check-if-correct-guest/check-if-correct-guest.component';
import { NewsPopupComponent } from '../shared/components/news-popup/news-popup.component';
import { ChooseWhichCommentComponent } from '../shared/components/choose-which-comment/choose-which-comment.component';
import { CancelledByDriverComponent } from '../shared/components/cancelled-by-driver/cancelled-by-driver.component';
import { OrderedPopupComponent } from '../shared/components/ordered-popup/ordered-popup.component';
import { AvergeTimeComponent } from './averge-time/average-time.component';
import { RatingComponent } from './rating/rating.component';
import { Co2shopComponent } from './co2shop/co2shop.component';
import { Co2reportComponent } from './co2report/co2report.component';
import { ConfirmCo2Component } from '../shared/components/confirm-co2/confirm-co2.component';
import { Co2ContactInformationComponent } from '../shared/components/co2-contact-information/co2-contact-information.component';
import { VoucherComponent } from './voucher/voucher.component';
import { ReceptionCommentComponent } from '../shared/components/reception-comment/reception-comment.component';
import { InitPopupMobileComponent } from '../shared/components/init-popup-mobile/init-popup-mobile.component';
import { PaidPopupEmailComponent } from '../shared/components/paid-popup-email/paid-popup-email.component';
import { RestaurantComponent } from './restaurant/restaurant.component';
import { InitPopupMobileRestaurantComponent } from '../shared/components/init-popup-mobile-restaurant/init-popup-mobile-restaurant.component';
import { MobileRestaurantComponent } from './mobile-restaurant/mobile-restaurant.component';
import { PhoneNumberInputPcComponent } from '../shared/components/phone-number-input-pc/phone-number-input-pc.component'
import { InitPopupMobileStrandardComponent } from '../shared/components/init-popup-mobile-standard/init-popup-mobile-strandard.component';
import { MobileStandardComponent } from './mobile-standard/mobile-standard.component';
import { UpdateHotelSettingsDialogComponent } from './setup/update-hotel-settings-dialog/update-hotel-settings-dialog.component';
import { FastOrderComponent } from './fast-order/fast-order.component';
import { UpdateRideDialogComponent } from './update-ride-dialog/update-ride-dialog.component';
import { MobileStatusComponent } from './mobile-status/mobile-status.component';
import { TaxiAssistantComponent } from './taxi-assistant/taxi-assistant.component';
import { NewHeaderComponent } from '../shared/components/new-header/new-header.component';
import { NewLanguageChooserComponent } from './new-language-chooser copy/new-language-chooser.component';
import { NewDateTimePickerComponent } from '../shared/components/new-date-time-picker/new-date-time-picker.component';
import { PauseScreenComponent } from '../shared/components/pause-screen/pause-screen.component';
import { BottomBannerComponent } from '../shared/components/bottom-banner/bottom-banner.component';


export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, undefined, '.json?unique=' + new Date().getTime());
}

const components = [
  LogoComponent,
  HeaderComponent,
  SplashComponent,
  OrderTaxiComponent,
  TaxiToHotelComponent,
  BookTaxiComponent,
  AirportComponent,
  FromAirportComponent,
  SecondDestinationComponent,
  HomeComponent,
  ControlTripsComponent,
  BillHistoryComponent,
  BillComponent,
  InfoAirportComponent,
  OrderButtonComponent,
  InfoSharedRideComponent,
  CancelTaxiComponent,
  SupportComponent,
  ReceiptComponent,
  TaxiContactComponent,
  PriceComponent,
  PriceAsTextComponent,
  PriceOfLargeTaxiComponent,
  PageNotFoundComponent,
  SearchFilterPipe,
  ReplacePipe,
  FixedDestinationEditComponent,
  MainComponent,
  FixedDestinationEditPriceComponent,
  FixedDestinationListComponent,
  AdminLoginComponent,
  AdminHotelUrlDashboard,
  AirportChooserComponent,
  HotelPaymentComponent,
  UpcomingRidesComponent,
  PaymentsAlertComponent,
  TaxiOverviewComponent,
  TaxiArrivalsComponent,
  DateTimePickerComponent,
  SimpleDatePickerComponent,
  ReceptionistInitialsComponent,
  LanguageChooserComponent,
  ArrivalsOfTheDayComponent,
  TopMenuComponent,
  PaymentOptionComponent,
  GooglePlaceDirective,
  DestinationSelectorComponent,
  TaxiStatusMessageComponent,
  CoinCounterContainerComponent,
  CoinTreeCounterComponent,
  RushHourWarningComponent,
  SelfServiceStationComponent,
  MobileComponent,
  PhoneNumberInputComponent,
  GoogleMapComponent,
  StationHeaderComponent,
  TimeoutPopupComponent,
  NewUpdatePopupComponent,
  PayInTaxiPopupComponent,
  AreYouSurePopupComponent,
  PaidPopupComponent,
  PrepaidPopupComponent,
  ErrorOrderPopupComponent,
  TrackingOrderMapComponent,
  LargeTaxiPopupComponent,
  InputHotelJsonDialogComponent,
  SendLinkPopupComponent,
  NewPayInTaxiPopupComponent,
  DuplicatePhonenumberComponent,
  RoomNumberIncorrect,
  ApplyToBillBoxComponent,
  CancelledRidesApplytobillRemoveComponent,
  CheckIfCorrectGuestComponent,
  NewsPopupComponent,
  SpecialHotelInformationPopup,
  ChooseWhichCommentComponent,
  CancelledByDriverComponent,
  OrderedPopupComponent,
  AvergeTimeComponent,
  RatingComponent,
  Co2shopComponent,
  Co2reportComponent,
  ConfirmCo2Component,
  Co2ContactInformationComponent,
  VoucherComponent,
  ReceptionCommentComponent,
  InitPopupMobileComponent,
  PhoneNumberOnlyDirective,
  CollectPaymentPopupComponent,
  GdprPopupComponent,
  InitPopupComponent,
  PhoneNumberFormGroupComponent,
  PayComponent,
  PaidPopupEmailComponent,
  RestaurantComponent,
  InitPopupMobileRestaurantComponent,
  MobileRestaurantComponent,
  PhoneNumberInputPcComponent,
  InitPopupMobileStrandardComponent,
  MobileStandardComponent,
  UpdateHotelSettingsDialogComponent,
  FastOrderComponent,
  UpdateRideDialogComponent,
  MobileStatusComponent,
  TaxiAssistantComponent,
  NewHeaderComponent,
  NewLanguageChooserComponent,
  NewDateTimePickerComponent,
  PauseScreenComponent,
  BottomBannerComponent
];

@NgModule({
  declarations: [
    components
  ],
  exports: components,
  imports: [
    CommonModule,
    MaterialModule,
    MatTableModule,
    RouterModule,
    ReactiveFormsModule, FormsModule,
    IonicModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient] }
    }), MatExpansionModule,
    NgCircleProgressModule.forRoot(), MatDialogModule, GoogleMapsModule
  ]
})
export class ComponentsModule { }
