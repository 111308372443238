import {ICoordinate} from '../../models/coordinate.interface';

export class ReceptionMapperService {
    public static mapTaxiMapFormToICoordinate(data: any): ICoordinate {
        return {
            lng: data.taxiCoordinate.longitude,
            lat: data.taxiCoordinate.latitude,
            minutesAway: data?.minutesAway,
            additionalGuestInfo: data?.additionalGuestInfo
        };
    }
}
