import { Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-hotel-payment',
  templateUrl: './hotel-payment.component.html',
  styleUrls: ['./hotel-payment.component.scss']
})
export class HotelPaymentComponent implements OnInit, OnDestroy {
  @Output() hotelPayment = new EventEmitter<boolean>();

  @HostBinding('hidden') hotelPaymentNotAllowed: boolean = false;

  allRidesMustBePaidInTheTaxiSubscription: Subscription;

  constructor(hotelService: HotelService) {
    this.hotelPaymentNotAllowed = hotelService.allRidesShouldBePaidByTheCustomerValue;
    this.allRidesMustBePaidInTheTaxiSubscription = hotelService.allRidesShouldBePaidByTheCustomer.subscribe(v => this.hotelPaymentNotAllowed = v);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.allRidesMustBePaidInTheTaxiSubscription.unsubscribe();
  }

  paymentChange($event: any) {
    this.hotelPayment.emit($event.checked);
  }

}
