<div class="container">
    <div class="coin-counter-container" *ngIf="displayCoinCounter">
        <div class="coin-counter">
            <div class="counter">{{ getCoinProgressBarCount() }}%</div>
            <div class="counter-description">{{ "coin-counter-progress-bar" | translate }}</div>
            <div class="counter">{{ getGiftCount() }}</div>
            <div class="counter-description">{{ "coin-counter-gift" | translate }}</div>
        </div>
        <circle-progress [percent]="getCoinCount()" [radius]="50" [outerStrokeWidth]="20" [innerStrokeWidth]="20" [space]="-20" [outerStrokeColor]="'#7ed957'" [innerStrokeColor]="'#545454'" [showZeroOuterStroke]="false" [animation]="true" [animationDuration]="300" [imageSrc]="'assets/images/co2-coin.png'" [showImage]="true" [imageHeight]="80" [imageWidth]="80"></circle-progress>
    </div>

    <div class="basic-counter-container" *ngIf="displayBasicCounter">
        <div class="basic-counter-circle">{{ getEarnedCoins() }} {{ currency }}</div>
        <div class="bendingText">
            <span>B</span>
            <span>O</span>
            <span>N</span>
            <span>U</span>
            <span>S</span>
        </div>
    </div>

    <div class="tree-counter-container" *ngIf="displayTreeCounter">
        <div class="tree-counter">
            <div class="counter">{{ getTreeCount() }}</div>
            <div class="counter-description">{{ "tree-counter" | translate }}</div>
        </div>
        <div class="tree-progress">
            <img src="assets/images/tree.png" class="tree-img" alt="" />
            <mat-progress-bar mode="determinate" [value]="getTreeProgressBarCount()"></mat-progress-bar>
        </div>
    </div>

    <div class="coin-container" *ngIf="displayBonus">
        <div class="circle-container">
            <span class="circle">{{ getEarnedCoins() }} EUR.</span>
            <span class="title">{{ "total" | translate }}</span>
        </div>
    </div>

    <div class="progress-bar-container"  *ngIf="displayProgressBar">
        <div class="title-container">
            <ion-label [ngClass]="{'text-white': isWhiteTextColor}" class="bold">{{ monthlyBonusGoalTitle }}</ion-label>
        </div>
        <div class="progress-bar-container">
            <div class="header">
                <span [ngClass]="{'text-white': isWhiteTextColor}" class="left-text">{{ getEarnedCoinsMonthly() }}</span>
                <span [ngClass]="{'text-white': isWhiteTextColor}" class="right-text">{{ monthlyRidesGoal }}</span>
            </div>
            <mat-progress-bar [mode]="'determinate'" [value]="getProgressPercentage()" class="progress-bar"></mat-progress-bar>
        </div>
    </div>

    <div class="christmas-div" *ngIf="showChristmasImage">
        <img class="christmas-image" src="assets/images/christmas.gif">
        <p class="christmas-text">Merry Christmas from HotelRide!</p>
    </div>
</div>
