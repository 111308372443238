<ion-content>
    <app-header></app-header>

    <div class="content"
         *ngIf="statusLoaded">
        <h1 *ngIf="isCancelled"> {{ 'taxiStatusCancelled' | translate }} </h1>
        <p *ngIf="errorOnCancel">{{ taxiCouldnotBeCancelledByGuest }}</p>
        <div *ngIf="!isCancelled && !errorOnCancel">
            <h1>
                {{'cancelTaxiInfo' | translate}}
            </h1>
            <button mat-flat-button
                    (click)="cancelRide()">
                {{'cancelTaxi' | translate}}
            </button>
        </div>
    </div>

</ion-content>s