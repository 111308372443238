<ion-content>
    <app-header [language]="lang"></app-header>
    <div class="center" *ngIf="notReleasedYet">
        <p>CO<sub>2</sub> shop coming soon.</p>
    </div>
    <div class="content" *ngIf="showConfirmed">    
        <div class="confirmed">
            {{'thanksForYourOrder' | translate}}
        </div>
    </div>
    <div class="content" *ngIf="!showConfirmed && !notReleasedYet">
        <div class="products" *ngFor="let product of products">
            <div class="imageContainer">
                <img src=".\assets\images\{{product.imageSrc}}" class="productImage">
            </div>
            <div class="productTitle">
                {{product.name}}
            </div>
            <div class="productTitle">
               {{ "coinCost" | translate }} {{product.coinCost}} {{ "CO2Coins" | translate }}
            </div>
            <div *ngIf="product.inStock != 99" class="productTitle">
                {{ "inStock" | translate }} {{product.inStock}}
             </div>
            <div class="counterBox">
                <button mat-stroked-button class="amountBtn" (click)="amountDecrease(product.productId)">-</button>
                <input class="inputAmount" type="number" id="product{{product.productId}}" min="0" [defaultValue]="0" (change)="onChangeAmount($event, product.productId)">
                <button mat-stroked-button class="amountBtn" (click)="amountIncrease(product.productId)">+</button>
            </div>  
        </div>
        <div class="cost">
            <div *ngIf="noProductsSelected" class="error"> {{ "noProductsSelected" | translate }} </div>
            <div *ngIf="amountTooHigh" class="error"> {{ "amountTooHigh" | translate }} </div>
            <div class="totalCost"> 
                <div class="totalCostBox">
                    <div class="totalTitle"> {{ "hotelCO2Coins" | translate }} </div>
                    <div> {{hotelCO2Coins}} {{ "CO2Coins" | translate }} </div>
                    <br>
                    <div class="totalTitle"> {{ "totalCO2Coins" | translate }} </div>
                    <div> {{total}} {{ "CO2Coins" | translate }} </div>
                </div>
            </div>
            <div class="order">
                <button class="orderButton" (click)="confirmOrder()"> {{ "orderCO2Button" | translate }} </button>
            </div>
        </div>
    </div>
</ion-content>
