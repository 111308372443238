<form [formGroup]="form">

    <div class="form-group">
        <ion-label>{{ 'fixedDestination-taxiPrice' | translate }}</ion-label>
        <ion-input type="number"
                   placeholder="e.g. “200“"
                   class="form-control"
                   formControlName="taxiPrice"></ion-input>
        <div *ngIf="taxiPrice && formSubmit"
             class="alert alert-danger">
            <div *ngIf="taxiPrice.errors?.required">
                {{ 'fixedDestination-taxiPriceRequired' | translate }}
            </div>
        </div>
    </div>

    <div class="form-group">
        <ion-label>{{ 'fixedDestination-hotelPrice' | translate }}</ion-label>
        <ion-input type="number"
                   placeholder="e.g. “50“"
                   class="form-control"
                   formControlName="hotelPrice"></ion-input>
        <div *ngIf="hotelPrice && formSubmit"
             class="alert alert-danger">
            <div *ngIf="hotelPrice.errors?.required">
                {{ 'fixedDestination-hotelPriceRequired' | translate }}
            </div>
        </div>
    </div>

    <div class="form-group">
        <ion-label>{{ 'fixedDestination-guestPrice' | translate }}</ion-label>
        <ion-input type="number"
                   class="form-control"
                   [value]="taxiPrice?.value + hotelPrice?.value"
                   [disabled]="true"></ion-input>
    </div>

</form>