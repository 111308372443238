import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'special-hotel-info-popup',
  templateUrl: './special-hotel-info-popup.component.html',
  styleUrls: ['./special-hotel-info-popup.component.scss']
})
export class SpecialHotelInformationPopup {

  informationList: string[] = [];

  constructor(private dialogRef: MatDialogRef<SpecialHotelInformationPopup>, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.informationList = data;
  }

  public clickedContinue(): void {
    this.dialogRef.close();
  }
}
