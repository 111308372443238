<ion-content id="bookTaxi">
  <app-header [logoSrc]="logoSrc" [language]="hotelInfo.language" [name]="showName"></app-header>
  <div class="taxiHotel">
    <div class="center">
      <div class="left-container">
        <app-cancelled-rides-applytobill-remove *ngIf="showCancelledApplyToBill"></app-cancelled-rides-applytobill-remove>
        <app-average-time *ngIf="getDisplayAverage()"></app-average-time>
        <app-rating *ngIf="getDisplayRating()"></app-rating>
        <app-coin-tree-counter-container></app-coin-tree-counter-container>
      </div>
      <div class="main-container">

        <div class="tabs">
          <mat-button-toggle-group name="fontStyle"
                                   aria-label="Font Style"
                                   value="destination">
            <mat-button-toggle value="destination"
                               (click)="otherDestination()">
              {{ "anotherDestination" | translate }}
            </mat-button-toggle>

            <!--<mat-button-toggle value="lufthavnen"
                             (click)="toAirport()">
            {{ "toAirport" | translate }}
          </mat-button-toggle>-->
          
            <mat-button-toggle value="fromairport"
                               (click)="fromAirport()" *ngIf="this.canCreateFromAirportRide">
              {{ "fromAirport" | translate }}
            </mat-button-toggle>

            <button mat-button class="mat-button" (click)="sendLinkPopupActivator()">
              {{ "sendPaymentLinkToCustomer_tabText" | translate }}
            </button>

            <mat-button-toggle (click)="taxiSupport()">
              {{ "taxiSupport" | translate }}
            </mat-button-toggle>

            <mat-button-toggle value="fromairport"
                               class="big-screen simple-button ml"
                               disabled>
              {{ "taxi-rides" | translate }} & {{ "taxi-arrivals" | translate }}
            </mat-button-toggle>

            <!--<mat-button-toggle value="fromairport"
                               class="bigger-screen simple-button"
                               disabled>
              {{ "taxi-arrivals" | translate }}
            </mat-button-toggle>-->
          </mat-button-toggle-group>
        </div>

        <app-taxi-status-message></app-taxi-status-message>

        <div class="site-content">
          <form class="orderForm">
            <div class="container">
              <app-second-destination *ngIf="otherDestinationTab"></app-second-destination>
              <app-airport *ngIf="toAirportTab"></app-airport>
              <app-from-airport *ngIf="fromAirportTab"></app-from-airport>
              <app-taxi-contact *ngIf="taxiSupportTab"></app-taxi-contact>
            </div>
          </form>
          <div class="right-side">
            <div>
              <app-upcoming-rides class="big-screen"></app-upcoming-rides>
              <!--<app-arrivals-of-the-day [onlyLatest]="true"
                                       class="bigger-screen"></app-arrivals-of-the-day>-->
            </div>
            <div class="google-map"
                 *ngIf="displayMap">
              <app-google-map [carCoordinates]="taxiCars"></app-google-map>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>