import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SmsService } from 'src/app/services/sms.service';

@Component({
  selector: 'app-send-link-popup',
  templateUrl: './send-link-popup.component.html',
  styleUrls: ['./send-link-popup.component.scss']
})
export class SendLinkPopupComponent implements OnInit {

  sendLink: FormGroup;
  formSubmitted: boolean = false;
  isSendingSms: boolean = false;

  constructor(fb: FormBuilder, private dialogRef: MatDialogRef<SendLinkPopupComponent>, private smsService: SmsService) {
    this.sendLink = fb.group({
      phoneNo: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]],
    });
  }

  get phoneNo() {
    return this.sendLink.get('phoneNo');
  }

  sendSms() {
    if (this.isSendingSms) return;

    this.formSubmitted = true;
    if (this.sendLink.valid) {
      //Create spinner
      this.isSendingSms = true;
      this.dialogRef.disableClose = true;

      //Call the sms service
      this.smsService.sendQRCodeLink(this.sendLink.value.phoneNo).subscribe(
        (result: Object) => {
          this.dialogRef.close(this.sendLink.value);
        },
        (errorObj: { error: string }) => {

          //Remove spinner
          this.isSendingSms = false;
          this.dialogRef.disableClose = false;

          //Show error message
          alert(errorObj.error);
        }
      );
    }
  }

  ngOnInit(): void {
  }
}
