import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IPriceInfo } from 'src/app/services/models/price-info.model';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'app-price-as-text',
  templateUrl: './price-as-text.component.html',
  styleUrls: ['./price-as-text.component.scss']
})
export class PriceAsTextComponent implements OnDestroy {

  taxiPriceText?: string;
  loadingTaxiPrice?: boolean;

  taxiPriceSubscription: Subscription;
  loadingTaxiPriceSubscription: Subscription;
  languageChangeSubscription?: Subscription;

  priceInfo?: IPriceInfo;

  constructor(priceService: PriceService, private translate: TranslateService) {
    this.taxiPriceSubscription = priceService.taxiPrice.subscribe(priceInfo => {
      this.priceInfo = priceInfo;

      this.updateText()
    });

    this.loadingTaxiPriceSubscription = priceService.loadingTaxiPrice.subscribe(l => this.loadingTaxiPrice = l);

    this.languageChangeSubscription = translate.onDefaultLangChange.subscribe(() => {
      this.updateText();
    });

  }

  ngOnDestroy(): void {
    this.taxiPriceSubscription.unsubscribe();
    this.loadingTaxiPriceSubscription.unsubscribe();
    this.languageChangeSubscription?.unsubscribe();
  }

  private updateText() {
    if (this.priceInfo) {
      this.taxiPriceText = this.translate.instant('taxiPriceFixed');
    }
    else {
      this.taxiPriceText = this.translate.instant('taxiPriceUnknown');
    }
  }
}
