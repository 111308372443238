import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private password?: string;

  get isLoggedIn() { return !!this.password; }

  get adminPassword() { return this.password ?? ""; }
  set adminPassword(password: string) { this.password = password; }
}
