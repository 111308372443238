import { GetRideStatusResponse } from 'src/app/models/ride/ride-status.interface';

export class GetRideStatusMapperService {
    public static mapRideStatus(data: any): GetRideStatusResponse {
        var returnData: GetRideStatusResponse = {
            taxiCoordinate: {
                lng: data.taxiCoordinate?.taxiCoordinate?.longitude,
                lat: data.taxiCoordinate?.taxiCoordinate?.latitude,
                minutesAway: data.taxiCoordinate?.minutesAway,
                additionalGuestInfo: ''
            },
            markerPosition: {
                lng: data.markerPosition?.taxiCoordinate?.longitude,
                lat: data.markerPosition?.taxiCoordinate?.latitude,
                minutesAway: data?.markerPosition?.minutesAway,
                additionalGuestInfo: ''
            },
            taxiStatus: data.taxiStatus,
            etaOfTaxi: data.etaOfTaxi,
            country: data.country,
            logoSrc: data.logoSrc ?? 'assets/images/logo_white.svg',
            taxiCarId: data.taxiCarId,
            paymentIntentId: data.paymentIntentId,
            taxiName: data.taxiName,
            guestPhoneNumber: data.guestPhoneNumber,
        };
        return returnData;
    }
}
