import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { NewUpdatePopupComponent } from '../shared/components/new-update-popup/new-update-popup.component';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  public static get currentVersion() { return "9635ab87a28b1b944b305934b5279a83f0c4960c" };

  constructor(private http: HttpClient, private dialog: MatDialog, private router: Router, private activatedRouter: ActivatedRoute) {
  }

  isPopupOpen = false;
  versionCheckIntervalFunc?: NodeJS.Timeout;

  public startVersionCheck(): void {
    this.versionCheckIntervalFunc = setInterval(async () => {
      if (this.isPopupOpen) return;

      const isVersionUpdated = await this.isVersionUpdated();
      if (isVersionUpdated) {
        this.openNewVersionPopup();
        clearInterval(this.versionCheckIntervalFunc!);
      }
    }, environment.checkForNewVersionUpdateDelayMs);
  }

  public openNewVersionPopup(): void {
    const dialogRef = this.dialog.open(NewUpdatePopupComponent);
    this.isPopupOpen = true;

    dialogRef.afterClosed().subscribe(async (result) => {
      this.isPopupOpen = false;

      if (result) {
        await this.resetInterface();
      }
      else
        this.startVersionCheck();
    });
  }

  async resetInterface(): Promise<void> {
    await this.router.navigate([location.pathname], { queryParams: { ...this.activatedRouter.snapshot.queryParams, "version": await this.getVersion() } });
    window.location.reload();
  }

  async getVersion(): Promise<string> {
    return this.http.get(location.origin + '/version.txt?' + Math.random().toString().replace('0.', '')).toPromise() as Promise<string>;
  }

  async isVersionUpdated(): Promise<boolean> {
    const originVersion = await this.getVersion();
    const localVersion = VersionService.currentVersion;

    return originVersion != localVersion;
  }
}
