import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-update-popup',
  templateUrl: './new-update-popup.component.html',
  styleUrls: ['./new-update-popup.component.scss']
})
export class NewUpdatePopupComponent implements OnInit, OnDestroy {
  seconds = 30;
  timeToReset?: NodeJS.Timeout
  constructor(private dialogRef: MatDialogRef<NewUpdatePopupComponent>) {
  }

  ngOnInit(): void {
    this.timeToReset = setInterval(() => {
      if (this.seconds > 0) { this.seconds--; } else {
        clearInterval(this.timeToReset!);
        this.closeDialog();
      }
    }, 1000);
  }
  ngOnDestroy(): void {
    if (this.timeToReset) { clearInterval(this.timeToReset); }
  }

  private closeDialog(): void {
    this.dialogRef.close(true);
  }

}
