<ng-container>
    <div class="main-container-box">
        <div class="dropdown-container-box">
            <div class="dropdown">
                <button type="button" class="dropbtn" (click)="searchInput.value=''; onDropDownClick()">
                    <img class="dropbtn-part input-shadow" [src]="'./assets/flags/' + selectedFlag?.img" [alt]="selectedFlag?.code">
                </button>
                <div #flagDropdown class="dropdown-content">
                    <input  placeholder="{{ 'chooseCountry' | translate }}" type="text" autocomplete="off" class="search-form-control input-shadow" name="search" ngModel #searchInput
                           (ngModelChange)="search(searchInput.value)">
                    <span *ngFor="let f of flags" (click)=" onFlagSelect(f)"><img [src]="'./assets/flags/' + f?.img" [alt]="f.code">{{f.name}}</span>
                </div>
            </div>
        </div>
        <div class="num-container">
        <div class="dail-code-box">
            <button type="button" class="dial-code dropbtn" (click)="searchInput.value=''; onDropDownClick()">{{selectedFlag?.dial_code}}</button>
        </div>
        <div class="input-container-box">
            <input placeholder="{{ 'guest_number_placeholder_no_country_code' | translate }}" type="number" pattern="[0-9]*" inputmode="numeric" autocomplete="off" class="custom-form-control input-shadow" name="number" ngModel #numberInput phoneNumberOnly (ngModelChange)="onNumberPress(numberInput.value)" [(ngModel)]="inputValue"> 
        </div>
        </div>
    </div>
</ng-container>
