import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-large-taxi-popup',
  templateUrl: './large-taxi-popup.component.html',
  styleUrls: ['./large-taxi-popup.component.scss']
})
export class LargeTaxiPopupComponent implements OnInit {
  textTranslation?: string;
  taxiHotelNumberTranslation?: string;

  constructor(private translate: TranslateService, @Inject(MAT_DIALOG_DATA) data: LargetaxiPopupArguments) {
    this.textTranslation = this.translate.instant("receipt_largeTaxiPopUp").replace('{taxiName}', data.taxiName);
    this.taxiHotelNumberTranslation = this.translate.instant("receipt_largeTaxiPopUpPhoneNumber").replace('{taxiHotelNumber}', data.taxiHotelNumber);
  }



  ngOnInit(): void {
  }

}

interface LargetaxiPopupArguments {
  taxiName: string;
  taxiHotelNumber: string;
}
