export enum RideStatus {
    TaxiOrdered, Persisted, FlightArrivalTimeRequestedFromGuest, FlightArrivalTimeSet, Cancelled, CancelledBecauseTheFlightHasBeenDelayed
}

export enum RideType {
    FromAirport, ToAirport, OtherRide, Direct, FixedPrice
}

export enum TaxiStatus {
    Ordered, OnItsWay, TaximeterStarted, Cancelled, Completed, Unknown, InVain, NotOrderedYet, NoShow, Arrived
}

export enum DisplayOptions {
    None = 0,
    DisplayCoinCounter = 1,
    DisplayTreeCounter = 2,
    DisplayBasicCounter = 4,
    DisplayBonus = 8,
    DisplayProgressBar = 16,
    DisplayNewsPopup = 32,
    DisplayAverage = 64,
    DisplayRating = 128,
}

export enum HotelSettings {
    None = 0,
    RemoveToHotelButton = 1,
    HideEverything = 2,
    ShowNameOnRecep = 4
}

export enum CancelledByDriver {
    False, NoReOrder, ReOrdered, BeenNotifiedReOrder, BeenNotifiedNoReOrder
}

export enum SearchEngine {
    Google, Here
}