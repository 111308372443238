<div class="container">
    <h2 mat-dialog-title class="title">{{ "newUpdatePopupTitle" | translate }}</h2>
    <mat-dialog-content class="content">
       <div [innerHtml]=" 'newUpdatePopupDescription' | translate" ></div>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="clean-btn new-session" [mat-dialog-close]="true" cdkFocusInitial>{{ "newUpdatePopupDescriptionNewSessionButton" | translate }} <p>{{seconds}}</p></button>
            <button class="clean-btn cancel" mat-dialog-close>{{ "newUpdatePopupDescriptionContinueSessionButton" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
