import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageService } from 'src/app/services/page.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';

@Component({
  selector: 'app-taxi-contact',
  templateUrl: './taxi-contact.component.html',
  styleUrls: ['./taxi-contact.component.scss']
})
export class TaxiContactComponent implements OnInit {
  supportText = '';

  constructor(private translate: TranslateService, private taxiConfigurationService: TaxiConfigurationService, private pageService: PageService) { }

  ngOnInit(): void {
    this.translate.get('taxiCompanySupportText').subscribe((translated: string) => {
      this.supportText = translated
        .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
        .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue)
        .replace('{extraInformation}', `${this.taxiConfigurationService.extraContactInformationValue || ""}\n`);

      this.taxiConfigurationService.taxiName.subscribe(_ => {
        this.supportText = translated
          .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue)
          .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue)
          .replace('{extraInformation}', `${this.taxiConfigurationService.extraContactInformationValue || ""}\n`);
      })
    });

    this.pageService.currentHeaderTranslationKey = 'taxi-support';
  }
}
