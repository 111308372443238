import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'replace'
})
export class ReplacePipe implements PipeTransform {
    transform(value: any, key: string, valueToInsert: any): string {
        if (!valueToInsert)
            return value;

        return value.replace('{' + key + '}', valueToInsert);
    }
}