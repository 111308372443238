<div class="gfg">
    <img id="screensaver" src="/assets/images/Screensaver.png"/>
    
    <div class="top-txt-box">
        <h3 class="top-txt"> {{'selfServiceStation_topText' | translate }}</h3>
    </div>

    <div class="button-box">
        <div class="button-row">
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(1)" color="primary">{{ 'selfServiceStation_orderTaxiNow' | translate }}</button>
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(0)" color="primary">{{ 'selfServiceStation_orderTaxiFixedPrice' | translate }}</button>
        </div>
    </div>

    <div class="bottom-element">
        <img id="logo" [src]=logoSrc />
    </div>
</div>
