<div class="header">
    <h1 mat-dialog-title>Update</h1>
    <button mat-dialog-action mat-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div class="container" [formGroup]="rideFormGroup">
  <div class="left-container"> 
    <div class="form-group">
      <div class="title">{{ "selfServiceStation_fromTitle" | translate }}</div>
      <app-destination-selector
        formControlName="from"
        [showErrors]="formSubmit">
      </app-destination-selector>
    </div>

    <div *ngIf="ride?.to" class="form-group">
      <div class="title">{{ "selfServiceStation_destinationTitle" | translate }}</div>
        <app-destination-selector
          formControlName="destination"
          [showErrors]="formSubmit">
        </app-destination-selector>
    </div>

    <div>
      <div class="title"> {{ "guestPickedUp" | translate }} </div>
      <app-date-time-picker formControlName="pickupTime">
      </app-date-time-picker>
    </div>

    <div class="form-group roomNo">
      <div class="title"> {{ "roomNo" | translate }}</div>
      <ion-input formControlName="roomNo"
                  type="text"
                  placeholder="“253“"
                  class="form-control"></ion-input>
    </div>

    <div class="form-group" [ngClass]="'m-l-10'">
      <div class="title"> {{ "guestName" | translate }}</div>
      <ion-input formControlName="guestName"
                  type="text"
                  [placeholder]="translate.instant('guest_name_placeholder')"
                  class="form-control" 
                  input-placeholder></ion-input>
    </div>

    <div>
      <div class="title"> {{ "phoneNoLabel" | translate }}</div>
      <ion-input formControlName="phoneNo"
                  type="text"
                  placeholder="e.g. “”"
                  class="form-control"
                  maxlength="20"></ion-input>
    </div>
    
  </div>

  <div class="right-container">
    <div class="large">
      <div class="title">{{ "commentToDriverLabel" | translate }}</div>
      <ion-input formControlName="commentToDriver"
                  maxlength="200"
                  type="text"
                  placeholder="e.g. “”"
                  class="form-control line-break"></ion-input>
      </div>

      <div *ngIf="ride?.flightNumber" class="form-group">
        <div class="title">{{ "flightNo" | translate }}</div>
        <ion-input formControlName="flightNo"
                   type="text"
                   placeholder="e.g. “SK586“"
                   class="form-controls"
                   (keypress)="flightNoKeyPress($event)">
        </ion-input>
      </div>
  </div>
</div>

<div class="update-button">
  <button mat-button (click)="saveSettings()" class="button" color="primary">Update</button>
</div>