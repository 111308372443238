<div class="container">
    <app-station-header [logoSrc]="rideStatus.logoSrc" [language]="language"></app-station-header>
    <div class="main" *ngIf="rideGotten">
        <div class="labelDiv">
            {{ getTaxiStatusText() }}
        </div>
        <div class="labelDiv">
            <mat-icon>hourglass_full</mat-icon> {{ getEtaText() }}
        </div>
        <div *ngIf="rideStatus.taxiCarId" class="labelDiv">
            {{ 'upcoming-rides-taxi-car-id' | translate }} {{rideStatus.taxiCarId}}
        </div>
        <div *ngIf="rideStatus.taxiName && rideStatus.guestPhoneNumber" class="labelDiv">
            {{ 'call-to-taxi' | translate }} {{rideStatus.taxiName}} {{rideStatus.guestPhoneNumber}}
        </div>
        <div *ngIf="rideStatus.paymentIntentId" class="download-button">
            <button mat-stroked-button (click)="openReceipt(rideId)">
                {{ 'downloadReciept' | translate }}
            </button>
        </div>
        <div *ngIf="showCancel()" class="cancelDiv">
            <button mat-stroked-button (click)="cancelRide()">
                {{'cancelTaxi' | translate}}
            </button>
        </div>
    </div>
    <div class="error labelDiv" *ngIf="!rideGotten">
        <label> {{ 'ridenotfound' | translate }} </label>
    </div>
    <div class="google-map">
        <app-google-map *ngIf="taxiCoordinateKnown" [carCoordinates]="taxiPos" [markerPosition]="rideStatus.markerPosition" [width]="width" [height]="height"></app-google-map>
    </div>
</div>