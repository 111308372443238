<form [formGroup] = "formGroup">
    <div class="content">
        <div class="title"> {{ "recption-comment-title" | translate }} </div>
        <div class="writingbox">
            <textarea formControlName="comment"
                        type="text"
                        [placeholder]="translate.instant('recption-comment-placeholder')"
                        class="input-field"></textarea>
        </div>
        <div class="button-box">
            <button class="button yes" (click)="confirm()">
                {{ 'confirm' | translate }}
            </button>
            <button class="button no" (click)="cancel()">
                {{ 'cancel' | translate }}
            </button>
        </div>
    </div>
</form>