import { TaxiToHotelComponent } from './components/taxi-to-hotel/taxi-to-hotel.component';
import { OrderTaxiComponent } from './components/order-taxi/order-taxi.component';
import { SplashComponent } from './components/splash/splash.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BookTaxiComponent } from './components/book-taxi/book-taxi.component';
import { ControlTripsComponent } from './components/control-trips/control-trips.component';
import { BillHistoryComponent } from './components/bill-history/bill-history.component';
import { BillComponent } from './components/bill/bill.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { InfoAirportComponent } from './components/info-airport/info-airport.component';
import { InfoSharedRideComponent } from './components/info-shared-ride/info-shared-ride.component';
import { CancelTaxiComponent } from './components/cancel-taxi/cancel-taxi.component';
import { SupportComponent } from './components/support/support.component';
import { FixedDestinationEditComponent } from './components/setup/fixed-destination-edit/fixed-destination-edit.component';
import { MainComponent } from './components/setup/main/main.component';
import { FixedDestinationListComponent } from './components/setup/fixed-destination-list/fixed-destination-list.component';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { AdminLoginComponent } from './components/setup/admin-login/admin-login.component';
import { TaxiOverviewComponent } from './components/taxi-overview/taxi-overview.component';
import { TaxiContactComponent } from './components/taxi-contact/taxi-contact.component';
import { TaxiArrivalsComponent } from './components/taxi-arrivals/taxi-arrivals.component';
import { SelfServiceStationComponent } from './components/self-service-station/self-service-station.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { TrackingOrderMapComponent } from './components/tracking-order-map/tracking-order-map.component';
import { ReceiptComponent } from './components/receipt/receipt.component';
import { PayComponent } from './components/pay/pay.component';
import { Co2shopComponent } from './components/co2shop/co2shop.component';
import { Co2reportComponent } from './components/co2report/co2report.component';
import { VoucherComponent } from './components/voucher/voucher.component';
import { RestaurantComponent } from './components/restaurant/restaurant.component';
import { MobileRestaurantComponent } from './components/mobile-restaurant/mobile-restaurant.component';
import { MobileStandardComponent } from './components/mobile-standard/mobile-standard.component';
import { FastOrderComponent } from './components/fast-order/fast-order.component';
import { MobileStatusComponent } from './components/mobile-status/mobile-status.component';
import { TaxiAssistantComponent } from './components/taxi-assistant/taxi-assistant.component';

const routes: Routes = [
  {
    component: SplashComponent,
    path: 'splash'
  },
  {
    component: OrderTaxiComponent,
    path: 'order-taxi'
  },
  {
    component: TaxiToHotelComponent,
    path: 'taxi-to-hotel'
  },
  {
    component: BookTaxiComponent,
    path: 'book-a-taxi'
  },
  {
    component: ControlTripsComponent,
    path: 'control-trips'
  },
  {
    component: BookTaxiComponent,
    path: 'hotel'
  },
  {
    component: BillHistoryComponent,
    path: 'bill-histories'
  },
  {
    component: BillComponent,
    path: 'bill'
  },
  {
    component: InfoAirportComponent,
    path: 'info-airport'
  },
  {
    component: InfoSharedRideComponent,
    path: 'info-shared-ride'
  },
  {
    component: CancelTaxiComponent,
    path: 'cancel-taxi'
  },
  {
    component: TaxiOverviewComponent,
    path: 'taxi-overview'
  },
  {
    component: TaxiArrivalsComponent,
    path: 'taxi-arrivals'
  },
  {
    component: MainComponent,
    path: 'setup',
    children: [
      {
        component: AdminLoginComponent,
        path: 'login'
      },
      {
        component: FixedDestinationListComponent,
        path: 'prices',
        canActivate: [AuthGuardService]
      },
      {
        component: FixedDestinationEditComponent,
        path: 'prices/update',
        canActivate: [AuthGuardService]
      },
      {
        component: FixedDestinationEditComponent,
        path: 'prices/create',
        canActivate: [AuthGuardService]
      }
    ],
  },
  {
    component: PageNotFoundComponent,
    path: 'not-found'
  },
  {
    component: SupportComponent,
    path: 'support'
  },
  {
    component: ReceiptComponent,
    path: 'receipt'
  },
  {
    component: TaxiContactComponent,
    path: 'taxi-support'
  },
  {
    component: SelfServiceStationComponent,
    path: 'self-service-station'
  },
  {
    component: MobileComponent,
    path: 'mobile'
  },
  {
    component: TrackingOrderMapComponent,
    path: 'tracking'
  },
  {
    component: PayComponent,
    path: 'pay'
  },
  {
    component: Co2shopComponent,
    path: 'co2Shop'
  },
  {
    component: Co2reportComponent,
    path: 'co2Report'
  },
  {
    component: VoucherComponent,
    path: 'voucher'
  },
  {
    component: RestaurantComponent,
    path: 'resturant'
  },
  {
    component: RestaurantComponent,
    path: 'restaurant'
  },
  {
    component: MobileRestaurantComponent,
    path: 'mobile-restaurant'
  },
  {
    component: MobileStandardComponent,
    path: 'mobile-standard'
  },
  {
    component: FastOrderComponent,
    path: 'fast-order'
  },
  {
    component: MobileStatusComponent,
    path: 'status'
  },
  {
    component: TaxiAssistantComponent,
    path: 'assistant'
  },
  {
    redirectTo: 'splash',
    path: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
