<ion-content>
  <app-header></app-header>
  <div class="taxiHotel">
    <div class="container">
      <form class="orderForm"
            [formGroup]="taxiToHotel"
            (submit)="order()"
            #taxiToHotelForm="ngForm">
        <div class="addresses">
          <app-airport-chooser [label]="'from'"
                               (airportSelected)="airportSelected($event)"
                               (numberOfAirports)="numberOfAirports($event)"
                               *ngIf="!onlyOneAirport"></app-airport-chooser>
          <div class="item"
               *ngIf="onlyOneAirport">
            <h4>{{ "from" | translate }}: {{ airportName }}</h4>
            <address>
              <span class="material-icons">place</span>
              <span class="text">{{ airportAddress }}</span>
            </address>
          </div>
          <div class="item">
            <h4>{{ "to" | translate }}: {{ hotelName }}</h4>
            <address>
              <span class="material-icons">place</span>
              <span class="text">{{ hotelAddress }}</span>
            </address>
          </div>
          <div class="item"
               *ngIf="valuesFromUrl">
            <h4>{{ "dateOfArrival" | translate }}:</h4>
            <address>
              <span class="material-icons">schedule</span>
              <span class="text">
                {{ flightDate?.value | date: "dd/MM/yy" }}</span>
            </address>
          </div>
          <div class="item"
               *ngIf="valuesFromUrl">
            <h4>{{ "guestName" | translate }}:</h4>
            <address>
              <span class="material-icons">person</span>
              <span class="text"> {{ guestName?.value }}</span>
            </address>
          </div>
        </div>

        <div class="forms">
          <div class="form-group"
               *ngIf="!valuesFromUrl">
            <ion-label>{{ "yourName" | translate }}</ion-label>
            <ion-input type="text"
                       class="form-control"
                       formControlName="guestName">
            </ion-input>
            <div *ngIf="guestName && formSubmit"
                 class="alert alert-danger">
              <div *ngIf="guestName?.errors?.required">
                {{ "yourNameRequired" | translate }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <ion-label>{{ "phoneNoLabel" | translate }}</ion-label>
            <ion-input formControlName="phoneNo"
                       type="tel"
                       placeholder="Guest Number: “+4531392328”"
                       class="form-control">
            </ion-input>
            <div *ngIf="phoneNo && formSubmit"
                 class="alert alert-danger">
              <div *ngIf="phoneNo?.errors?.required">
                {{ "phoneNoRequired" | translate }}
              </div>
              <div *ngIf="phoneNo?.errors?.minlength">
                {{ "phoneNoMinimum" | translate }}
              </div>
              <div *ngIf="phoneNo?.errors?.pattern">
                {{ "phoneNoValid" | translate }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <ion-label>{{ "email" | translate }}</ion-label>
            <ion-input formControlName="email"
                       type="email"
                       placeholder="E-mail"
                       class="form-control"></ion-input>
            <div *ngIf="email && formSubmit"
                 class="alert alert-danger">
              <div *ngIf="email?.errors?.required">
                {{ "emailRequired" | translate }}
              </div>
              <div *ngIf="email?.errors?.email">
                {{ "emailValid" | translate }}
              </div>
            </div>
          </div>

          <div class="form-group"
               *ngIf="!valuesFromUrl">
            <ion-label>{{ "bookingNumber" | translate }}</ion-label>
            <ion-input type="text"
                       class="form-control"
                       formControlName="bookingNumber">
            </ion-input>
            <div *ngIf="bookingNumber && formSubmit"
                 class="alert alert-danger">
              <div *ngIf="bookingNumber?.errors?.required">
                {{ "bookingNumberRequired" | translate }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <ion-label>{{ "flightno" | translate }}</ion-label>
            <ion-input type="text"
                       placeholder="e.g. “SK586“"
                       class="form-control"
                       formControlName="flightNo"
                       (change)="flightNoUpdated()"
                       (keypress)="flightNoKeyPress($event)">
            </ion-input>
            <div *ngIf="flightNo && formSubmit"
                 class="alert alert-danger">
              <div *ngIf="flightNo?.errors?.required">
                {{ "flightNoRequired" | translate }}
              </div>
            </div>
            <div *ngIf="flightNotFound"
                 class="alert alert-danger">
              {{ "flightNotFound" | translate }}
            </div>
          </div>

          <span id="flightArrivalTime"></span>
          <div class="form-group"
               *ngIf="!valuesFromUrl || flightNotFound">
            <div class="taxiInfo">
              <h2>
                <mat-icon>watch_later</mat-icon>
                {{ "guestFlightArrivalTime" | translate }}
              </h2>
              <div class="innercontainer">
                <mat-form-field appearance="fill"
                                *ngIf="!valuesFromUrl">
                  <mat-label>{{ "chooseDate" | translate }}</mat-label>
                  <input matInput
                         [matDatepicker]="picker"
                         formControlName="flightDate"
                         [min]="minimumValidDate" />
                  <mat-datepicker-toggle matSuffix
                                         [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

                <div>{{ "flightArrivalTime-optional" | translate }}</div>

                <ion-grid class="ion-no-padding"
                          style="padding-top: 20px">
                  <ion-row class="ion-no-padding">
                    <ion-col size="3"
                             style="padding-top: 6px">
                      <button style="display: inline; font-size: 20px"
                              mat-button
                              color="primary"
                              type="button"
                              (click)="decreaseTime()">
                        {{ "-" | translate }}
                      </button>
                    </ion-col>
                    <ion-col size="6"
                             class="ion-no-padding">
                      <mat-slider class="customSlide"
                                  thumbLabel
                                  formControlName="pickupTime"
                                  [displayWith]="formatLabel"
                                  tickInterval="1"
                                  [step]="step"
                                  (change)="pickupTimeChange()"
                                  min="0"
                                  max="23.92"></mat-slider>
                    </ion-col>
                    <ion-col size="3"
                             style="padding-top: 6px">
                      <button style="display: inline; font-size: 20px"
                              mat-button
                              color="primary"
                              type="button"
                              (click)="increaseTime()">
                        {{ "+" | translate }}
                      </button>
                    </ion-col>
                  </ion-row>
                </ion-grid>

                <div *ngIf="flightNotFound && pickupTime?.errors?.required"
                     class="alert alert-danger">
                  {{ "flightNotFoundSoTimeRequired" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="taxiInfo">
          <h2>
            <mat-icon>local_taxi</mat-icon>
            {{ "possibilityToOrder" | translate }}
          </h2>
          <app-price-of-large-taxi></app-price-of-large-taxi>
          <mat-checkbox aria-label="Select an option"
                        (change)="isShowShare($event)"
                        formControlName="largeTaxi"
                        color="primary">{{
            "fixedDestination-largeTaxiDescription" | translate
            }}</mat-checkbox>&nbsp;&nbsp;
          <mat-checkbox aria-label="Select an option"
                        (change)="isShowShare($event)"
                        formControlName="stationCar"
                        color="primary">{{ "stationCar" | translate }}</mat-checkbox> &nbsp;&nbsp;

          <mat-checkbox aria-label="Select an option"
                        (change)="isShowShare($event)"
                        formControlName="animal"
                        color="primary">{{ "animalLabel" | translate }}</mat-checkbox>&nbsp;&nbsp;

          <mat-checkbox aria-label="Select an option"
                        (change)="isShowShare($event)"
                        formControlName="bike"
                        color="primary">{{ "bikeLabel" | translate }}</mat-checkbox>&nbsp;&nbsp;
          <mat-checkbox aria-label="Select an option"
                        (change)="isShowShare($event)"
                        formControlName="electricCar"
                        color="primary">{{ "electricCarLabel" | translate }}
          </mat-checkbox>
          <div *ngIf="largeTaxi && formSubmit"
               class="alert alert-danger">
            <div *ngIf="largeTaxi?.errors?.required">
              {{ "pleaseSelectOption" | translate }}
            </div>
          </div>
        </div>

        <div class="taxiInfo"
             *ngIf="showOnLarge">
          <h2>
            <mat-icon>person</mat-icon> {{ "chooseNumberOfGuests" | translate }}
          </h2>
          <div class="innercontainer">
            <div class="guests">
              <span class="steps">4</span>
              <span class="steps">5</span>
              <span class="steps">6</span>
              <span class="steps">7</span>
            </div>
            <mat-slider class="customSlide"
                        thumb
                        tickInterval="1"
                        min="4"
                        max="7"
                        formControlName="numberOfGuests"
                        [value]="1"></mat-slider>
          </div>
        </div>

        <div class="taxiInfo"
             *ngIf="showOnLarge != true && taxiCanShareTaxi && hotelSharesTaxi">
          <h2>
            <mat-icon>local_taxi</mat-icon> {{ "doYouWishToShare" | translate }}
          </h2>
          <p>
            {{ "doYouWantToShare" | translate }}<br />
            {{
            "shareTaxiCO2" | translate | replace: "savings":sharedRideSavings | replace: "currency":currency
            }}
          </p>
          <mat-radio-group color="primary"
                           aria-label="Select an option"
                           formControlName="shareTaxi">
            <mat-radio-button (change)="isShow($event)"
                              [value]="true">{{
              "yes" | translate
              }}</mat-radio-button>
            <mat-radio-button (change)="isShow($event)"
                              [value]="false">{{
              "no" | translate
              }}</mat-radio-button>
          </mat-radio-group>
          <div *ngIf="shareTaxi && formSubmit"
               class="alert alert-danger">
            <div *ngIf="shareTaxi?.errors?.required">
              {{ "pleaseSelectOption" | translate }}
            </div>
          </div>
          <div class="showtext"
               *ngIf="showOnCheck === '1'">
            <p>
              {{ "shareTaxiInTime" | translate }}
            </p>
          </div>
        </div>
        <div class="showtext"
             *ngIf="showOnCheck === '1'">
          <small>({{ "whenRidesharing" | translate }})</small>
        </div>
        <div class="action">
          <button mat-flat-button
                  color="primary"
                  type="submit">
            <app-price textWhenPriceIsUnknown="orderTaxiButton"
                       textWhenPriceIsKnown="orderTaxiWithKnownPriceButton">
            </app-price>
          </button>
          <small>{{ "whenOrderingTaxi" | translate }}</small>
        </div>
      </form>
    </div>
  </div>
</ion-content>
