<div class="container">
    <table mat-table
           [dataSource]="fixedDestinations">
        <!-- Group header -->
        <ng-container matColumnDef="destinationHeader">
            <mat-cell *matCellDef="let group">
                <strong>{{group.destinationName}}, {{group.destination}}</strong>
            </mat-cell>
        </ng-container>
        <mat-row *matRowDef="let row; columns: ['destinationHeader']; when: isGroup"> </mat-row>

        <ng-container matColumnDef="description">
            <th mat-header-cell
                *matHeaderCellDef></th>
            <td mat-cell
                *matCellDef="let element">{{ destinationDescription(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="guestPrice">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'fixedDestination-fromHotelPrice' | translate }}</th>
            <td mat-cell
                *matCellDef="let element">{{ element.taxiPrice + element.hotelPrice }}</td>
        </ng-container>

        <ng-container matColumnDef="guestPriceToHotel">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'fixedDestination-toHotelPrice' | translate }}</th>
            <td mat-cell
                *matCellDef="let element">{{ !!element.taxiPriceToHotel ? (element.taxiPriceToHotel + element.hotelPriceToHotel) : '' }}</td>
        </ng-container>

        <ng-container matColumnDef="paymentOptionsAllowed">
            <th mat-header-cell
                *matHeaderCellDef>{{ 'fixedDestination-paymentOptionsAllowed' | translate }}</th>
            <td mat-cell
                *matCellDef="let element">{{ getPaymentOptionsAllowedString(element.paymentOptionsAllowed) }}</td>
        </ng-container>

        <tr mat-header-row
            *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row
            *matRowDef="let row; columns: displayedColumns;"
            (click)="fixedDestinationSelected(row)"></tr>
    </table>

    <div class="action">
        <button mat-flat-button
                color="primary"
                (click)="create()">{{ 'fixedDestination-add' | translate }} </button>
    </div>

    <div class="urlDashboard">
        <admin-hotel-url-dashboard></admin-hotel-url-dashboard>
    </div>
</div>