<div class="splash">
  <div class="overlay">
    <div class="bg-white">
      <div class="step"
           [class.step-show]="slideItem === 'one'"
           [class.step-one]="slideItem === 'two'">
        <img src="assets/images/splash-img.png"
             alt="">
        <h2>{{'thanksForUsing' | translate}}</h2>
        <p>{{'byChoosingUs' | translate}}</p>
        <div class="example-button-container">
          <button mat-flat-button
                  (click)="open()">{{'iAmReady' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>