import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, Output } from "@angular/core";
import { Address } from "./objects/address";
import { Options } from "./objects/options/options";

declare let google: any;

@Directive({
  selector: '[appGooglePlace]'
})
export class GooglePlaceDirective implements AfterViewInit {
  @Input('options') options?: Options;
  @Input('disableAutocomplete') disableAutocomplete: boolean = false;
  @Output() onAddressChange: EventEmitter<Address> = new EventEmitter();
  private autocomplete: any;
  private eventListener: any;
  public place?: Address;

  constructor(private el: ElementRef, private ngZone: NgZone) {
  }

  ngAfterViewInit(): void {
    if (!this.options)
      this.options = new Options();

    // this.initialize();
  }

  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  public initialize(): void {
    if (!this.isGoogleLibExists()) throw new Error("Google maps library can not be found");

    this.el.nativeElement.addEventListener('input', (event: Event) => {
      // disableAutocomplete destroy Google Autocomplete to stop suggestions
      if (this.disableAutocomplete) {
        this.destroyGoogleAutocomplete();
      } else {
        // Reinitialize autocomplete if disableAutocomplete is false
        if (!this.autocomplete) {
          this.initGoogleAutocomplete();
        }
      }
    });

    this.el.nativeElement.addEventListener('keydown', (event: KeyboardEvent) => {
      if (!event.key) {
        return;
      }

      let key = event.key.toLowerCase();

      if (key == 'enter' && event.target === this.el.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
      }
    });

    // according to https://gist.github.com/schoenobates/ef578a02ac8ab6726487
    if (window && window.navigator && window.navigator.userAgent && navigator.userAgent.match(/(iPad|iPhone|iPod)/g)) {
      setTimeout(() => {
        let containers = document.getElementsByClassName('pac-container');

        if (containers) {
          let arr = Array.from(containers);

          if (arr) {
            for (let container of arr) {
              if (!container)
                continue;

              container.addEventListener('touchend', (e) => {
                e.stopImmediatePropagation();
              });
            }

          }
        }
      }, 500);
    }
  }

  private initGoogleAutocomplete(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, this.options);
    if (!this.autocomplete) throw new Error("Autocomplete is not initialized");

    if (!this.autocomplete.addListener != null) // Check to bypass https://github.com/angular-ui/angular-google-maps/issues/270
    {
      this.eventListener = this.autocomplete.addListener('place_changed', () => {
        this.handleChangeEvent();
      });
    }
  }

  private destroyGoogleAutocomplete(): void {
    if (this.eventListener) {
      google.maps.event.removeListener(this.eventListener);
      this.eventListener = null;
    }
    this.autocomplete = null;

    const containers = document.getElementsByClassName('pac-container');
    Array.from(containers).forEach((container: any) => {
      container.parentNode.removeChild(container);
    });
  }

  public reset(): void {
    if (this.options?.componentRestrictions)
      this.autocomplete.setComponentRestrictions(this.options?.componentRestrictions);

    if (this.options?.types)
      this.autocomplete.setTypes(this.options?.types);
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   // This is an ugly hack to make sure we have a chance to load the country restriction before initializing the component
  //   if (!changes.options.isFirstChange())
  //     this.initialize();
  // }

  private handleChangeEvent(): void {
    this.ngZone.run(() => {
      this.place = this.autocomplete.getPlace();

      if (this.place) {
        this.onAddressChange.emit(this.place);
      }
    });
  }
}
