import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-input-hotel-json-dialog',
  templateUrl: './input-hotel-json-dialog.component.html',
  styleUrls: ['./input-hotel-json-dialog.component.scss']
})
export class InputHotelJsonDialogComponent implements OnInit {

  constructor(public dialog: MatDialogRef<InputHotelJsonDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialog.close();
  }

  onFileSelected(dialog: MatDialogRef<InputHotelJsonDialogComponent>, event: Event) {
    const inputElement = event.target as HTMLInputElement;

    const file: File = inputElement.files?.item(0) as File;

    if (file) {
      var reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = function (evt: any) {
        dialog.componentInstance.data = JSON.stringify(JSON.parse(evt.target.result), null, 4);

      }
      reader.onerror = function (evt) {
        console.log('error reading file');
      }
    }
  }


}
