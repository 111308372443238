<div class="gfg">
    <img id="screensaver" src="/assets/images/Screensaver.png"/>
    
    <div class="top-txt-box">
        <img id="assistant" src="/assets/images/assistant.png">
        <div class="top-txt"> {{ 'mobile_standard_toptext' | translate }}</div>
        <img *ngIf="hotelLogoSrc" id="hotelLogoSrc" [src]=hotelLogoSrc>
        <div class="top-txt"> {{ 'mobile_standard_secondtext' | translate }}</div>
    </div>

    <div class="button-box">
        <div class="button-row">
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(0)" color="primary" style="margin-bottom: 10%;">{{ 'book_now' | translate }}</button>
            <button mat-flat-button class="continue-button" (click)="continue(1)" color="primary">{{ 'selfServiceStation_orderTaxiAirport' | translate }}</button>
        </div>
    </div>

    <div class="bottom-element">
        <img id="logo" [src]=logoSrc />
    </div>
</div>
