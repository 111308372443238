<div *ngIf="!isInitialized">
</div>

<div class="text-center" *ngIf="isInitialized && rides.length === 0">
    {{ 'arrivals-of-the-day-none-planned' | translate }}
</div>

<div class="text-center" *ngIf="rides.length > 0">
    {{ 'arrivals-of-the-day-planned' | translate }}

    <div class="ride"
         *ngFor="let ride of rides">
        <div class="header">{{ ride.pickupTime + 'z' | date:'HH:mm' }}</div>
        <div class="content">
            {{ 'arrivals-of-the-day-from' | translate }} {{ ride.from.name }}
            <span *ngIf="statusOnItsWay(ride)"
                  class="status on-its-way">{{ 'arrivals-of-the-day-on-its-way' | translate }}</span>
            <span *ngIf="statusTaximeterStarted(ride)"
                  class="status taximeter-started">{{ 'arrivals-of-the-day-taximeter-started' | translate }}</span>
            <span *ngIf="statusInVain(ride)"
                  class="status in-vain">{{ 'arrivals-of-the-day-in-vain' | translate }}</span>
            <span *ngIf="statusCancelledBecauseOfADelayedFlight(ride)"
                  class="status cancelled">{{ 'arrivals-of-the-day-cancelled-because-of-a-delayed-flight' | translate }}</span>
            <span *ngIf="statusNoShow(ride)"
                  class="status in-vain">{{ 'upcoming-rides-no-show' | translate }}</span>

            <div *ngIf="ride.bookingNumber">{{ 'arrivals-of-the-day-booking-number' | translate }} {{ ride.bookingNumber }}</div>
            <div *ngIf="ride.roomNumber">{{ 'arrivals-of-the-day-room-number' | translate }} {{ ride.roomNumber }}</div>
            <div *ngIf="ride.guestName">{{ 'arrivals-of-the-day-guest-name' | translate }} {{ ride.guestName }}</div>
        </div>
    </div>
</div>
