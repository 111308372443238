import { Injectable } from '@angular/core';
import { TaxiService } from './taxi.service';
import { environment } from 'src/environments/environment';
import { GetRidePaymentInfoResponse } from '../models/pay/getRidePaymentInfoResponse';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PayService extends TaxiService {

  public getRidePaymentInfo(rideId: string): Observable<GetRidePaymentInfoResponse> {
    return this.post(environment.getRidePaymentInfo, { rideId });
  }

  public confirmPaymentAndOrderRide(rideId: string, checkoutId: string): Observable<any> {
    return this.post(environment.confirmPaymentAndOrderRide, { rideId, checkoutId });
  }

  public extendOrderTimeWhenClickingOnLink (rideId: string): Observable<any> {
    return this.post(environment.extendOrderTimeWhenClickingOnLink, { rideId });
  }
}
