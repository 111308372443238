<div class="container">
    <h2 mat-dialog-title class="title">{{ "selfServiceStation_collectPaymentTitle" | translate }}</h2>
    <mat-dialog-content class="content">
        <p>
            {{ "collectPaymentPopup_subtitle" | translate }}
        </p>
        <ul>
            <li>{{ "payInTaxiPopup_description_item1" | translate }}</li>
            <li>{{ "payInTaxiPopup_description_item2" | translate }}</li>
            <li>{{ "payInTaxiPopup_description_item3" | translate }}</li>
        </ul>
    </mat-dialog-content>
</div>
