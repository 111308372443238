import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GetTaxiService } from '../../services/get-taxi.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ErrorOrderPopupComponent } from '../../shared/components/error-order-popup/error-order-popup.component';
import { ICoordinate } from 'src/app/models/coordinate.interface';
import { GetRideStatusMapperService } from 'src/app/shared/mappers/ride-status-mapper.service';
import { GetRideStatusResponse } from 'src/app/models/ride/ride-status.interface';
import { TaxiStatus } from 'src/app/models/enums';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-mobile-status',
    templateUrl: './mobile-status.component.html',
    styleUrls: ['./mobile-status.component.scss']
})

export class MobileStatusComponent implements OnInit, OnDestroy {
    loading!: HTMLIonLoadingElement;
    language: string = 'en';
    rideId: string = '';
    taxiPos: ICoordinate[] = [];
    rideStatus: GetRideStatusResponse = {
        markerPosition: undefined,
        taxiCoordinate: undefined,
        taxiStatus: undefined,
        etaOfTaxi: undefined,
        country: 'England',
        logoSrc: 'assets/images/logo_white.svg',
        taxiCarId: undefined,
        paymentIntentId: undefined,
        taxiName: undefined,
        guestPhoneNumber: undefined,
    }

    rideGotten: boolean = false;
    updateInterval: any;

    width: string = "100vw";
    height: string = "50vh";

    constructor(private translate: TranslateService, public loadingController: LoadingController, private route: ActivatedRoute, private getTaxiService: GetTaxiService, public dialog: MatDialog, private alertController: AlertController) {
        let lang = localStorage.getItem('lang');
        translate.setDefaultLang(lang ? lang : 'en');
    }

    ngOnInit() {
        // Start the interval when the component is initialized
        this.route.queryParams.subscribe(async (params) => {
            if (params.id) {
                this.rideId = params.id;
                this.startAutoUpdate();
            }
        });
    }

    ngOnDestroy() {
        // Clear the interval when the component is destroyed to prevent memory leaks
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    startAutoUpdate() {
        // Call the update method immediately
        this.update();

        // Set the interval to call the update method every 60 seconds (60000 milliseconds)
        this.updateInterval = setInterval(() => {
            this.update();
        }, 60000);
    }

    update() {
        if (!this.getTaxiService)
            return;

        this.getTaxiService.getRideStatus(this.rideId).subscribe(async (data) => {
            this.rideStatus = GetRideStatusMapperService.mapRideStatus(data);

            switch (data.country) {
                case "Denmark":
                    this.language = "da"
                    break;
                case "Norway":
                    this.language = "no"
                    break;
                case "Germany":
                    this.language = "de"
                    break;
                case "Sweden":
                    this.language = "se"
                    break;
                case "Finland":
                    this.language = "fi"
                    break;
            }

            if (this.rideStatus.taxiCoordinate?.lat || this.rideStatus.taxiCoordinate?.lng)
                this.taxiPos = [this.rideStatus.taxiCoordinate];

            this.rideGotten = true;

            setTimeout(this.update, 60000);
        }, error => {
            this.generalErrorPopUp(this.translate.instant('ridenotfound'));
        });
    }

    get taxiCoordinateKnown() {
        if ((this.rideStatus.markerPosition?.lat || this.rideStatus.markerPosition?.lng))
            return true
        else
            return false
    }

    getTaxiStatusText() {
        if (this.rideStatus.taxiStatus == undefined)
            return '';

        if (this.rideStatus.taxiStatus === TaxiStatus.Ordered)
            return this.translate.instant('taxiStatusOrdered');

        if (this.rideStatus.taxiStatus === TaxiStatus.OnItsWay)
            return this.translate.instant('taxiStatusOnItsWay');

        if (this.rideStatus.taxiStatus === TaxiStatus.TaximeterStarted)
            return this.translate.instant('taxiStatusTaximeterStarted');

        if (this.rideStatus.taxiStatus === TaxiStatus.Cancelled)
            return this.translate.instant('taxiStatusCancelled');

        if (this.rideStatus.taxiStatus === TaxiStatus.Completed)
            return this.translate.instant('taxiStatusCompleted');

        if (this.rideStatus.taxiStatus === TaxiStatus.Unknown)
            return this.translate.instant('taxiStatusUnknown');

        if (this.rideStatus.taxiStatus === TaxiStatus.InVain)
            return this.translate.instant('taxiStatusInVain');

        if (this.rideStatus.taxiStatus === TaxiStatus.NoShow)
            return this.translate.instant('taxiStatusNoShow');

        if (this.rideStatus.taxiStatus === TaxiStatus.Arrived)
            return this.translate.instant('shortTaxiStatusArrived');

        return '';
    }

    getEtaText() {
        if (this.rideStatus.etaOfTaxi != undefined)
            return this.translate.instant('eta').replace('{minutes}', this.rideStatus.etaOfTaxi);
        else
            return this.translate.instant('etaNotFound');
    }

    showCancel() {
        if (this.rideStatus.taxiStatus == undefined)
            return false;

        if (this.rideStatus.taxiStatus === TaxiStatus.Ordered)
            return true;

        if (this.rideStatus.taxiStatus === TaxiStatus.OnItsWay)
            return true;

        return false;
    }

    async openReceipt(rideId: string) {
        window.open(location.origin + environment.showReceiptUrl.replace("{rideId}", rideId), "_blank");
    }

    async cancelRide() {
        let body = {
            id: this.rideId
        }
        await this.presentLoading("Cancelling...")

        this.getTaxiService.cancelRide(body).subscribe(async (data) => {
            await this.hideLoading()
            this.generalErrorPopUp(this.translate.instant('taxiStatusCancelled'));
        }, async (error) => {
            await this.hideLoading()
        });
    }

    public openErrorPopUp(taxiName: string, taxiHotelNumber: string): void {
        const dialogRef = this.dialog.open(ErrorOrderPopupComponent, {
            data: {
                taxiName,
                taxiHotelNumber
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.resetInterface();
            }
        });
    }

    public async generalErrorPopUp(message: string) {
        const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: message,
            cssClass: 'alertmessage',
            buttons: [
                {
                    text: 'Okay',
                    handler: () => {
                        this.resetInterface();
                    }
                }
            ]
        });
        errorAlert.present();
    }

    async presentLoading(message: string): Promise<void> {
        this.loading = await this.loadingController.create({
            cssClass: 'my-custom-class',
            message: this.translate.instant(message),
            duration: 20000
        });
        await this.loading.present();
    }

    async hideLoading(): Promise<void> {
        if (!this.loading) return;
        await this.loading.dismiss();
    }

    resetInterface(): void {
        window.location.reload();
    }

    delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}