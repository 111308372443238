import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-taxi-status-message',
  templateUrl: './taxi-status-message.component.html',
  styleUrls: ['./taxi-status-message.component.scss']
})
export class TaxiStatusMessageComponent implements OnInit, OnDestroy {

  taxiCompanyStatusMessages?: Subscription;

  statusMessages: { statusMessage: string }[] = [];

  constructor(private hotelService: HotelService) { }

  ngOnInit(): void {
    this.taxiCompanyStatusMessages = this.hotelService.taxiCompanyStatusMesssages.subscribe(statusMessages => {
      this.statusMessages = statusMessages;
    });
  }

  ngOnDestroy(): void {
    this.taxiCompanyStatusMessages?.unsubscribe();
  }

}
