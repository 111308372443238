import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-airport-chooser',
  templateUrl: './airport-chooser.component.html',
  styleUrls: ['./airport-chooser.component.scss']
})
export class AirportChooserComponent implements OnInit {
  hotelId?: string;

  airports?: { id: string, name: string }[];

  selectedAirportId: any;
  @Input() label: string = 'airportChooser-choose';
  @Output() airportSelected = new EventEmitter<string>();
  @Output() numberOfAirports = new EventEmitter<number>();

  constructor(getTaxiService: GetTaxiService, private translate: TranslateService, private parameterService: ParameterService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.selectedAirportId = params.airportId;

      getTaxiService.getAirportsForHotel(this.hotelId!)
        .subscribe(airports => {
          this.airports = airports;

          this.numberOfAirports.emit(airports.length);

          this.selected();
        });
    });
  }

  ngOnInit(): void {
  }

  selected() {
    this.airportSelected.emit(this.selectedAirportId);
  }

}
