import { Component, OnInit } from '@angular/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-info-shared-ride',
  templateUrl: './info-shared-ride.component.html',
  styleUrls: ['./info-shared-ride.component.scss']
})
export class InfoSharedRideComponent implements OnInit {

  // airport variable is set up with default values to be compatible with old SMS's without airportId in URL
  airport: any = {
    iataAirportCode: 'cph',
    guestInfoSharedRide: 'You will receive a SMS if the taxi will be shared or not. If delayed, please use Dantaxi in lane 2. When sharing your taxi, you secure a cheaper price and are helping the environment by reducing CO2 emissions. Thank you!'
  };

  constructor(parameterService: ParameterService, getTaxiService: GetTaxiService) {
    parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      var airportId = params.airportId;

      if (airportId)
        getTaxiService.getAirportInfo(airportId).subscribe(data => {
          this.airport = data;
        });
    });
  }

  ngOnInit(): void {
  }

}
