import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IProduct } from 'src/app/models/co2Products.interface';

@Component({
  selector: 'app-confirm-co2',
  templateUrl: './confirm-co2.component.html',
  styleUrls: ['./confirm-co2.component.scss']
})
export class ConfirmCo2Component {
  products: IProduct[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>) {
    this.products = data.products;
  }

  yes() {
    this.dialogRef.close(true);
  }

  no() {
    this.dialogRef.close(false);
  }

  get total() {
    var totalReturn = 0;
    this.products.forEach((product) => {
      totalReturn += product.amount * product.coinCost;
    });
    return totalReturn
  }
}
