import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { InputHotelJsonDialogComponent } from '../input-hotel-json-dialog/input-hotel-json-dialog.component';
import { UpdateHotelSettingsDialogComponent } from '../update-hotel-settings-dialog/update-hotel-settings-dialog.component';

@Component({
  selector: 'admin-hotel-url-dashboard',
  templateUrl: './admin-hotel-url-dashboard.component.html',
  styleUrls: ['./admin-hotel-url-dashboard.component.scss']
})
export class AdminHotelUrlDashboard implements OnInit {
  loading!: HTMLIonLoadingElement;

  displayedColumns: string[] = ['hotelName', 'actions'];
  hotelsData: HotelData[] = [];
  hotelsMatTableDataSource = new MatTableDataSource<HotelData>();

  hotelId?: string;
  airportId?: string;
  accessToken?: string;
  terminalReaderId?: string;

  getTaxiService: GetTaxiService;

  constructor(public dialog: MatDialog, getTaxiService: GetTaxiService, private router: Router, private translate: TranslateService, public loadingController: LoadingController, public sanitizer: DomSanitizer) {
    this.getTaxiService = getTaxiService;

    this.presentLoading();

    getTaxiService.getHotelInformationForAdminUrlRedirector().subscribe((data: HotelData[]) => {
      this.hotelsMatTableDataSource.data = data;

      this.hideLoading();
    }, error => {
      console.error("error: ", error);
      this.hideLoading();
    });
  }

  ngOnInit(): void {
  }

  gotoHotelPlatform(platform: string, hotelData: HotelData) {
    const hasAccessTokenAndAirportIdAndTerminalId: boolean = platform != "mobile";
    const url = this.router.serializeUrl(this.router.createUrlTree([platform], {
      queryParams: {
        'hotelId': hotelData.id,
        ...hasAccessTokenAndAirportIdAndTerminalId && {
          'accessToken': hotelData.accessToken,
          'airportId': hotelData.airportId,
          ...hotelData.terminalReaderId && { 'terminalReaderId': hotelData.terminalReaderId }
        }
      },
    }));
    window.open(url, '_blank');
  }

  async getHotelData(hotel: HotelData) {
    this.presentLoading();
    this.getTaxiService.getAllHotelDataJson(hotel.id).subscribe(data => {

      this.downloadJson(hotel.hotelName, data);

      this.hideLoading();
    }, error => {
      console.error("error: ", error);
      this.hideLoading();
    });
  }

  downloadJson(hotelId: string, myJson: object) {
    var sJson = JSON.stringify(myJson, null, "\t");
    var element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(sJson));
    element.setAttribute('download', hotelId + ".json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  openUpdateHotelDialog(): void {
    const dialogRef = this.dialog.open(InputHotelJsonDialogComponent, {
      width: '70%',
      data: "",
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.setHotelData(JSON.parse(result));
      }
    });
  }

  async setHotelData(hotelJsonData: object) {
    this.presentLoading();
    this.getTaxiService.setHotelDataJson(hotelJsonData).subscribe(data => {
      console.log("updated data: ", data);

      this.hideLoading();
      window.location.reload();
    }, error => {
      console.error("error: ", error);
      this.hideLoading();
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  openUpdateHotelSettingsDialog(hotelData: HotelData): void {
    const dialogRef = this.dialog.open(UpdateHotelSettingsDialogComponent, {
      width: '70%',
      data: hotelData,
    });

    dialogRef.afterClosed().subscribe(async (result) => {
    });
  }
}

interface HotelData {
  hotelName: string;
  id: string;
  accessToken: string;
  airportId?: string;
  terminalReaderId?: string;
}