import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DuplicatePhonenumberComponentArgs {
  phoneNo: string;
}

@Component({
  selector: 'duplicate-phonenumber',
  templateUrl: './duplicate-phonenumber.component.html',
  styleUrls: ['./duplicate-phonenumber.component.scss']
})

export class DuplicatePhonenumberComponent implements OnInit, OnDestroy {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DuplicatePhonenumberComponentArgs, private dialogRef: MatDialogRef<DuplicatePhonenumberComponent>, public dialog: MatDialog) {
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  public no(): void {
    this.dialogRef.close(false);
  }

  public yes(): void {
    this.dialogRef.close(true);
  }
}
