import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { GooglePlaceDirective } from 'src/app/directives/google-place/google-place.directive';
import { ComponentRestrictions } from 'src/app/directives/google-place/objects/options/componentRestrictions';
import { Options } from 'src/app/directives/google-place/objects/options/options';
import { GetTaxiService, PaymentOptionsAllowed } from 'src/app/services/get-taxi.service';
import { HotelService } from 'src/app/services/hotel.service';
import { PageService } from 'src/app/services/page.service';
import { SaveTaxiService } from 'src/app/services/save-taxi.service';
import { IAddress } from '../../../models/address.interface';
import { AddressMapperService } from '../../../mappers/address-mapper.service';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-fixed-destination-edit',
  templateUrl: './fixed-destination-edit.component.html',
  styleUrls: ['./fixed-destination-edit.component.scss']
})
export class FixedDestinationEditComponent implements OnInit, OnDestroy {
  loading!: HTMLIonLoadingElement;

  public form: FormGroup;
  public formSubmit: boolean = false;

  public editMode: boolean = false;
  fixedDestinations: any[] = [];

  hotelId?: string;
  airportId?: string;
  accessToken?: string;
  destinationAddress?: IAddress;
  selectedAirportId?: string;

  destinationOptions = new Options();
  destinationSearchCountriesSubscription?: Subscription;

  constructor(private fb: FormBuilder, private translate: TranslateService, private router: Router, private route: ActivatedRoute, getTaxiService: GetTaxiService, private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private pageService: PageService, hotelService: HotelService) {
    this.form = this.fb.group({
      destinationName: [null],
      destination: [null],
      prices: fb.array([]),
      isLargeTaxi: [false],
      isSharedTaxi: [false],
      prefixedCommentToTaxiDriver: [null],
      paymentOptionsAllowed: [null]
    });

    this.route.queryParams.subscribe(async (params) => {
      this.hotelId = params['hotelId'];
      this.airportId = params['airportId'];
      this.accessToken = params['accessToken'];

      this.selectedAirportId = this.airportId;

      this.pageService.currentHeaderTranslationKey = 'fixedDestination-priceCreateTitle';

      if (!params['fixedDestinationIds']) {
        this.fixedDestinations = [this.emptyFixedDestination(), this.emptyFixedDestination()];

        this.form.addControl('locationToAddressIsAirport', fb.control(false));

        this.prices.push(fb.group({
          hotelPrice: [null, Validators.required],
          taxiPrice: [null, Validators.required]
        }));
        this.prices.push(fb.group({
          hotelPrice: [null, Validators.required],
          taxiPrice: [null, Validators.required]
        }));
      }
      else {
        await this.presentLoading();

        var fixedDestinationIds: string[];
        if (typeof (params['fixedDestinationIds']) === 'string')
          fixedDestinationIds = [params['fixedDestinationIds']];
        else
          fixedDestinationIds = [...params['fixedDestinationIds']];

        this.editMode = true;
        this.pageService.currentHeaderTranslationKey = 'fixedDestination-priceUpdateTitle';

        getTaxiService.getFixedDestinationsByIds(fixedDestinationIds)
          .subscribe(async fixedDestinations => {
            this.fixedDestinations = fixedDestinations;

            this.destinationName!.setValue(fixedDestinations[0].locationToName);
            this.destination!.setValue(fixedDestinations[0].locationToAddress);
            this.isLargeTaxi!.setValue(fixedDestinations[0].isLargeTaxi);
            this.isSharedTaxi!.setValue(fixedDestinations[0].isSharedTaxi);
            this.prefixedCommentToTaxiDriver!.setValue(fixedDestinations[0].prefixedCommentToTaxiDriver);
            this.paymentOptionsAllowed!.setValue(fixedDestinations[0].paymentOptionsAllowed)

            for (var i = 0; i < fixedDestinations.length; i++)
              this.prices.push(fb.group({
                hotelPrice: [fixedDestinations[i].hotelPrice, Validators.required],
                taxiPrice: [fixedDestinations[i].taxiPrice, Validators.required]
              }));

            await this.hideLoading();
          });
      }
    });

    this.destinationSearchCountriesSubscription = hotelService.destinationSearchCountries.subscribe(countries => {
      if (countries) {
        this.destinationOptions = {
          componentRestrictions: new ComponentRestrictions({
            country: countries
          })
        };

        // The destinationOptions should be set before we can initialize the autocomplete component
        // and this requires us to wait a bit :-/
        setTimeout(() => this.destinationDropdown?.initialize(), 250);
      }
    });
  }

  @ViewChild(GooglePlaceDirective) destinationDropdown?: GooglePlaceDirective;

  ngOnInit(): void {
    this.destination?.valueChanges.subscribe((value: any) => {
      this.destination?.setValue(value, { onlySelf: true, emitEvent: false, emitModelToViewChange: true });
    }, error => { }, () => { });
  }

  ngOnDestroy(): void {
    this.destinationSearchCountriesSubscription?.unsubscribe();
  }

  emptyFixedDestination() {
    return {
      taxiPrice: 0,
      hotelPrice: 0
    };
  }

  get destinationName() { return this.form.get('destinationName'); }

  get destination() { return this.form.get('destination'); }

  get isLargeTaxi() { return this.form.get('isLargeTaxi'); }

  get isSharedTaxi() { return this.form.get('isSharedTaxi'); }

  get prefixedCommentToTaxiDriver() { return this.form.get('prefixedCommentToTaxiDriver'); }

  get prices() { return this.form.get('prices') as FormArray; }

  get locationToAddressIsAirport() { return this.form.get('locationToAddressIsAirport'); }

  get paymentOptionsAllowed() { return this.form.get('paymentOptionsAllowed'); }

  handleAirportDestinationChange(event: MatCheckboxChange) {
    this.locationToAddressIsAirport?.setValue(event.checked);
    this.destinationName!.setValue('');
    this.destination!.setValue('');
  }

  airportSelected(airportId: string) {
    this.selectedAirportId = airportId;
  }

  handleAddressChange(address: any) {
    this.destinationName?.setValue(address.name);
    this.destination?.setValue(address.formatted_address);
    this.destinationAddress = AddressMapperService.mapGoogleAddressComponentsToIAddress(address);
  }

  handleLargeTaxiChange(event: MatCheckboxChange) {
    if (event.checked)
      this.isSharedTaxi?.setValue(false);
  }

  handleSharedTaxiChange(event: MatCheckboxChange) {
    if (event.checked)
      this.isLargeTaxi?.setValue(false);
  }

  handlePrefixedCommentToTaxiDriverChange(prefixedCommentToTaxiDriver: string) {
    this.prefixedCommentToTaxiDriver?.setValue(prefixedCommentToTaxiDriver);
  }

  getPricesFormGroupAt(index: number) {
    return <FormGroup>this.prices.controls[index];
  }

  async submit() {
    await this.presentLoading();

    this.formSubmit = true;

    if (!this.form.valid || (!this.destination!.value && !this.locationToAddressIsAirport!.value)) {
      await this.hideLoading();
      return;
    }

    for (var i = 0; i < this.fixedDestinations.length; i++) {
      this.fixedDestinations[i].hotelPrice = this.prices.at(i).value.hotelPrice;
      this.fixedDestinations[i].taxiPrice = this.prices.at(i).value.taxiPrice;

      this.fixedDestinations[i].prefixedCommentToTaxiDriver = this.prefixedCommentToTaxiDriver!.value;
      this.fixedDestinations[i].paymentOptionsAllowed = this.paymentOptionsAllowed!.value;
    }

    if (this.editMode)
      this.updateExisting(this.fixedDestinations);
    else
      this.createNew(this.fixedDestinations);
  }

  createNew(fixedDestinations: any) {
    var body = {
      locationToAddressIsAirport: this.locationToAddressIsAirport!.value,
      airportId: this.selectedAirportId,
      toName: this.destinationName!.value,
      toAddress: this.destination!.value,
      isSharedTaxi: this.isSharedTaxi!.value,
      isLargeTaxi: this.isLargeTaxi!.value,
      prefixedCommentToTaxiDriver: this.prefixedCommentToTaxiDriver!.value,
      fixedDestinations: fixedDestinations,
      ToAddressHouseNo: this.destinationAddress?.houseNo,
      ToAddressStreetName: this.destinationAddress?.streetName,
      ToAddressCity: this.destinationAddress?.city,
      ToAddressZipCode: this.destinationAddress?.zipCode,
    };

    this.saveTaxiService.createFixedDestinations(body).subscribe(async _ => {
      await this.hideLoading();
      this.goBack();
    });
  }

  updateExisting(fixedDestinations: any) {
    this.saveTaxiService.updateFixedDestinations(fixedDestinations).subscribe(async _ => {
      await this.hideLoading();
      this.goBack();
    });
  }

  delete() {
    this.saveTaxiService.deleteFixedDestinations(this.fixedDestinations.map(fd => fd.id)).subscribe(async _ => {
      await this.hideLoading();
      this.goBack();
    });
  }

  goBack() {
    this.router.navigate(['setup', 'prices'],
      {
        queryParams: {
          'hotelId': this.hotelId,
          'accessToken': this.accessToken,
          'airportId': this.airportId,
        }
      });
  }

  paymentOptionsAllowedChanged(event: MatRadioChange) {
    this.paymentOptionsAllowed?.setValue(event.value);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

}
