import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TaxiService } from './taxi.service';

@Injectable({
  providedIn: 'root'
})
export class SmsService extends TaxiService implements ISmsService {
  sendQRCodeLink(phoneNumber: string): Observable<Object> {
    return this.post(environment.sendQRCodeLink, { phoneNumber }, true, false);
  }
}

export interface ISmsService {
  sendQRCodeLink(phoneNo: string): Observable<Object>;
}
