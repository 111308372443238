import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TaxiService } from './taxi.service';

@Injectable({
  providedIn: 'root'
})
export class GetHotelSystem extends TaxiService {

  public getCustomerInfo(RoomNumber: string): Observable<any> {
    return this.post(environment.getCustomer, { RoomNumber }, true)
  }

  public getSpecialHotelInformation(body: Date): Observable<any> {
    return this.post(environment.getSpecialHotelInformation, { body }, true)
  }
}
