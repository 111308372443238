export class Price {
    public price: number;
    public currencySymbol: string;

    public constructor(price: number, currencySymbol: string) {
        this.price = price;
        this.currencySymbol = currencySymbol;
    }

    public toString(): string {
        return this.price + " " + this.currencySymbol;
    }
}