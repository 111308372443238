import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { OrderType } from '../enums/order-type.enum';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterService {
  parameters = new BehaviorSubject<Parameters | undefined>(undefined);

  get orderType() {
    return window.location.pathname.indexOf('mobile') > 0 ? OrderType.Mobile : window.location.pathname.indexOf('self-service-station') > 0 ? OrderType.Terminal : OrderType.Hotel;
  }

  constructor(route: ActivatedRoute, private location: Location, private log: LogService) {
    route.queryParams.subscribe((params) => {
      let hotelId = params['hotelId'] ?? undefined;
      let accessToken = params['accessToken'] ?? undefined;
      let airportId = params['airportId'] ?? undefined;
      let terminalReaderId = params['terminalReaderId'] ?? undefined;
      let rideId = (params['id'] ?? '') + (params['rideId'] ?? '');
      let checkoutId = (params['guid'] ?? '') + (params['checkoutId'] ?? '');

      if (this.hasErrors(this.location.path(), hotelId, accessToken, airportId, terminalReaderId, rideId))
        return this.failure();

      if (this.parameters.value === undefined
        || hotelId !== this.parameters.value.hotelId
        || accessToken !== this.parameters.value.accessToken
        || airportId !== this.parameters.value.airportId
        || terminalReaderId !== this.parameters.value.terminalReaderId
        || rideId !== this.parameters.value.rideId
        || checkoutId !== this.parameters.value.checkoutId)
        this.parameters.next(new Parameters(hotelId, accessToken, airportId, terminalReaderId, rideId, checkoutId));
    });
  }

  hasErrors(path: string, hotelId?: string, accessToken?: string, airportId?: string, terminalReaderId?: string, rideId?: string, checkoutId?: string): boolean {
    const guidValidator = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    const hotelIdIsValid = !!hotelId && guidValidator.test(hotelId);
    const accessTokenIsValid = !!accessToken && guidValidator.test(accessToken);
    const airportIdIsValid = !!airportId && guidValidator.test(airportId);
    const terminalReaderIdIsValid = !!terminalReaderId;
    const rideIdIsValid = !!rideId && guidValidator.test(rideId);
    const checkoutIdIsValid = !!checkoutId && guidValidator.test(checkoutId);

    // If the path is empty we are running unit tests and shouldn't return errors unless we get a specific path
    if (path === '')
      return false;

    if (path.startsWith('/taxi-to-hotel'))
      return false;

    //If the path starts with /tracking it means that the user is trying to track a vehicle.
    if (path.startsWith('/tracking'))
      return false;

    if (path.startsWith('/info-airport') || path.startsWith('/info-shared-ride'))
      return !airportIdIsValid;

    if (path.startsWith('/cancel-taxi') || path.startsWith('/receipt') || path.startsWith('/status'))
      return !rideIdIsValid;

    if (path.startsWith('/fast-order'))
      return !hotelIdIsValid;

    if (path.startsWith('/assistant'))
      return !hotelIdIsValid;

    // We always need the hotelId
    if (hotelId === undefined)
      return true;

    // For the mobile solution we need only the hotelId and prevent the others from being incorrectly given to the user
    if (path.startsWith('/mobile'))
      return !hotelIdIsValid || !!accessToken || !!airportId;

    // For the mobile solution we need the hotelId and accesstoken
    if (path.startsWith('/resturant'))
      return !hotelIdIsValid || !accessTokenIsValid;
    if (path.startsWith('/restaurant'))
      return !hotelIdIsValid || !accessTokenIsValid;

    // The terminalReaderId is optional - but if it is provieded it should be valid
    if (!!terminalReaderId && !terminalReaderIdIsValid)
      return true;

    // The self-service-station doesn't need the airportId
    if (path.startsWith('/self-service-station'))
      return !hotelIdIsValid || !accessTokenIsValid;

    // For all other pages we need both an accessToken and the airportId
    return (!path.startsWith('/not-found')) && (!hotelIdIsValid || !accessTokenIsValid || !airportIdIsValid);
  }

  failure() {
    //Comment back in if we want logging on URL back.
    //this.log.error('Invalid URL provided!');

    alert('Invalid URL provided!');

    window.location.href = 'https://hotel-ride.com';
  }
}

export class Parameters {
  constructor(
    public hotelId: string,
    public accessToken?: string,
    public airportId?: string,
    public terminalReaderId?: string,
    public rideId?: string,
    public checkoutId?: string) { }
}