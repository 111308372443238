import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-coin-tree-counter',
    templateUrl: './coin-tree-counter.component.html',
    styleUrls: ['./coin-tree-counter.component.scss']
})
export class CoinTreeCounterComponent implements OnInit, OnDestroy {
    @Output() onLoadEmitter: EventEmitter<any> = new EventEmitter<any>();
    @Input() completedTaxiCountTotal: number = 0;
    @Input() completedTaxiCountMonth: number = 0;
    @Input() monthlyRidesGoal: number = 0;
    @Input() displayCoinCounter: boolean = false;
    @Input() displayBasicCounter: boolean = false;
    @Input() displayTreeCounter: boolean = false;
    @Input() displayProgressBar: boolean = false;
    @Input() displayBonus: boolean = false;
    @Input() currency: string = "";
    @Input() earnedCoinsPerRide: number = 0;
    @Input() alreadyGotPaidAmount: number = 0;
    @Input() earnedCoinsDescription: string = "";
    @Input() monthlyBonusGoalTitle: string = "";

    prepaidTourCompletedShow: boolean = false;
    popupTimer: number = 10000;
    isWhiteTextColor: boolean = false;
    showChristmasImage: boolean;
    private intervalId: any;

    constructor(private router: Router) {
        const today = new Date();
        const currentMonth = today.getMonth(); // Month is 0-indexed (0 is January)
        const currentDay = today.getDate();
        this.showChristmasImage = (currentMonth === 11 && currentDay < 27);

        this.router.events.subscribe(() => {
            const currentRoute = this.router.url;
            // Check if the current route contains the specific parent route
            this.isWhiteTextColor = currentRoute.includes('restaurant') || currentRoute.includes('resturant') || currentRoute.includes('fast-order');
        });
    }

    ngOnInit(): void {
        this.onLoadEmitter.emit();

        this.checkDate();
        this.intervalId = setInterval(() => {
            this.checkDate();
        }, 60 * 60 * 1000); // Check the date every hour (60 min, 60 seconds, 1000 milliseconds)
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    getCoinCount(): number {
        return (this.completedTaxiCountTotal % 500) / 5;
    }

    getCoinProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 500) / 5);
    }

    getGiftCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 500);
    }

    getEarnedCoins(): number {
        var result = this.completedTaxiCountTotal * this.earnedCoinsPerRide;
        result -= this.alreadyGotPaidAmount;
        return result;
    }

    getEarnedCoinsMonthly(): number {
        return this.completedTaxiCountMonth * this.earnedCoinsPerRide;
    }

    getProgressPercentage(): number {
        return (this.getEarnedCoinsMonthly() / this.monthlyRidesGoal) * 100;
    }

    getTreeProgressBarCount(): number {
        return Math.floor((this.completedTaxiCountTotal % 175) / 1.75);
    }

    getTreeCount(): number {
        return Math.floor(this.completedTaxiCountTotal / 175);
    }

    private checkDate(): void {
        const today = new Date();
        const currentMonth = today.getMonth(); // Month is 0-indexed (0 is January)
        const currentDay = today.getDate();

        // Show image if it's December 25th
        this.showChristmasImage = !this.isWhiteTextColor && (currentMonth === 11 && currentDay < 27);
    }
}
