<div *ngIf="optionsAvailable">
    <div *ngIf="!hotelIntegration"> {{ "payment-options" | translate }} </div>

    <div class="taxiInfo">
        <mat-radio-group color="primary"
                         [value]="_selectedValue"
                         [disabled]="readOnly || _selectedValue === 'hotelPays'"
                         *ngIf="!hotelIntegration">
            <mat-radio-button class="colored-button green" [class.tree-icon] = "displayTreeIcon" (change)="selected($event.value)"
                              [value]="'applyToBill'"
                              *ngIf="isApplyToBillAvailable()">
                {{ "apply-to-bill" | translate }}
            </mat-radio-button>

            <mat-radio-button class="colored-button green" [class.tree-icon] = "displayTreeIcon"  (change)="selected($event.value)"
                              [value]="'prepay'"
                              *ngIf="isPrepayAvailable()">
                {{ "prepay" | translate}}
            </mat-radio-button>

            <mat-radio-button class="colored-button gray" (change)="selected($event.value)"
                              [value]="'payInTaxi'"
                              [disabled]="!canPayInTaxi()"
                              *ngIf="isPayInTaxiAvailable()">
                {{ "payInTaxi" | translate}}
            </mat-radio-button>
        </mat-radio-group>

        <div *ngIf="showErrors"
             class="alert alert-danger">
            <div *ngIf="!selectedValue">
                {{ "pleaseSelectOption" | translate }}
            </div>
        </div>
    </div>

</div>
