import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-co2-contact-information',
  templateUrl: './co2-contact-information.component.html',
  styleUrls: ['./co2-contact-information.component.scss']
})

export class Co2ContactInformationComponent implements OnInit {
  phoneRequired: boolean = false;
  emailRequired: boolean = false;
  public formGroup: FormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>, private fb: FormBuilder) {
    this.formGroup = fb.group({
      phoneNumber: new FormControl('', [Validators.minLength(8), Validators.pattern(/^((00|\+)\d{8,16})$/)]),
      email: new FormControl('', [Validators.email]),
    });

    if (data.phoneRequired)
      this.phoneRequired = true;

    if (data.emailRequired)
      this.emailRequired = true;

    if (!data.phoneRequired && !data.emailRequired)
      this.dialogRef.close(true)
  }

  ngOnInit(): void {
  }

  get phoneNumber() {
    return this.formGroup.get('phoneNumber');
  }

  get email() {
    return this.formGroup.get('email');
  }

  yes() {
    this.formGroup.controls["phoneNumber"].updateValueAndValidity();
    this.formGroup.controls["email"].updateValueAndValidity();

    if (!this.formGroup.valid)
      return;
    else
      this.dialogRef.close({
        phoneNumber: this.formGroup.value.phoneNumber,
        email: this.formGroup.value.email
      });
  }

  no() {
    this.dialogRef.close(null);
  }
}
