import { Component, OnInit } from '@angular/core';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-taxi-arrivals',
  templateUrl: './taxi-arrivals.component.html',
  styleUrls: ['./taxi-arrivals.component.scss']
})
export class TaxiArrivalsComponent implements OnInit {

  date: Date = new Date();

  constructor(private pageService: PageService) { }

  ngOnInit(): void {
    this.pageService.currentHeaderTranslationKey = 'taxi-arrivals';
  }

  setDate(date: Date) {
    this.date = date;
  }

}
