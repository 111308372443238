import { ErrorHandler, Injectable } from "@angular/core";
import { LogService } from "./log.service";

@Injectable({
    providedIn: 'root'
})

export class ErrorService extends ErrorHandler {
    constructor(private log: LogService) {
        super();
    }

    handleError(error: any): void {
        if (error.message)
            error = error.message;

        this.log.error(error instanceof Object ? JSON.stringify(error) : error);
        super.handleError(error);
    }
}