<div class="container">
    <div class="continue-payment">
        <button class="clean-btn p-btn continue-payment-terminal" (click)="continuePayAtTerminal()" cdkFocusInitial>
            <!-- <button class="clean-btn new-session" [mat-dialog-close]="true" cdkFocusInitial> -->
            {{ "payInTaxiPopup_continueTerminalPayment_button" | translate }}
        </button>
        <!-- <img src="assets/images/tree.png" class="tree-img" alt="tree"/> -->
    </div>

    <mat-dialog-content class="content">
        <h2>{{ "payInTaxiPopup_title" | translate }}</h2>
        <p>{{ "payInTaxiPopup_description" | translate }}</p>
        <ul>
            <li>{{ "payInTaxiPopup_description_item1" | translate }}</li>
            <li>{{ "payInTaxiPopup_description_item2" | translate }}</li>
            <li>{{ "payInTaxiPopup_description_item3" | translate }}</li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="clean-btn p-btn pay-in-taxi" (click)="openAreYouSureDialog()" cdkFocusInitial>
                <!-- <button class="clean-btn new-session" [mat-dialog-close]="true" cdkFocusInitial> -->
                {{ "payInTaxiPopup_payInTaxiButton" | translate }}
            </button>
            <button class="clean-btn p-btn cancel" (click)="cancelBooking()">{{ "cancelTaxi" | translate }}</button>
        </div>
    </mat-dialog-actions>
</div>
