import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDestination } from '../../models/destination.interface';

export interface InitPopupMobileStrandardComponentArgs {
  logoSrc?: string;
  hotelLogoSrc?: string;
}

@Component({
  selector: 'app-init-popup-mobile-strandard',
  templateUrl: './init-popup-mobile-strandard.component.html',
  styleUrls: ['./init-popup-mobile-strandard.component.scss']
})

export class InitPopupMobileStrandardComponent {

  private _logoSrc: string = 'assets/images/logo_white.svg';
  private _hotelLogoSrc: string | undefined = undefined;

  locations: IDestination[] | undefined = undefined;

  set logoSrc(value: string | undefined) {
    if (value) this._logoSrc = value;
  }

  get logoSrc(): string | undefined {
    return this._logoSrc;
  }

  set hotelLogoSrc(value: string | undefined) {
    if (value) this._hotelLogoSrc = value;
  }

  get hotelLogoSrc(): string | undefined {
    return this._hotelLogoSrc;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: InitPopupMobileStrandardComponentArgs, public dialogRef: MatDialogRef<InitPopupMobileStrandardComponent>) {
    this.logoSrc = data.logoSrc;
    this.hotelLogoSrc = data.hotelLogoSrc;
  }

  continue(value: number): void {
    this.dialogRef.close(value);
  }

  updateComponentData(logoSrc: string | undefined, hotelLogoSrc: string | undefined) {
    this.logoSrc = logoSrc;
    this.hotelLogoSrc = hotelLogoSrc;
  }
}
