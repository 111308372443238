<mat-form-field appearance="fill" 
                class="date-time-picker">
       <input matInput
              type="datetime-local"
              [min]="minDate"
              [max]="maxDate"
              [formControl]="_formControlDate"
              (change)="updatingValue($event)"
              required
              class="date-time-input">
</mat-form-field>