<ion-content>
    <app-header></app-header>

    <div class="content">
        <div class="container">
            <app-simple-date-picker (date)="setDate($event)"></app-simple-date-picker>

            <app-arrivals-of-the-day [date]="date"></app-arrivals-of-the-day>
        </div>
    </div>
</ion-content>