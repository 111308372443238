import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { PaymentOption } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-apply-to-bill-box',
  templateUrl: './apply-to-bill-box.component.html',
  styleUrls: ['./apply-to-bill-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApplyToBillBoxComponent),
      multi: true
    }
  ]
})

export class ApplyToBillBoxComponent implements ControlValueAccessor {
  @Input() showErrors = false;
  @Input() disabled = false;

  onChange: any = () => { };
  onTouched: any = () => { };

  _selectedValue?: string = undefined;
  get applyToBillChecked() { return this.selectedValue == PaymentOption.ApplyToBill }

  get selectedValue(): PaymentOption {
    if (this._selectedValue === 'applyToBill')
      return PaymentOption.ApplyToBill;

    else if (this._selectedValue === 'payByTerminal')
      return PaymentOption.PayByTerminal;

    else if (this._selectedValue === 'payInTaxi')
      return PaymentOption.PayInTaxi;

    else {
      return PaymentOption.PayByTerminal;
    }
  }

  set selectedValue(value: PaymentOption) {
    var previousValue = this._selectedValue;

    if ((value & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal)
      this._selectedValue = 'payByTerminal';

    else if ((value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill)
      this._selectedValue = 'applyToBill';

    else if ((value & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi)
      this._selectedValue = 'payInTaxi';

    if (this._selectedValue != previousValue)
      this.onChange(this.selectedValue);
  }

  constructor() { }

  checkboxCheckChange(value: MatCheckboxChange) {
    if (value.checked == true) {
      this._selectedValue = "applyToBill";
      this.onChange(this.selectedValue);
    }
    else {
      this._selectedValue = "payByTerminal";
      this.onChange(this.selectedValue);
    }
  }

  writeValue(obj: PaymentOption): void {
    this.selectedValue = obj;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.onChange(this.selectedValue);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
