<div class="header">
    <h1 mat-dialog-title>Import</h1>
    <button mat-dialog-action mat-button (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </button>
</div>

<input type="file" class="file-upload" (change)="onFileSelected(this.dialog, $event)" />

<mat-form-field appearance="fill" class="jsonInput">
    <mat-label>Hotel JSON value</mat-label>
    <textarea matInput [(ngModel)]="data" rows="30"></textarea>
</mat-form-field>

<button mat-button [mat-dialog-close]="data" cdkFocusInitial>Update</button>
