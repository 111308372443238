import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LocalStorageTools {
    readonly ReceptionistInitialsKey = "ReceptionistInitials";

    public GetReceptionistInitials(): string | null {
        var initials = sessionStorage.getItem(this.ReceptionistInitialsKey);
        return initials;
    }

    public SetReceptionistInitials(initials: string) {
        sessionStorage.setItem(this.ReceptionistInitialsKey, initials);
    }
}