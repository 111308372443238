<div class="content">
    <div class="products" *ngFor="let product of products">
        <div class="title">
            {{product.name}}
        </div>
        <div class="productText">
            {{ "co2ProductAmount" | translate }} {{product.amount}}
        </div>
        <div class="productText">
            {{ "co2ProductCost" | translate }} {{product.coinCost}}
        </div>
        <div class="productText">
            {{ "co2ProductAmountCoinCost" | translate }} {{product.amount * product.coinCost}}
        </div>
    </div>
    <div class="totalCO2Coins title"> {{ "totalCO2Coins" | translate }} {{total}} </div>
    <div class="choiceBox">
        <div class="title"> {{ "choiceTitle" | translate }} </div>
        <div class="choiceBoxButtonBox">
            <button class="choiceBoxButton yes" (click)="yes()"> {{ "yes" | translate }} </button>
            <br>
            <button class="choiceBoxButton no" (click)="no()"> {{ "no" | translate }} </button>
        </div>
    </div>
</div>
