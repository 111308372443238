import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { GetTaxiService } from '../../services/get-taxi.service';
import { AlertController, IonContent } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { LoadingController } from '@ionic/angular';
import { AirportDirection, PriceService } from 'src/app/services/price.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { PageService } from 'src/app/services/page.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-taxi-to-hotel',
  templateUrl: './taxi-to-hotel.component.html',
  styleUrls: ['./taxi-to-hotel.component.scss'],
})
export class TaxiToHotelComponent implements OnInit, OnDestroy {

  step = 5 / 60;

  hotelId: any;
  airportId: any;
  accessToken: any;
  selectedAirportId?: string;
  onlyOneAirport?: boolean;
  hotelName: any;
  hotelAddress: any;
  airportName: any;
  airportAddress: any;
  sharedRideSavings?: number;
  loading!: HTMLIonLoadingElement;
  flightNotFound = false;
  valuesFromUrl = false;
  largeTaxiPrice?: boolean;
  minimumValidDate: Date;
  taxiCanShareTaxi = true;
  taxiCanShareTaxiSubscription: Subscription = new Subscription;
  hotelSharesTaxi = false;
  hotelSharesTaxiSubscription: Subscription = new Subscription;
  pickupTimeSetManually = false;
  currency = '';

  @ViewChild(IonContent) content: IonContent | undefined;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, private getTaxiService: GetTaxiService, private alertController: AlertController, private translate: TranslateService, private router: Router, private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService, pageService: PageService, private hotelService: HotelService) {
    priceService.setAirportDirection(AirportDirection.fromAirport);
    priceService.setIsLargeTaxi(false);

    this.minimumValidDate = new Date();

    pageService.currentHeaderTranslationKey = 'taxiToHotel'

    hotelService.currency.subscribe(currency => this.currency = currency);

    this.route.queryParams.subscribe(async (params) => {
      if (params == undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;

      var flightDate = params.flightDate;
      var bookingNumber = params.bookingNumber;
      var guestName = params.guestName;

      this.valuesFromUrl = !!flightDate;

      this.taxiToHotel = this.fb.group({
        flightNo: [null, Validators.required],
        phoneNo: [null, [Validators.required, Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]],
        flightDate: [flightDate, Validators.required],
        pickupTime: [null],
        shareTaxi: [null, Validators.required],
        guestName: [guestName, Validators.required],
        bookingNumber: [bookingNumber, Validators.required],
        largeTaxi: [false],
        stationCar: [false],
        animal: [false],
        bike: [false],
        electricCar: [false],
        numberOfGuests: [4],
        email: [null, [Validators.required, Validators.email]]
      })

      let body = {
        hotelId: this.hotelId,
        airportId: this.airportId,
        accessToken: this.accessToken
      }

      await this.presentLoading();
      this.getTaxiService.getTaxiToHotel(body).subscribe(data => {
        console.log(data);
        this.hotelName = data.hotel.name;
        this.hotelAddress = data.hotel.address;
        this.airportName = data.airport.name;
        this.airportAddress = data.airport.address;
        this.sharedRideSavings = data.sharedRideSavings;
        this.largeTaxiPrice = data.largeTaxiPrice;
        this.hideLoading();
      }, async (error) => {
        this.hideLoading();
        const alert = await this.alertController.create({
          header: "Error Occurred",
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
                this.router.navigateByUrl('/not-found');
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();
      });
    });
  }

  ngOnDestroy(): void {
    if (this.taxiCanShareTaxiSubscription)
      this.taxiCanShareTaxiSubscription.unsubscribe();

    if (this.hotelSharesTaxiSubscription)
      this.hotelSharesTaxiSubscription.unsubscribe();
  }

  formatLabelHasBeenChanged = false;
  formatLabel(value: number | null) {
    if (!value) {
      if (this.formatLabelHasBeenChanged)
        return '00:00';
      else
        return ''
    }
    if (value === 0) {
      return '00:00'
    }
    if (value === 24) {
      return '23:59'
    }

    this.formatLabelHasBeenChanged = true;

    let decimalPart = +value.toString().replace(/^[^\.]+/, '0');
    let mm = decimalPart * 60;
    mm = Math.round(mm)
    var mmPart = mm.toString().length == 1 ? "0" + mm.toString() : mm.toString();
    if (mmPart == '60') {
      mmPart = '00'
    }
    var hhPart = '00';
    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split(".");
      if (strArr[0].length == 1) {
        strArr[0] = "0" + strArr[0];
      }
      hhPart = strArr[0];
      //console.log(strArr);
    }

    return hhPart + ":" + mmPart;
  }

  get tripToHotel() {
    return this.taxiToHotel.get('tripToHotel');
  }
  get phoneNo() {
    return this.taxiToHotel.get('phoneNo');
  }
  get flightNo() {
    return this.taxiToHotel.get('flightNo');
  }
  get shareTaxi() {
    return this.taxiToHotel.get('shareTaxi');
  }
  get largeTaxi() {
    return this.taxiToHotel.get('largeTaxi');
  }
  get numberOfGuests() {
    return this.taxiToHotel.get('numberOfGuests');
  }
  get guestName() {
    return this.taxiToHotel.get('guestName');
  }
  get bookingNumber() {
    return this.taxiToHotel.get('bookingNumber');
  }
  get pickupTime() {
    return this.taxiToHotel.get('pickupTime');
  }
  get flightDate() {
    return this.taxiToHotel.get('flightDate');
  }
  get stationCar() {
    return this.taxiToHotel.get('stationCar');
  }

  get animal() {
    return this.taxiToHotel.get('animal');
  }
  get bike() {
    return this.taxiToHotel.get('bike');
  }

  get electricCar() {
    return this.taxiToHotel.get('electricCar');
  }

  get email() {
    return this.taxiToHotel.get('email');
  }

  public taxiToHotel: FormGroup = <any>null;

  ngOnInit(): void {
    this.taxiCanShareTaxi = this.taxiConfigurationService.canShareTaxiValue;
    if (!this.taxiCanShareTaxi)
      this.shareTaxi!.setValue(false);

    this.taxiCanShareTaxiSubscription = this.taxiConfigurationService.canShareTaxi.subscribe(v => {
      this.taxiCanShareTaxi = v;
      if (!this.taxiCanShareTaxi)
        this.shareTaxi!.setValue(false);
    });

    this.hotelSharesTaxi = this.hotelService.sharingTaxiesValue;
    if (!this.hotelSharesTaxi)
      this.shareTaxi!.setValue(false);

    this.hotelSharesTaxiSubscription = this.hotelService.sharingTaxies.subscribe(v => {
      this.hotelSharesTaxi = v;
      if (!this.hotelSharesTaxi)
        this.shareTaxi!.setValue(false);
    });
  }

  showOnCheck = '';
  formSubmit: boolean = false;
  isShow($event: any) {
    this.showOnCheck = $event.value;
  }

  showOnLarge: any;
  isShowShare($event: any) {
    this.showOnLarge = this.taxiToHotel.value.largeTaxi;
    if (this.taxiToHotel.value.largeTaxi)
      this.shareTaxi?.disable();
    else
      this.shareTaxi?.enable();

    this.priceService.setIsLargeTaxi(this.taxiToHotel.value.largeTaxi);
    if (this.taxiToHotel.value.largeTaxi) {
      this.stationCar?.disable();
    }
    else if (this.taxiToHotel.value.stationCar) {
      this.largeTaxi?.disable();
    }
    if (!this.taxiToHotel.value.largeTaxi) {
      this.stationCar?.enable();
    }
    if (!this.taxiToHotel.value.stationCar) {
      this.largeTaxi?.enable();
    }
  }

  // order(){
  //   if(this.taxiToHotel.valid)
  //   {
  //     console.log(this.taxiToHotel.value);
  //     this.formSubmit=true;
  //   }
  // }

  async order() {
    await this.presentLoading();

    this.formSubmit = true;
    if (!this.taxiToHotel.valid) {
      await this.hideLoading();
      return;
    }

    const alert = await this.alertController.create({
      header: this.translate.instant('thankYouForYourOrder'),
      subHeader: this.translate.instant('taxiOrderedGuest'),
      cssClass: 'alertmessage',
      buttons: [
        {
          text: 'Okay',
          handler: () => {
            this.taxiToHotel.reset({ pickupDate: new Date(), largeTaxi: false, animal: false, bike: false, electricCar: false, numberOfGuests: 4, stationCar: false });
            this.formSubmit = false;
            this.showOnLarge = false;
            this.shareTaxi?.enable();
            setTimeout(() => { this.content?.scrollToTop() }, 500)
          }
        }
      ],
      backdropDismiss: false
    });
    alert.onDidDismiss().then(() => {
      this.taxiToHotel.reset({ pickupDate: new Date(), largeTaxi: false, animal: false, bike: false, electricCar: false, numberOfGuests: 4, stationCar: false });
      this.formSubmit = false;
      setTimeout(() => { this.content?.scrollToTop() }, 500)
    })

    let hour = 12;
    let minute = 0;
    if (this.taxiToHotel.value.pickupTime !== null) {
      let time = this.formatLabel(this.taxiToHotel.value.pickupTime);
      hour = parseInt(time.split(":")[0]);
      minute = parseInt(time.split(":")[1]);
    }
    let date = new Date(this.taxiToHotel.value.flightDate);
    date.setHours(hour);
    date.setMinutes(minute);
    date.setSeconds(0);
    date.setMilliseconds(0);

    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      airportId: this.selectedAirportId,
      phoneNumber: this.taxiToHotel.value.phoneNo,
      flightNumber: this.taxiToHotel.value.flightNo,
      shareRide: this.taxiToHotel.value.shareTaxi,
      flightDate: date.toISOString(),
      pickupTimeSetManually: this.pickupTimeSetManually,
      bookingNumber: this.taxiToHotel.value.bookingNumber,
      largeTaxi: this.taxiToHotel.value.largeTaxi,
      guestName: this.taxiToHotel.value.guestName,
      numberOfGuests: this.taxiToHotel.value.largeTaxi ? this.taxiToHotel.value.numberOfGuests : undefined,
      stationCar: this.taxiToHotel.value.stationCar,
      animal: this.animal?.value,
      bike: this.bike?.value,
      electricCar: this.electricCar?.value,
      flightNotFound: undefined,
      email: this.taxiToHotel.value.email,
      paymentOption: PaymentOption.PayInTaxi
    }

    if (this.flightNotFound)
      body.flightNotFound = <any>true;

    this.saveTaxiService.saveTaxiToHotel(body).subscribe(async (data) => {
      console.log(data);
      await this.hideLoading()
      await alert.present();
    }, async (error) => {
      await this.hideLoading();
      console.log(error);
      if (error.status == 400) {
        if (error.error.statusCode === 'FlightNumberNotFound') {
          this.flightNotFound = true;

          const element = document.getElementById('flightArrivalTime');
          element?.scrollIntoView();

          return;
        }

        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    });

    this.flightNotFound = false;
    // setTimeout(async ()=>{
    //   await alert.dismiss();
    // },3000);
  }

  flightNoKeyPress(event: any) {
    // No spaces (character code 32) allowed
    if (event.keyCode === 32)
      event.preventDefault();
  }

  flightNoUpdated() {
    this.getTaxiService.getFlightInfo(this.flightNo!.value)
      .subscribe(flightInfo => {
        if (flightInfo === null) {
          this.flightNotFound = true;
          this.pickupTime!.setValidators([Validators.required]);
          this.formatLabelHasBeenChanged = false;
          this.pickupTime!.setValue(null);
          this.formatLabel(null);
          this.pickupTimeSetManually = false;

          return;
        }

        this.flightNotFound = false;
        this.pickupTimeSetManually = false;
        this.pickupTime!.setValidators([]);

        // Converting from string to Date
        var flightArrivalTime = new Date(flightInfo.flightTime);
        var hours = flightArrivalTime.getHours();
        var minutes = flightArrivalTime.getMinutes();
        // Calculate the slider value and set it
        var timeValue = hours + minutes / 60;
        this.pickupTime!.setValue(timeValue);
        this.formatLabelHasBeenChanged = true;
      });
  }

  pickupTimeChange() {
    this.pickupTimeSetManually = true;
  }

  airportSelected(airportId: string) {
    this.selectedAirportId = airportId;

    this.priceService.setAirport(airportId);
  }

  numberOfAirports(count: number) {
    this.onlyOneAirport = count === 1;
  }

  increaseTime() {
    if (this.taxiToHotel.value.pickupTime < 23.900066666666667)
      this.pickupTime?.setValue(this.taxiToHotel.value.pickupTime + this.step);

    this.pickupTimeSetManually = true;
  }

  decreaseTime() {
    if (this.taxiToHotel.value.pickupTime > 0.083)
      this.pickupTime?.setValue(this.taxiToHotel.value.pickupTime - this.step);

    this.pickupTimeSetManually = true;
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

}
