<mat-button-toggle-group [value]="selectedTab">

    <mat-button-toggle value="book-a-taxi"
                       (click)="navigate('book-a-taxi')">
        {{ 'orderTaxiButton' | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="control-trips"
                       (click)="navigate('control-trips')">
        {{ 'tripAdmin' | translate }}
    </mat-button-toggle>

    <mat-button-toggle *ngIf="hotelInfo && !hotelInfo.terminalLocationId"
                       value="taxi-arrivals"
                       (click)="navigate('taxi-arrivals')">
        {{ 'taxi-arrivals' | translate }}
    </mat-button-toggle>

    <mat-button-toggle *ngIf="showBills"
                        value="bill-histories"
                        (click)="navigate('bill-histories')">
        <app-payments-alert></app-payments-alert>
    </mat-button-toggle>

    <mat-button-toggle value="co2Shop" (click)="navigate('co2Shop')">
        {{ 'coinShop' | translate }}
    </mat-button-toggle>

    <mat-button-toggle value="co2Report" (click)="navigate('co2Report')">
        {{ 'CO2ReportTopmenu' | translate }}
    </mat-button-toggle>

</mat-button-toggle-group>