import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { TaxiService } from './taxi.service';
import { IBoughtProducts } from '../models/co2Products.interface';

@Injectable({
    providedIn: 'root'
})
export class CO2Service extends TaxiService {
    public getCO2Pdf(body: any): Observable<any> {
        return this.post(environment.getCO2Pdf, body, true);
    }

    public getCO2Products(): Observable<any> {
        return this.post(environment.getCO2Products, "", true);
    }

    public co2ProductBought(body: IBoughtProducts): Observable<any> {
        return this.post(environment.co2ProductBought, body, true);
    }

    public getMonthsWithRides(hotelId: string): Observable<any> {
        return this.http.get(this.api_url + environment.getGetMonthsWithRides.replace('{hotelId}', hotelId));
    }
}
