<div class="container">
    <app-station-header></app-station-header>

    <div class="payBody">
        <div *ngIf="!getRidePaymentInfoResponse; then loading; else loaded"></div>

        <ng-template #loading>
            <p>Loading...</p>
        </ng-template>

        <ng-template #loaded>
            <div class="from-name">
                <h3>{{ "from" | translate }}</h3>
                <h3>
                    <b>{{ getRidePaymentInfoResponse?.from?.name }}</b>
                </h3>
            </div>
            <div class="from-address">
                <p>{{ getRidePaymentInfoResponse?.from?.address }}</p>
            </div>
            <div class="spacer"></div>

            <div class="to-name">
                <h3>{{ "to" | translate }}</h3>
                <h3>
                    <b>
                        {{ getRidePaymentInfoResponse?.to?.name }}
                    </b>
                </h3>
            </div>
            <div class="to-address">
                <p>{{ getRidePaymentInfoResponse?.to?.address }}</p>
            </div>
            <div class="spacer"></div>

            <div class="pickup-time">
                <h3>{{ "selfServiceStation_dateTimeTitle" | translate }}</h3>
                <h3>
                    <b>
                        {{ pickupDateTimeString }}
                    </b>
                </h3>
            </div>
            <div class="spacer"></div>

            <!-- Ordered -->
            <div *ngIf="getRidePaymentInfoResponse?.paid">
                <div class="price">
                    <h3>{{ "taxiPrice" | translate }}</h3>
                    <h3>
                        <b> {{ getRidePaymentInfoResponse?.price }} {{ getRidePaymentInfoResponse?.currency }} </b>
                    </h3>
                </div>

                <div class="spacer"></div>

                <!-- Already payed -->
                <a *ngIf="getRidePaymentInfoResponse?.paid" mat-raised-button disabled class="paid">{{ "pay_paid" | translate }}</a>
            </div>

            <!-- Too late -->
            <a *ngIf="getRidePaymentInfoResponse?.tooLate" mat-raised-button disabled class="tooLate">{{ "pay_tooLate" | translate }}</a>

            <!-- Not paid -->
            <div class="payBtn" *ngIf="!getRidePaymentInfoResponse?.paid && !getRidePaymentInfoResponse?.tooLate">
                <a href="{{ getRidePaymentInfoResponse?.paymentLink }}" mat-raised-button color="primary">
                    <div class="priceText">{{ priceText }}</div>
                </a>
            </div>
        </ng-template>
    </div>
</div>
