<div class="gfg">
    <img id="screensaver" src="/assets/images/Screensaver.png"/>
    
    <div class="top-txt-box">
        <img id="assistant" src="/assets/images/assistant.png">
        <div class="top-txt"> {{ getTopText() }}</div>
        <img *ngIf="hotelLogoSrc" id="hotelLogoSrc" [src]=hotelLogoSrc>
        <div *ngIf="!hotelLogoSrc" class="top-txt hotel-name" >{{ hotelName }}</div>
        <div class="top-txt"> {{'selfServiceStation_mobile_secondText' | translate }}</div>
    </div>

    <div class="button-box">
        <div class="button-row">
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(1)" color="primary">{{ 'selfServiceStation_orderTaxiNow' | translate }}</button>
            <button mat-flat-button class="continue-button bottom-margin" (click)="continue(0)" color="primary" style="margin-bottom: 10%;">{{ 'selfServiceStation_orderTaxiFixedPrice' | translate }}</button>
            <button mat-flat-button class="continue-button" (click)="continue(3)" color="primary"> {{ 'selfServiceStation_orderTaxiHotel' | translate }}</button>
            <button *ngIf="showLocationOrAiport()" mat-flat-button class="continue-button" (click)="continue(4)" color="primary"> {{ getLocationTranslation(0) }}</button>
            <button *ngIf="!showLocationOrAiport()" mat-flat-button class="continue-button" (click)="continue(2)" color="primary">{{ 'selfServiceStation_orderTaxiAirport' | translate }}</button>
        </div>
    </div>

    <div class="bottom-element">
        <img id="logo" [src]=logoSrc />
    </div>
</div>
