import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-news-popup',
  templateUrl: './news-popup.component.html',
  styleUrls: ['./news-popup.component.scss']
})
export class NewsPopupComponent {
  constructor(private dialogRef: MatDialogRef<NewsPopupComponent>) { }

  public click(): void {
    this.dialogRef.close();
  }
}
