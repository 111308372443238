import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { AreYouSurePopupComponent } from '../are-you-sure-popup/are-you-sure-popup.component';

@Component({
  selector: 'app-pay-in-taxi-popup',
  templateUrl: './pay-in-taxi-popup.component.html',
  styleUrls: ['./pay-in-taxi-popup.component.scss']
})
export class PayInTaxiPopupComponent implements OnInit, OnDestroy {
  constructor(private dialogRef: MatDialogRef<PayInTaxiPopupComponent>, public dialog: MatDialog, public translateService: TranslateService) {
  }

  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

  public continuePayAtTerminal(): void {
    //close the dialog if the user wants to pay at the terminal 
    //and then return false because the user "did not" want to pay in taxi

    this.dialogRef.close(false);
  }

  public openAreYouSureDialog(): void {
    const areYouSureYouWannaPayInTaxiDescription = this.translateService.instant('payInTaxiPopup_areYouSure_title');

    const areYouSureDialog = this.dialog.open(AreYouSurePopupComponent, {
      data: {
        description: areYouSureYouWannaPayInTaxiDescription
      },
      disableClose: true
    }
    );

    areYouSureDialog.afterClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(true);
      }
    });
  }

  public cancelBooking(): void {
    //This refreshes the page which also clears the terminal
    window.location.reload();
  }
}
