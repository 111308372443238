import { Component } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-cancelled-rides-applytobill-remove',
  templateUrl: './cancelled-rides-applytobill-remove.component.html',
  styleUrls: ['./cancelled-rides-applytobill-remove.component.scss']
})

export class CancelledRidesApplytobillRemoveComponent {
  private hotelId?: string;
  private accessToken?: string;

  rides: any;

  updateTimer?: number;

  loading!: HTMLIonLoadingElement;

  show: boolean = false;

  constructor(private getTaxiService: GetTaxiService, parameterService: ParameterService, private alertController: AlertController, private translateService: TranslateService, public loadingController: LoadingController) {
    parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;

      if (this.hotelId !== undefined && this.accessToken !== undefined)
        this.update();
    });
  }

  update() {
    clearTimeout(this.updateTimer);

    this.getTaxiService.getCancelledRidesForApplyToBill(this.hotelId!)
      .subscribe(async data => {
        if (data.length > 0)
          this.show = true
        else
          this.show = false

        this.rides = data;

        this.updateTimer = window.setTimeout(() => this.update(), 300000);
      });
  }

  removeRideButtonClick(ride: any) {
    this.removeRide(ride);
    this.update();
  }

  async removeRide(ride: any) {
    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      rideId: ride.rideId
    };

    await this.presentLoading();

    this.getTaxiService.deleteRide(body).subscribe(async data => {
      await this.hideLoading();
    }, async (error) => {
      await this.hideLoading();
      const alert = await this.alertController.create({
        header: 'Error Occurred',
        subHeader: this.translateService.instant('500errorMsg'),
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ],
        backdropDismiss: false
      });
      await alert.present();
    })
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}