<div class="container">
    <mat-dialog-content class="content">
        <h1 class="title">{{ "newsTitle" | translate }}</h1>
        <p class="text">{{ "news" | translate }}</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <div class="action-buttons">
            <button class="button" (click)="click()">Ok</button>
        </div>
    </mat-dialog-actions>
</div>
