import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SaveTaxiService } from '../../services/save-taxi.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { AirportDirection, PriceService } from 'src/app/services/price.service';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { Subscription } from 'rxjs';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { ValidationTools } from 'src/tools/ValidationTools';
import { LocalStorageTools } from 'src/tools/LocalStorageTools';
import { ReceptionistInitialsService } from 'src/app/services/receptionist-initials.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-airport',
  templateUrl: './airport.component.html',
  styleUrls: ['./airport.component.scss']
})
export class AirportComponent implements OnInit, OnDestroy {

  availableOptions: string[] = [];

  step = 5 / 60;
  formatLabel(value: number | null) {
    if (!value) {
      return '00:00';
    }
    if (value === 0) {
      return '00:00'
    }
    if (value === 24) {
      return '23:59'
    }

    let decimalPart = +value.toString().replace(/^[^\.]+/, '0');
    let mm = decimalPart * 60;
    mm = Math.round(mm)
    var mmPart = mm.toString().length == 1 ? "0" + mm.toString() : mm.toString();
    if (mmPart == '60') {
      mmPart = '00'
    }
    var hhPart = '00';
    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split(".");
      if (strArr[0].length == 1) {
        strArr[0] = "0" + strArr[0];
      }
      hhPart = strArr[0];
      //console.log(strArr);
    }

    return hhPart + ":" + mmPart;
  }

  showOnCheck: any;
  isShow($event: any) {
    this.showOnCheck = $event.value;
  }

  showOnLarge: boolean = false;
  disableStation: boolean = false;
  disableLarge: boolean = false;

  updateRoomNumberAndGuestNameRequirements() {
    if ((this.paymentOption!.value & PaymentOption.ApplyToBill) == PaymentOption.ApplyToBill || this.hotelService.nameOrRoomNumberRequiredToTheAirport.value) {
      if (this.roomNo && this.guestName) {
        this.roomNo?.setValidators([this.tools.oneOfTwoFieldsRequired(this.guestName)]);
        this.guestName?.setValidators([this.tools.oneOfTwoFieldsRequired(this.roomNo)])
      }
    }
    else {
      this.roomNo?.clearValidators();
      this.guestName?.clearValidators();
    }

    this.roomNo!.updateValueAndValidity();
    this.guestName!.updateValueAndValidity();
  }

  isShowShare($event: any) {
    this.showOnLarge = this.fromAirport.value.largeTaxi;

    if ((this.paymentOption!.value & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi || this.fromAirport.value.largeTaxi)
      this.shareTaxi?.disable();
    else
      this.shareTaxi?.enable();

    this.priceService.setIsLargeTaxi(this.fromAirport.value.largeTaxi);

    this.updateRoomNumberAndGuestNameRequirements();

    var phoneNumber = this.phoneNo!;
    if (this.fromAirport.value.largeTaxi || this.fromAirport.value.stationCar)
      phoneNumber.setValidators([Validators.required, Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]);
    else
      phoneNumber.setValidators([Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]);
    phoneNumber.updateValueAndValidity();
    if (this.fromAirport.value.largeTaxi) {
      this.stationCar?.disable();
    }
    else if (this.fromAirport.value.stationCar) {
      this.largeTaxi?.disable();
    }
    if (!this.fromAirport.value.largeTaxi) {
      this.stationCar?.enable();
    }
    if (!this.fromAirport.value.stationCar) {
      this.largeTaxi?.enable();
    }
  }

  hotelId: any;
  airportId: any;
  accessToken: any;
  selectedAirportId?: string;
  loading!: HTMLIonLoadingElement;
  messageShow: boolean = false;
  sharedRideSavings?: number;
  largeTaxiPrice?: number;
  taxiCanShareTaxi: boolean;
  taxiCanShareTaxiSubscription: Subscription;
  hotelSharesTaxi: boolean;
  hotelSharesTaxiSubscription: Subscription;
  currency = '';

  public fromAirport: FormGroup;

  defaultCommentToTaxiDriverSubscription?: Subscription;
  currencySubscription?: Subscription;

  numberOfCarsSelectionOptions: number[] = [1, 2, 3, 4];

  constructor(private storageTools: LocalStorageTools, private fb: FormBuilder, private alertController: AlertController, public translate: TranslateService,
    private getTaxiService: GetTaxiService, private parameterService: ParameterService,
    private saveTaxiService: SaveTaxiService, public loadingController: LoadingController, private priceService: PriceService, private taxiConfigurationService: TaxiConfigurationService,
    private tools: ValidationTools, public receptionistService: ReceptionistInitialsService, private hotelService: HotelService) {

    this.availableOptions = this.taxiConfigurationService.taxiAvailableOptionValues;

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;

      let body = {
        hotelId: this.hotelId,
        airportId: this.airportId,
        accessToken: this.accessToken
      }

      this.getTaxiService.getTaxiToAirport(body).subscribe(data => {
        this.sharedRideSavings = data.sharedRideSavings;
        this.largeTaxiPrice = data.largeTaxiPrice;
      }, async (error) => { });

      priceService.setAirportDirection(AirportDirection.toAirport);
      priceService.setIsLargeTaxi(false);
    });
    this.fromAirport = fb.group({
      roomNo: [null],
      guestName: [null],
      phoneNo: [null, [Validators.minLength(8), Validators.pattern(/^(\+([0-9]{8,16}))$/)]],
      numberOfCars: ['1', [Validators.min(1), Validators.max(25)]],
      largeTaxi: [false],
      stationCar: [false],
      animal: [false],
      bike: [false],
      electricCar: [false],
      numberOfGuests: [4],
      commentToDriver: [null],
      shareTaxi: [null],
      luggage: [1],
      pickupTime: [new Date(), Validators.required],
      paymentOption: [PaymentOption.ApplyToBill, Validators.required],
      taxiPrice: [0],
      email: [null, [Validators.email]]
    });

    this.taxiCanShareTaxi = taxiConfigurationService.canShareTaxiValue;
    if (!this.taxiCanShareTaxi)
      this.shareTaxi!.setValue(false);

    this.taxiCanShareTaxiSubscription = taxiConfigurationService.canShareTaxi.subscribe(v => {
      this.taxiCanShareTaxi = v;
      if (!this.taxiCanShareTaxi)
        this.shareTaxi!.setValue(false);
    });

    this.hotelSharesTaxi = hotelService.sharingTaxiesValue;
    if (!this.hotelSharesTaxi)
      this.shareTaxi!.setValue(false);

    this.hotelSharesTaxiSubscription = hotelService.sharingTaxies.subscribe(v => {
      this.hotelSharesTaxi = v;
      if (!this.hotelSharesTaxi)
        this.shareTaxi!.setValue(false);
    });

    this.paymentOption!.valueChanges.subscribe(value => this.updateRoomNumberAndGuestNameRequirements());

    this.commentToDriver!.setValue(hotelService.defaultCommentToTaxiDriverValue);
    this.defaultCommentToTaxiDriverSubscription = hotelService.defaultCommentToTaxiDriver.subscribe(comment => this.commentToDriver!.setValue(comment));
    this.currencySubscription = hotelService.currency.subscribe(currency => this.currency = currency);
  }

  paidByGuest() {
    return (this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill ||
      (this.paymentOption!.value & PaymentOption.Prepay) === PaymentOption.Prepay;
  }

  applyToBill() {
    var payByBill = (this.paymentOption!.value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill;

    if (!payByBill) {
      this.shareTaxi?.setValue(false);
      this.isShow({ value: false });
    }

    return payByBill;
  }

  ngOnDestroy(): void {
    if (this.taxiCanShareTaxiSubscription)
      this.taxiCanShareTaxiSubscription.unsubscribe();

    if (this.hotelSharesTaxiSubscription)
      this.hotelSharesTaxiSubscription.unsubscribe();

    if (this.defaultCommentToTaxiDriverSubscription)
      this.defaultCommentToTaxiDriverSubscription.unsubscribe();

    if (this.currencySubscription)
      this.currencySubscription.unsubscribe();
  }

  hotelPaymentChanged(hotelPayment: boolean) {
    this.shareTaxi!.setValue(false);

    if (hotelPayment)
      this.paymentOption!.setValue(PaymentOption.HotelPays);
    else
      this.paymentOption!.setValue(PaymentOption.PayInTaxi);
  }

  get roomNo() {
    return this.fromAirport.get('roomNo');
  }
  get numberOfCars() {
    return this.fromAirport.get('numberOfCars');
  }
  get guestName() {
    return this.fromAirport.get("guestName")
  }
  get phoneNo() {
    return this.fromAirport.get('phoneNo');
  }
  get commentToDriver() {
    return this.fromAirport.get('commentToDriver');
  }
  get largeTaxi() {
    return this.fromAirport.get('largeTaxi');
  }
  get shareTaxi() {
    return this.fromAirport.get('shareTaxi');
  }
  get animal() {
    return this.fromAirport.get('animal');
  }
  get bike() {
    return this.fromAirport.get('bike');
  }
  get electricCar() {
    return this.fromAirport.get('electricCar');
  }
  get pickupTime() {
    return this.fromAirport.get('pickupTime');
  }
  get numberOfGuests() {
    return this.fromAirport.get('numberOfGuests');
  }
  get stationCar() {
    return this.fromAirport.get('stationCar');
  }
  get luggageCount() {
    return this.fromAirport.get('luggage');
  }
  get paymentOption() {
    return this.fromAirport.get('paymentOption');
  }
  get taxiPrice() {
    return this.fromAirport.get('taxiPrice');
  }
  get email() {
    return this.fromAirport.get('email');
  }

  phoneSelect(event: any): void {
    this.phoneNo?.setValue(event?.tel);
  }

  ngOnInit(): void {
    this.priceService.taxiPrice.subscribe(p => {
      this.taxiPrice?.setValue(p?.customerPrice);
    });
    this.getTaxiService.getAirportInfo(this.airportId).subscribe(data => {
      this.priceService.setTo(data, true, AirportDirection.toAirport);
    });
  }

  formSubmit: boolean = false;

  async order() {
    await this.presentLoading();

    this.formSubmit = true;

    // what a messs
    this.fromAirport.controls["roomNo"].updateValueAndValidity();
    this.fromAirport.controls["guestName"].updateValueAndValidity();
    this.paymentOption!.updateValueAndValidity();

    if (!this.fromAirport.valid) {
      await this.hideLoading()
      return;
    }

    if (!this.receptionistService.isConfigurationValid()) {
      await this.hideLoading();
      return;
    }

    let body = {
      hotelId: this.hotelId,
      accessToken: this.accessToken,
      comment: this.commentToDriver?.value,
      airportId: this.selectedAirportId,
      receptionistInitials: this.receptionistService.getInitials(),
      phoneNumber: this.fromAirport.value.phoneNo,
      roomNumber: this.fromAirport.value.roomNo,
      numberOfCars: Number(this.numberOfCars?.value),
      guestName: this.fromAirport.value.guestName,
      departureTime: this.pickupTime!.value.toISOString(),
      shareRide: this.fromAirport.value.shareTaxi,
      luggageCount: this.fromAirport.value.luggage,
      largeTaxi: this.fromAirport.value.largeTaxi,
      // We only show the numberOfGuests option to the user for large taxies
      numberOfGuests: this.fromAirport.value.largeTaxi ? this.fromAirport.value.numberOfGuests : undefined,
      stationCar: this.fromAirport.value.stationCar,
      animal: this.animal?.value,
      bike: this.bike?.value,
      electricCar: this.electricCar?.value,
      email: this.fromAirport.value.email,
      paymentOption: this.paymentOption!.value ?? PaymentOption.ApplyToBill,
    }
    this.saveTaxiService.saveTaxiToAirport(body).subscribe(async (data) => {
      await this.hideLoading()
      // await alert.present();
      this.messageShow = true
      setTimeout(async () => {
        this.phoneNo!.setValue(null);
        this.roomNo!.setValue(null);
        this.guestName!.setValue(null);
        this.largeTaxi!.setValue(false);
        this.stationCar!.setValue(false);
        this.animal?.setValue(false);
        this.commentToDriver?.setValue(null);
        this.bike?.setValue(false);
        this.electricCar?.setValue(false);
        this.luggageCount!.setValue(1);
        this.numberOfGuests!.setValue(4);
        this.numberOfCars?.setValue(1);
        this.shareTaxi!.setValue(null);
        this.shareTaxi?.enable();
        this.stationCar?.enable();
        this.largeTaxi?.enable();
        this.pickupTime?.setValue(new Date());
        this.formSubmit = false;
        this.messageShow = false;
        this.paymentOption!.setValue(PaymentOption.None);
        this.paymentOption!.setValidators([Validators.required]);
      }, 5000);
    }, async (error) => {
      await this.hideLoading();
      console.log(error);
      if (error.status == 400) {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
          cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
      else {
        const errorAlert = await this.alertController.create({
          header: this.translate.instant('error'),
          subHeader: this.translate.instant('500errorMsg'),
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ]
        });
        errorAlert.present();
      }
    })
  }

  airportSelected(airportId: string) {
    this.selectedAirportId = airportId;

    this.priceService.setAirport(airportId);
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  onChangePrice(): void {
    this.priceService.setManualPrice(this.fromAirport.value.taxiPrice);
  }

  public displayOption(optionName: string): boolean {
    return this.availableOptions === null || this.availableOptions.some(option => option.toUpperCase() === optionName.toUpperCase());
  }

  public disableOptions(): boolean {
    return this.availableOptions?.some(x => x.toUpperCase() === 'DISABLE');
  }

  onChangeDate(): void {
    this.priceService.setSelectedDate(this.pickupTime?.value);
  }
}
