import {IAddress} from '../models/address.interface';

export class AddressMapperService {
    public static mapGoogleAddressComponentsToIAddress(data: any): IAddress {

        return {
            houseNo: +(data.address_components.find((x: { types: string[]; }) => x.types.includes('street_number'))?.long_name),
            streetName: data.address_components.find((x: { types: string[]; }) => x.types.includes('route'))?.long_name,
            city: data.address_components.find((x: { types: string[]; }) => x.types.includes('postal_town') || x.types.includes('locality'))?.long_name,
            zipCode: data.address_components.find((x: { types: string[]; }) => x.types.includes('postal_code'))?.long_name,
            fullAddress: data.formatted_address,
        };
    }
}
