import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { TaxiConfigurationService } from 'src/app/services/taxi-configuration.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { AlertController, LoadingController } from '@ionic/angular';
import { TaxiStatus, RideStatus, RideType } from 'src/app/models/enums';
import { IRide } from 'src/app/services/models/ride.model';

@Component({
  selector: 'app-upcoming-rides',
  templateUrl: './upcoming-rides.component.html',
  styleUrls: ['./upcoming-rides.component.scss']
})
export class UpcomingRidesComponent implements OnInit, OnDestroy {

  @Input() anonymousView: boolean = false;
  @Input() showCancel: boolean = false;

  upcomingRidesSubscription: any;
  arrivalsOfTheDaySubscription: any;
  upcomingRidesTimer?: number;
  taxiHotelNumberSubscription: Subscription = new Subscription();
  onlyLatest: boolean = false;
  subscr$ = new Subject();
  loading!: HTMLIonLoadingElement;

  isInitialized = false;
  rides: IRide[] = [];
  _date = new Date();

  inVainContactText: string = '';

  constructor(private getTaxiService: GetTaxiService, private translate: TranslateService, private taxiConfigurationService: TaxiConfigurationService, private parameterService: ParameterService, public loadingController: LoadingController, private alertController: AlertController) { }

  ngOnInit(): void {
    this.updateRides();

    const inVainContactTextUpdater = () => {
      this.inVainContactText = " " + this.translate.instant('upcoming-rides-in-vain-contact')
        .replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue)
        .replace('{taxiName}', this.taxiConfigurationService.taxiNameValue);
    };
    inVainContactTextUpdater();
    this.taxiConfigurationService.taxiHotelNumber.subscribe(taxiHotelNumber => {
      inVainContactTextUpdater();
    });
  }

  updateRides() {
    const arrivalsOfTheDaySubscription = this.getTaxiService.getArrivalsOfTheDay(this._date);

    const upcomingRidesSubscription = this.getTaxiService.getUpcomingRides();

    forkJoin([arrivalsOfTheDaySubscription, upcomingRidesSubscription]).pipe(takeUntil(this.subscr$)).subscribe((rides: Array<any>) => {
      this.isInitialized = true;

      this.rides = rides[1]
        .filter((r: any) =>
          r.rideType === RideType.Direct ||
          r.rideType === RideType.OtherRide ||
          r.rideType === RideType.ToAirport ||
          r.rideType === RideType.FixedPrice)
        .filter((r: any) =>
          r.taxiStatus === TaxiStatus.Ordered ||
          r.taxiStatus === TaxiStatus.OnItsWay ||
          r.taxiStatus === TaxiStatus.TaximeterStarted ||
          r.taxiStatus === TaxiStatus.InVain ||
          r.taxiStatus === TaxiStatus.NoShow);
      this.arrivalsOfTheDaySubscription = rides[0];
      //this.arrivalsOfTheDaySubscription = rides[1];
      const millisecondsPerMinute = 60000;
      var earliestPickupTime = new Date((<any>new Date()) - millisecondsPerMinute * 150);

      this.rides = this.rides.concat(rides[0].filter((r: any) =>
        !this.onlyLatest || new Date(r.pickupTime) >= earliestPickupTime)
        .filter((r: any) =>
          r.taxiStatus === TaxiStatus.Ordered ||
          r.taxiStatus === TaxiStatus.OnItsWay ||
          r.taxiStatus === TaxiStatus.TaximeterStarted ||
          r.taxiStatus === TaxiStatus.InVain ||
          r.taxiStatus === TaxiStatus.NoShow ||
          r.taxiStatus === TaxiStatus.Completed ||
          r.taxiStatus === TaxiStatus.NotOrderedYet ||
          (r.taxiStatus === TaxiStatus.Cancelled && r.rideStatus === RideStatus.CancelledBecauseTheFlightHasBeenDelayed)));

      this.rides.sort((a, b) => a.pickupTime > b.pickupTime ? 1 : -1);

      this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 30000);
    },
      error => {
        this.upcomingRidesTimer = window.setTimeout(() => this.updateRides(), 60000);
      });
  }

  ngOnDestroy(): void {
    this.subscr$.next();
    this.subscr$.complete();
    clearTimeout(this.upcomingRidesTimer);

    this.taxiHotelNumberSubscription.unsubscribe();
  }

  statusOnItsWay(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.OnItsWay;
  }

  statusTaximeterStarted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.TaximeterStarted;
  }

  statusInVain(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.InVain;
  }

  statusUnknown(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Unknown;
  }

  statusCancelled(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Cancelled;
  }

  statusNoShow(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.NoShow;
  }

  statusCompleted(ride: IRide) {
    return ride.taxiStatus === TaxiStatus.Completed;
  }

  async cancelRide(ride: IRide) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      let body = {
        id: ride.id
      }

      await this.presentLoading()

      this.getTaxiService.cancelRide(body).subscribe(async (data) => {
        await this.hideLoading()

        var textKey = this.translate.instant('backendStatusCodeTaxiCouldNotBeCancelled').replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue);
        if (data.taxiIsCancelled) {
          textKey = this.translate.instant('taxiHasBeenCancelled');
          ride.taxiStatus = TaxiStatus.Cancelled;
        }

        const alert = await this.alertController.create({
          header: this.translate.instant('taxiStatusHeader'),
          subHeader: textKey,
          cssClass: 'alertmessage',
          buttons: [
            {
              text: 'Okay',
              handler: () => {
              }
            }
          ],
          backdropDismiss: false
        });
        await alert.present();
      }, async (error) => {
        await this.hideLoading()
        if (error.status == 400) {
          const errorAlert = await this.alertController.create({
            header: this.translate.instant('error'),
            subHeader: this.translate.instant(`backendStatusCode${error.error.statusCode}`).replace('{taxiHotelNumber}', this.taxiConfigurationService.taxiHotelNumberValue).replace('{taxiName}', this.taxiConfigurationService.taxiNameValue),
            cssClass: !error.error ? 'alertmessage' : !error.error.statusCode ? 'alertmessage' : error.error.statusCode == "LargeTaxiNotAvailable" ? 'alertmessage2' : 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ]
          });
          errorAlert.present();
        }
        else {
          const alert = await this.alertController.create({
            header: "Error Occurred",
            subHeader: this.translate.instant('500errorMsg'),
            cssClass: 'alertmessage',
            buttons: [
              {
                text: 'Okay',
                handler: () => {
                }
              }
            ],
            backdropDismiss: false
          });
          await alert.present();
        }
      })
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}