import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GetRidePaymentInfoResponse } from 'src/app/models/pay/getRidePaymentInfoResponse';
import { LogService } from 'src/app/services/log.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { PayService } from 'src/app/services/pay.service';
import { PrepaidPopupComponent as PrepaidPopupComponent } from 'src/app/shared/components/pre-paid-popup/pre-paid-popup.component';
import { formattedDateTime } from 'src/app/utils/dateFormat';

@Component({
  selector: 'app-pay',
  templateUrl: './pay.component.html',
  styleUrls: ['./pay.component.scss']
})
export class PayComponent implements OnInit {

  getRidePaymentInfoResponse?: GetRidePaymentInfoResponse;
  orderingRide: boolean = false;
  get pickupDateTimeString() {
    if (this.getRidePaymentInfoResponse?.isAsap) return this.translate.instant("pay_asap");
    return formattedDateTime(new Date(this.getRidePaymentInfoResponse?.pickupTime!));
  }

  get priceText() {
    return this.translate.instant("pay_payTaxi").replace("{{price}}", this.getRidePaymentInfoResponse?.price!).replace("{{currency}}", this.getRidePaymentInfoResponse?.currency!);
  }

  constructor(private parameterService: ParameterService, private payService: PayService, private loadingController: LoadingController, private translate: TranslateService, private logger: LogService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const rideId = this.parameterService.parameters.value?.rideId;
    if (!rideId) return;

    this.populatePaymentInfo(rideId);
  }

  populatePaymentInfo(rideId: string): void {
    this.payService.getRidePaymentInfo(rideId).subscribe((response) => {
      this.getRidePaymentInfoResponse = response;

      if (response.tooLate) {
        this.logger.information("!!PAY MARKER!! Too late to order ride");
        return;
      }

      const checkoutId = this.parameterService.parameters.value?.checkoutId;

      const hasCheckoutIdAndNotPaid = checkoutId && !response.paid;

      if (hasCheckoutIdAndNotPaid) {
        this.confirmPaymentAndOrderRide(rideId, checkoutId!);
      }

      if (!checkoutId) {
        //If there is no checkoutId, it means that the user has clicked on the link without paying yet.
        this.payService.extendOrderTimeWhenClickingOnLink(rideId).subscribe(res => {
          this.logger.information("Successfully extended order time when clicking on link");
        }, err => {
          if (err.error == "Order time already extended") {
            this.logger.information("Order time already extended");
            return;
          }

          this.logger.error("Error extending order time when clicking on link", err);
        });
      }

    }, (error: HttpErrorResponse) => {

      if (error.status === 404) { //NOT FOUND
        alert("No ride found with this id");
      }
      else if (error.status === 400) { //BAD REQUEST
        alert("Invalid ride id");
      }
      else if (error.status === 500) { //INTERNAL SERVER ERROR
        alert("Internal server error");
      }
      else {
        this.logger.error("Unknown error from pay.component.ts", error);
        debugger
        alert("Unknown error");
      }
    });
  }

  private confirmPaymentAndOrderRide(rideId: string, checkoutId: string): void {
    this.presentLoading();

    this.payService.confirmPaymentAndOrderRide(rideId, checkoutId).subscribe((response) => {
      this.getRidePaymentInfoResponse!.paid = true;
      this.loading.dismiss();

      const dialogRef = this.dialog.open(PrepaidPopupComponent);

    }, (error: HttpErrorResponse) => {
      debugger
      alert("Error ordering ride");

      this.loading.dismiss();
    });
  }

  loading!: HTMLIonLoadingElement;

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }


}
