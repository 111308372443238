import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaxiService } from './taxi.service';
import { PaymentOption } from './hotel.service';

export enum AttributesToTaxi {
  None = 0,
  Animal = 1,
  Bike = 2,
  ElectricCar = 4,
  Carseat = 8,
  OneBoosterSeat = 16,
  TwoBoosterSeats = 32,
  ChildSeat = 64,
  EstateCar = 128,
  HybridCar = 256,
}

@Injectable({
  providedIn: 'root'
})
export class RideService {

  constructor(private taxiService: TaxiService) { }

  public getFixedComment(location: string, paymentOption: PaymentOption): Observable<any> {
    return this.taxiService.post(environment.getPrefixedComment, { location, paymentOption }, true);
  }

  public getLargeTaxiStatus(Id: string): Observable<any> {
    return this.taxiService.post(environment.getLargeTaxiStatus, { Id }, true);
  }

  public sendReceiptEmail(RideId: string, Email: string): Observable<any> {
    return this.taxiService.post(environment.sendReceiptEmail, { RideId: RideId, Email: Email }, true);
  }
}