<div class="container">
    <mat-dialog-content class="title">
            {{ "chooseComment" | translate }}
    </mat-dialog-content>
    <mat-dialog-actions class="action-buttons">
        <div *ngFor="let chooseAComment of chooseACommentList; let i = index;">
            <button class="commentButton" (click)="click(i)">
                {{chooseAComment.description}}
            </button>
        </div>
    </mat-dialog-actions>
</div>

