import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HotelService } from 'src/app/services/hotel.service';

@Component({
  selector: 'app-rush-hour-warning',
  templateUrl: './rush-hour-warning.component.html',
  styleUrls: ['./rush-hour-warning.component.scss']
})
export class RushHourWarningComponent implements OnInit {

  @Input() date: Date = new Date();

  text: string = '';

  constructor(private hotelService: HotelService, private translate: TranslateService) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ((this.hotelService.isRushHour1Active.value && this.date.getHours() >= 4 && this.date.getHours() < 9) ||
      (this.hotelService.isRushHour2Active.value && this.date.getHours() >= 11 && this.date.getHours() < 13) ||
      (this.hotelService.isRushHour3Active.value && this.date.getHours() >= 17 && this.date.getHours() < 19)) {
      var now = new Date();
      now.setSeconds(0);
      now.setMilliseconds(0);

      if (this.date.toISOString() === now.toISOString())
        this.text = this.translate.instant('delayWarningForTaxi');
      else
        this.text = this.translate.instant('delayWarningForPreorderedTaxi');
    }
    else
      this.text = '';
  }

}
