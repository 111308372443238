<div class="container">
    <table mat-table [dataSource]="hotelsMatTableDataSource">
        <ng-container matColumnDef="hotelName">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let hotel">{{ hotel.hotelName }}</td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let hotel">
                <button mat-button (click)="gotoHotelPlatform('hotel', hotel)">Hotel</button>
                <button mat-button (click)="gotoHotelPlatform('mobile', hotel)">Mobile</button>
                <button mat-button (click)="gotoHotelPlatform('self-service-station', hotel)">Self-service-station</button>
                <!--
                    <button mat-button (click)="getHotelData(hotel)">
                        <mat-icon>save_alt</mat-icon>
                    </button>
                    <button mat-button (click)="openUpdateHotelDialog()">
                        <mat-icon>insert_drive_file</mat-icon>
                    </button>
                -->
                <button mat-button (click)="openUpdateHotelSettingsDialog(hotel)">
                    <mat-icon>settings</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
