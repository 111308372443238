import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageTools } from "src/tools/LocalStorageTools";
import { HotelService } from "./hotel.service";

@Injectable({
    providedIn: 'root'
})
export class ReceptionistInitialsService {
    public initialsRequired: boolean = false;

    initials: BehaviorSubject<string> = new BehaviorSubject('');

    constructor(hotelService: HotelService, private storageTools: LocalStorageTools) {
        hotelService.receptionistInitialsRequired.subscribe(v => this.initialsRequired = v);
    }

    isInitialsRequired(): boolean {
        return this.initialsRequired;
    }

    isInitialsSet(): boolean {
        var initials = this.getInitials();;
        if (!initials || initials == '')
            return false;
        else
            return true;
    }

    setInitials(initals: string) {
        this.storageTools.SetReceptionistInitials(initals);
    }
    getInitials(): string {
        var initials = this.storageTools.GetReceptionistInitials() ?? '';

        this.initials.next(initials);

        return initials;
    }

    public isConfigurationValid(): boolean {
        if (!this.initialsRequired)
            return true;
        else
            return this.isInitialsSet();
    }
}