import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from 'src/app/services/admin.service';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { PageService } from 'src/app/services/page.service';
import { ParameterService } from 'src/app/services/parameter.service';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {
  loading!: HTMLIonLoadingElement;

  form: FormGroup;
  formSubmit = false;

  hotelId?: string;
  airportId?: string;
  accessToken?: string;

  constructor(fb: FormBuilder, private adminService: AdminService, private translate: TranslateService, private getTaxiService: GetTaxiService, private router: Router, private parameterService: ParameterService, private route: ActivatedRoute, private loadingController: LoadingController, private pageService: PageService) {
    this.form = fb.group({
      'adminPassword': ["", Validators.required]
    });

    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
    });
  }

  ngOnInit(): void {
    this.pageService.currentHeaderTranslationKey = 'setup-login';
  }

  get adminPassword() { return this.form.get('adminPassword'); }

  async submit() {
    this.formSubmit = true;

    await this.presentLoading();

    if (!this.form.valid) {
      await this.hideLoading();
      return;
    }

    this.formSubmit = false;

    this.getTaxiService.verifyAdmin(this.adminPassword!.value).subscribe(async result => {
      this.adminService.adminPassword = this.adminPassword!.value;

      await this.hideLoading();

      this.router.navigate(['setup', 'prices'], { queryParams: this.route.snapshot.queryParams });
    }, async error => {
      await this.hideLoading();

      this.router.navigate(['hotel'], { queryParams: this.route.snapshot.queryParams });
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

  navigateToTaxiOverview() {
    this.router.navigate(['taxi-overview'], {
      queryParams: {
        'hotelId': this.hotelId,
        'accessToken': this.accessToken,
        'airportId': this.airportId
      }
    });
  }
}
