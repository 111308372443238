<div class="orderTaxi">
  <div class="overlay">
    <div class="container">
      <img src="assets/images/logo_white.svg"
           alt=""
           class="logo">
      <div class="clear"></div>
      <button mat-raised-button
              (click)="goToSplash()"><span>{{'orderTaxi' | translate}}</span></button>
      <ul class="flag">
        <li>
          <button mat-button
                  matTooltip="English"
                  id="button_en"
                  (click)="useLanguage('en')">
            <img src="assets/images/Translation_Flags/flag-icn-en.png" 
                 alt="">
          </button>
        </li>
        <li>
          <button mat-button
                  matTooltip="Danish"
                  id="button_da"
                  (click)="useLanguage('da')">
            <img src="assets/images/Translation_Flags/flag-icn-da.png"
                 alt="">
          </button>
        </li>
        <li>
          <button mat-button
                  matTooltip="German"
                  id="button_de"
                  (click)="useLanguage('de')">
            <img src="assets/images/Translation_Flags/flag-icn-de.png"
                 alt="">
          </button>
        </li>
        <li>
          <button mat-button
                  matTooltip="Norwegian"
                  id="button_no"
                  (click)="useLanguage('no')">
            <img src="assets/images/Translation_Flags/flag-icn-no.png"
                 alt="">
          </button>
        </li>
        <li>
          <button mat-button
                  matTooltip="Sweden"
                  id="button_se"
                  (click)="useLanguage('se')">
            <img src="assets/images/Translation_Flags/flag-icn-se.png"
                 alt="">
          </button>
        </li>
        <li>
          <button mat-button
                  matTooltip="Finish"
                  id="button_fi"
                  (click)="useLanguage('fi')">
            <img src="assets/images/Translation_Flags/flag-icn-fi.png"
                 alt="">
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>
<!-- (click)="useLanguage('en')" -->