import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CO2Service } from 'src/app/services/co2.service';
import { ParameterService } from 'src/app/services/parameter.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-co2report',
  templateUrl: './co2report.component.html',
  styleUrls: ['./co2report.component.scss']
})
export class Co2reportComponent implements OnInit {
  lang: any;
  hotelId: any;
  accessToken: any;
  loading!: HTMLIonLoadingElement;
  monthlyRides: { month: number, year: number }[] = [];


  constructor(private parameterService: ParameterService, private translateService: TranslateService, public loadingController: LoadingController, private co2Service: CO2Service, private alertController: AlertController) {
    this.lang = localStorage.getItem("lang");
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.accessToken = params.accessToken;
    });
  }

  ngOnInit(): void {
    this.getMonthlyRides();
  }

  async getMonthlyRides() {
    await this.presentLoading()
    this.co2Service.getMonthsWithRides(this.hotelId).subscribe(async (data: any) => {
      this.monthlyRides = data;
      await this.hideLoading();
    }, async (error) => {
      await this.hideLoading();
      const errorAlert = await this.alertController.create({
        header: this.translateService.instant('error'),
        subHeader: this.translateService.instant('500errorMsg'),
        cssClass: 'alertmessage',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
            }
          }
        ]
      });
      errorAlert.present();
    }
    );
  }

  getMonthName(monthNumber: number): string {
    if (monthNumber == 0)
      return this.translateService.instant('yearlyReport');

    const date = new Date();
    date.setMonth(monthNumber - 1);

    var month = date.toLocaleString(this.lang, { month: 'long' });
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  getCO2PdfLink(date: { month: number, year: number }) {
    var azureCode = environment.getCO2Pdf.split('code=')[1];
    var year = date.year;
    var month = date.month;
    var url = `${environment.api}/GetCO2PDF/${this.hotelId}/${year}/${month}?code=${azureCode}`
    return url;
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translateService.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }
}
