import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HotelResponseMapperService } from 'src/app/mappers/hotel-response-mapper.service';
import { GetTaxiService } from 'src/app/services/get-taxi.service';
import { TaxiService } from 'src/app/services/taxi.service';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {

  slideItem = 'one';
  hotelId: any;
  airportId: any;
  flightDate: any;
  bookingNumber: any;
  guestName: any;
  constructor(private router: Router, private translate: TranslateService, private route: ActivatedRoute, private getTaxiService: GetTaxiService) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.hotelId = params['hotelId'];
      this.airportId = params['airportId'];
      this.flightDate = params['flightDate'];
      this.bookingNumber = params['bookingNumber'];
      this.guestName = params['guestName'];
      console.log(this.hotelId, this.airportId);

      let lang = localStorage.getItem("lang");

      if (this.hotelId) {
        this.getTaxiService.getHotelInfo(params.hotelId)
          .subscribe(data => {
            let hotelInfo = HotelResponseMapperService.mapHotelInfoToIHotelInfo(data);

            lang = hotelInfo.language;
            if (lang == 'se')
              this.translate.setDefaultLang('en');
            else
              this.translate.setDefaultLang(lang);
            localStorage.setItem("lang", 'en');
          });
      }

      if (!lang) {
        this.translate.setDefaultLang('en');
        localStorage.setItem("lang", 'en');
      }
    });
  }

  open() {
    // this.router.navigateByUrl('/order-taxi')
    this.router.navigateByUrl('/order-taxi?hotelId=' + this.hotelId +
      '&airportId=' + this.airportId + '&flightDate=' + (this.flightDate ?? '') + '&bookingNumber=' + (this.bookingNumber ?? '')
      + '&guestName=' + (this.guestName ?? ''));
  }

}
