import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'taxiapp';
  showSplash = true;
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'da', 'no', 'de', 'se', 'fi']);
    let lang = localStorage.getItem("lang");
    if (lang != null)
      translate.setDefaultLang(lang);
    else {
      translate.setDefaultLang('en');
    }
  }
}
