import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { HotelService, PaymentOption } from 'src/app/services/hotel.service';
import { PriceService } from 'src/app/services/price.service';
import { FixedPricePaymentinTaxi } from './../../services/hotel.service';
import { GetTaxiService } from '../../services/get-taxi.service';
import { takeUntil } from 'rxjs/operators';
import { RideType } from 'src/app/models/enums';

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PaymentOptionComponent),
      multi: true
    }
  ]
})
export class PaymentOptionComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() showErrors = false;

  @Input() fromAirport = false;
  @Input() toAirport = false;
  @Input() payInTaxiAllowed: boolean | undefined = true;

  @Input() hotelIntegration = false;

  optionsAvailable = false;
  readOnly = false;
  isFixedPriceDestination = false;
  subscr$ = new Subject();

  allRidesMustBePaidInTheTaxi: boolean = false;
  allRidesMustBePaidInTheTaxiSubscription?: Subscription;

  displayTreeIcon = false;

  fixedPricePaymentinTaxi: FixedPricePaymentinTaxi = FixedPricePaymentinTaxi.NotAllowed;
  fixedPricePaymentinTaxiSubscription?: Subscription;

  _isLargeTaxi = false;
  get isLargeTaxi() {
    return this._isLargeTaxi;
  }
  @Input()
  set isLargeTaxi(value: boolean) {
    this._isLargeTaxi = value;
    this.calculateValues();
  }

  _paymentOptionFilterMethod: (_: PaymentOption) => PaymentOption = (x) => x;

  @Input()
  set paymentOptionFilterMethod(method: (_: PaymentOption) => PaymentOption) {
    this._paymentOptionFilterMethod = method;
  };

  filteredPaymentOption(option: PaymentOption): PaymentOption {
    return this._paymentOptionFilterMethod(option);
  }

  calculateValues(): void {
    if (this.allRidesMustBePaidInTheTaxi) {
      this.readOnly = true;
      if (!this.selectedValue || (this.selectedValue & PaymentOption.HotelPays) !== PaymentOption.HotelPays)
        this.selectedValue = PaymentOption.PayInTaxi;

      return;
    }

    if (this.selectedValue && (this.selectedValue & PaymentOption.HotelPays) === PaymentOption.HotelPays)
      return;

    if (this.isFixedPriceDestination)
      this.readOnly = false;
    else {
      this.selectedValue = PaymentOption.PayInTaxi;
      this.readOnly = true;
    }
    // We want to be able to choose payment option for To Airport and From Airport rides
    if (this.toAirport || this.fromAirport) {
      this.readOnly = false;
    }
  }

  _selectedValue?: string = undefined;

  _paymentOptions: PaymentOption = PaymentOption.None;

  onChange: any = () => { };
  onTouched: any = () => { };

  get paymentOptions() {
    return this._paymentOptions;
  }

  set paymentOptions(value: PaymentOption) {
    this._paymentOptions = this.filteredPaymentOption(value);

    this.optionsAvailable =
      ((this._paymentOptions & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill ||
        (this._paymentOptions & PaymentOption.Prepay) === PaymentOption.Prepay) &&
      !((this._paymentOptions & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal);
  }

  get selectedValue(): PaymentOption | undefined {
    if (this._selectedValue === 'applyToBill')
      return PaymentOption.ApplyToBill;

    if (this._selectedValue === 'prepay')
      return PaymentOption.Prepay;

    if (this._selectedValue === 'payInTaxi')
      return PaymentOption.PayInTaxi;

    if (this._selectedValue === 'hotelPays')
      return PaymentOption.HotelPays;

    if (this._selectedValue === 'payByTerminal')
      return PaymentOption.PayByTerminal;

    return undefined;
  }

  set selectedValue(value: PaymentOption | undefined) {
    var previousValue = this._selectedValue;

    if (value === undefined) {
      if (!this.isPayInTaxiAvailable())
        this.selectedValue = PaymentOption.ApplyToBill;
      else
        this._selectedValue = 'prepay';

      if (this._selectedValue != previousValue)
        this.onChange(this.selectedValue);

      return;
    }

    if ((value & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill) {
      this._selectedValue = 'applyToBill';
    }
    else if ((value & PaymentOption.Prepay) === PaymentOption.Prepay)
      this._selectedValue = 'prepay';
    else if ((value & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi) {
      if (!this.isPayInTaxiAvailable()) {
        this._selectedValue = 'applyToBill';
        value = PaymentOption.ApplyToBill;
      }
      else
        this._selectedValue = 'payInTaxi';
    }
    else if ((value & PaymentOption.HotelPays) === PaymentOption.HotelPays)
      this._selectedValue = 'hotelPays';
    else if (value === null) { }
    else
      this._selectedValue = undefined;

    if (this.isPayByTerminalAvailable()) {
      this._selectedValue = 'payByTerminal';
    }

    if (this._selectedValue != previousValue)
      this.onChange(this.selectedValue);
  }

  taxiPriceSubscription?: Subscription;

  constructor(private hotelService: HotelService, private priceService: PriceService, public taxiService: GetTaxiService) { }

  ngOnInit(): void {
    this.taxiService.hotelInfo$.pipe(takeUntil(this.subscr$)).subscribe((data) => {
      this.displayTreeIcon = data.displayTreeCounter;
      localStorage.setItem('hotelInfo', JSON.stringify(data));
    });
    this.selectedValue = PaymentOption.PayInTaxi;

    this.paymentOptions = this.hotelService.paymentOptionsValue;
    this.hotelService.paymentOptions.subscribe(options => {
      this.paymentOptions = this.hotelService.paymentOptionsValue;
    });

    this.allRidesMustBePaidInTheTaxi = this.hotelService.allRidesMustBePaidInTheTaxiValue;
    this.allRidesMustBePaidInTheTaxiSubscription = this.hotelService.allRidesMustBePaidInTheTaxi.subscribe(v => {
      this.allRidesMustBePaidInTheTaxi = v;
      this.calculateValues();
    });

    this.fixedPricePaymentinTaxi = this.hotelService.fixedPricePaymentinTaxiValue;
    this.fixedPricePaymentinTaxiSubscription = this.hotelService.fixedPricePaymentinTaxi.subscribe(v => {
      this.fixedPricePaymentinTaxi = v;

      this.calculateValues();
    });

    this.taxiPriceSubscription = this.priceService.rideType.subscribe(rideType => {
      this.isFixedPriceDestination = rideType === RideType.FixedPrice && !!this.priceService.taxiPriceValue?.customerPrice;
      this.calculateValues();
    });

    this.calculateValues();
    this.allRidesMustBePaidInTheTaxiSubscription = this.hotelService.allPaymentMethodShouldDisabled.subscribe(v => {
      this.setDisabledPaymentOption(v);
    });
  }

  selected(value: any) {
    this._selectedValue = value;
    this.onChange(this.selectedValue);
  }

  ngOnDestroy(): void {
    this.taxiPriceSubscription?.unsubscribe();
    this.allRidesMustBePaidInTheTaxiSubscription?.unsubscribe();
    this.fixedPricePaymentinTaxiSubscription?.unsubscribe();
    this.subscr$.next();
    this.subscr$.complete();
  }

  isApplyToBillAvailable() {
    return (this.paymentOptions & PaymentOption.ApplyToBill) === PaymentOption.ApplyToBill && !((this.paymentOptions & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal);
  }

  isPayInTaxiAvailable() {
    return ((this.paymentOptions & PaymentOption.PayInTaxi) === PaymentOption.PayInTaxi && this.payInTaxiAllowed == true);
  }

  isPrepayAvailable() {
    return (this.paymentOptions & PaymentOption.Prepay) === PaymentOption.Prepay;
  }

  isPayByTerminalAvailable() {
    return (this.paymentOptions & PaymentOption.PayByTerminal) === PaymentOption.PayByTerminal;
  }

  canPayInTaxi() {
    if (!this.isFixedPriceDestination) return true;

    if (this.fixedPricePaymentinTaxi === FixedPricePaymentinTaxi.Allowed)
      return true;

    if (this.fixedPricePaymentinTaxi === FixedPricePaymentinTaxi.NotAllowed)
      return false;

    if (this.fixedPricePaymentinTaxi === FixedPricePaymentinTaxi.NotAllowedToFromAirport)
      return this.toAirport || this.fromAirport ? false : true;

    return true;
  }

  writeValue(obj: PaymentOption): void {
    this.selectedValue = obj;

    this.calculateValues();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.onChange(this.selectedValue);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledPaymentOption(disabled: boolean): void {
    if (disabled) {
      if (!this.selectedValue || (this.selectedValue & PaymentOption.HotelPays) !== PaymentOption.HotelPays)
        this.selectedValue = PaymentOption.PayInTaxi;
      this.readOnly = disabled;
    }
    else {
      this.readOnly = disabled;
    }
  }

}
