<div class="content">
    <form [formGroup]="formGroup"
    #secondDestinationForm="ngForm">
        <div class="title">{{'contactInformationTitle' | translate}}</div>
        <div *ngIf="phoneRequired" class="form-group">
            <ion-label>{{ "phoneno" | translate }}</ion-label>
            <ion-input formControlName="phoneNumber"
                    type="text"
                    placeholder="{{'guest_number_placeholder' | translate}}"
                    class="form-controls" input-placeholder></ion-input>
        </div>
        <div *ngIf="phoneNumber" class="alert">
            <div *ngIf="phoneNumber?.errors?.required">
                {{ "phoneNoRequired" | translate }}
            </div>
            <div *ngIf="phoneNumber?.errors?.minlength">
                {{ "phoneNoMinimum" | translate }}
            </div>
            <div *ngIf="phoneNumber?.errors?.pattern">
                {{ "phoneNoValid" | translate }}
            </div>
        </div>

        <div *ngIf="emailRequired" class="form-group">
            <ion-label>{{ "email" | translate }}</ion-label>
            <ion-input formControlName="email"
                        type="text"
                        placeholder="{{'selfServiceStation_emailPlaceholder' | translate}}"
                        class="form-controls" input-placeholder></ion-input>
        </div>                  
        <div class="alert" *ngIf="formGroup.controls['email'].invalid">{{ "selfServiceStation_emailError" | translate }}</div>

        <div class="choiceBox">
            <div class="choiceBoxButtonBox">
                <button class="choiceBoxButton confirm" (click)="yes()"> {{ "confirm" | translate }} </button>
                <br>
                <button class="choiceBoxButton cancel" (click)="no()"> {{ "cancel" | translate }} </button>
            </div>
        </div>
    </form>
</div>
