import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GetTaxiService } from './get-taxi.service';
import { IHotelInfo } from '../models/hotel-info.interface';
import { HotelResponseMapperService } from '../mappers/hotel-response-mapper.service';
import { LoadingController } from '@ionic/angular';
import { ParameterService } from './parameter.service';
import { IDestination } from '../shared/models/destination.interface';
import { ActivatedRoute } from '@angular/router';
import { IPriceInfo, IPriceInfoExchangeStrings } from './models/price-info.model';
import { LogService } from './log.service';
import { Price } from './models/price.model';
import { PaymentOption } from './hotel.service';
import { GetPriceRequest } from '../models/get-price-request.interface';
import { RideType } from '../models/enums';

export enum AirportDirection { toAirport, fromAirport }

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  private hotelId?: string;
  private airportId?: string;
  private accessToken?: string;

  private selectedDate: Date = new Date();
  private airportDirection?: AirportDirection;
  private isLargeTaxi?: boolean;
  subscr$ = new Subject();

  private to?: IDestination;
  private from?: IDestination;
  private paymentOption?: PaymentOption;
  private numberOfCars: number = 1;;
  private selectedTaxiCompany?: string;

  loading!: HTMLIonLoadingElement;
  hotelInfo: IHotelInfo = { address: 'loading...', name: 'loading...', id: '', terminalLocationId: '', zipCode: '', lockedMobileRoutes: false, hasNewOrderingFlow: false, currency: "...loading", language: '', hasHotelSystemIntegration: false, integrationOptions: 0, hotelSettings: 0, displayOptions: 0, noDestinationSettings: 0, selfServicePayInTaxiAllowed: false, hotelSpecialLocations: [{ name: "", address: "" }], country: '', establishmentType: 0, longitude: 0, latitude: 0 };
  loadingTaxiPrice: EventEmitter<boolean>;
  taxiPriceValue?: IPriceInfo;
  taxiPrice: EventEmitter<IPriceInfo>;
  largeTaxiPrice: EventEmitter<{ price: number, currency: string } | undefined>;
  rideType: BehaviorSubject<RideType | undefined> = new BehaviorSubject<RideType | undefined>(undefined);
  currency: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(undefined);
  priceFromTaxiCompany = new BehaviorSubject(false);

  private priceInfo?: IPriceInfo;

  constructor(private getTaxiService: GetTaxiService,
    private parameterService: ParameterService,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private route: ActivatedRoute,
    private log: LogService) {
    this.parameterService.parameters.subscribe(async (params) => {
      if (params === undefined)
        return;

      this.hotelId = params.hotelId;
      this.airportId = params.airportId;
      this.accessToken = params.accessToken;
    });

    this.loadingTaxiPrice = new EventEmitter();
    this.taxiPrice = new EventEmitter();
    this.largeTaxiPrice = new EventEmitter();
  }

  get price(): number | undefined {
    return this.priceInfo?.customerPrice;
  }

  get jsonWebToken(): string | undefined {
    return this.priceInfo?.jsonWebToken;
  }

  get exchangeStrings(): IPriceInfoExchangeStrings | undefined {
    return this.priceInfo?.exchangeStrings;
  }

  get taxiPriceExchangeStrings(): IPriceInfoExchangeStrings | undefined {
    return this.priceInfo?.taxiPriceExchangeStrings;
  }

  get isFixedDestination(): boolean | undefined {
    return this.priceInfo?.isFixedDestination;
  }

  get getPaymentOption(): PaymentOption | undefined {
    return this.paymentOption;
  }

  get localPrice(): Price | undefined {
    return this.exchangeStrings?.priceInLocalCurrency;
  }

  get taxiLocalPrice(): Price | undefined {
    return this.taxiPriceExchangeStrings?.priceInLocalCurrency;
  }

  get priceEuro(): Price | undefined {
    const currentPriceEur = this.exchangeStrings?.priceEuro;

    const roundedPriceEuro: number = Math.round(currentPriceEur?.price ?? 0);

    return roundedPriceEuro != 0 ? new Price(roundedPriceEuro, currentPriceEur!.currencySymbol) : undefined;
  }

  get taxiPriceEuro(): Price | undefined {
    const currentPriceEur = this.taxiPriceExchangeStrings?.priceEuro;

    const roundedPriceEuro: number = Math.round(currentPriceEur?.price ?? 0);

    return roundedPriceEuro != 0 ? new Price(roundedPriceEuro, currentPriceEur!.currencySymbol) : undefined;
  }

  setFrom(from: IDestination, fetchPrice = true): void {
    if (!from?.address) return;

    this.from = from;

    if (fetchPrice)
      this.fetchPriceInfo();
  }

  setPaymentOption(paymentOption: PaymentOption, fetchPriceInfo: Boolean = true): void {
    this.paymentOption = paymentOption;

    if (fetchPriceInfo)
      this.fetchPriceInfo();
  }

  setNumberOfCars(amount: number): void {
    this.numberOfCars = amount;

    this.fetchPriceInfo();
  }

  async setFromHotelAddress(): Promise<void> {

    const params = this.route.snapshot.queryParams;

    const hotelId: string = params.hotelId;
    if (!hotelId) {
      console.error('Hotel id is not defined in the url query params');
      return;
    }

    this.getTaxiService.getHotelInfo(params.hotelId)
      .subscribe(data => {
        this.hotelInfo = HotelResponseMapperService.mapHotelInfoToIHotelInfo(data);

        const from: IDestination = { name: this.hotelInfo.name, address: this.hotelInfo.address };
        this.setFrom(from);
      });
  }

  setTo(to: IDestination, fetchPrice = true, direction: AirportDirection | undefined = undefined): void {
    if (!to?.address) return;

    this.to = to;
    this.airportDirection = direction;

    if (fetchPrice)
      this.fetchPriceInfo();
  }

  setAirportDirection(direction?: AirportDirection): void {
    this.airportDirection = direction;
    this.to = undefined;

    this.fetchPriceInfo();
  }

  setAirport(airportId: string) {
    this.airportId = airportId;

    this.fetchPriceInfo();
  }

  setSelectedDate(date: Date): void {
    this.selectedDate = date;

    this.fetchPriceInfo();
  }

  setIsLargeTaxi(isLargeTaxi: boolean): void {
    this.isLargeTaxi = isLargeTaxi;

    this.fetchPriceInfo();
  }

  setChosenTaxiCompany(taxiCompany: string, fetch: boolean): void {
    this.selectedTaxiCompany = taxiCompany;

    if (fetch)
      this.fetchPriceInfo();
  }

  ngOnDestroy(): void {
    this.subscr$.next();
    this.subscr$.complete();
  }

  public setManualPrice(value?: number): void {
    if (this.priceInfo === undefined || this.priceInfo.customerPrice === undefined
      || this.priceInfo.customerPriceForLargeTaxi === undefined) {
      this.fetchPriceInfo();
    } else {
      this.priceInfo.customerPrice = value;
      this.emitTaxiPriceUpdates();
    }
  }

  public fetchPriceInfo() {
    var serverCall: Observable<any>;

    const getPriceRequest: GetPriceRequest = {
      hotelId: this.hotelId!,
      accessToken: this.accessToken!,
      from: this.from!,
      to: this.to!,
      pickupTime: this.selectedDate,
      // TODO: The following line can be removed once all hotels uses Stripe (or another payment solution where the ride is paid upfront)
      orderType: this.parameterService.orderType,
      largeTaxi: this.isLargeTaxi ?? false,
      paymentOption: this.paymentOption,
      numberOfCars: this.numberOfCars,
      chosenTaxiCompany: this.selectedTaxiCompany,
    };

    if (this.airportDirection !== undefined) {
      const getAirportPriceRequest = { ...getPriceRequest, airportId: this.airportId };

      serverCall = this.getTaxiService.getAirportPrice(getAirportPriceRequest);
    }
    else {
      if (!this.isAbleToFetchPriceWithTheCurrentFromAndToAddresses) return;

      serverCall = this.getTaxiService.getTaxiPrice(getPriceRequest);
    }

    this.loadingTaxiPrice.emit(true);

    serverCall.subscribe(
      (result: IPriceInfo) => {
        this.priceInfo = result;

        this.emitTaxiPriceUpdates();

        this.loadingTaxiPrice.emit(false);

        this.rideType.next(result.rideType);
        this.currency.next(result.currency);
      },
      error => {
        this.priceInfo = undefined;

        this.loadingTaxiPrice.emit(false);

        this.log.error("Price service error", error);
        throw new Error(error);
      }
    );
  }

  get isAbleToFetchPriceWithTheCurrentFromAndToAddresses(): boolean {
    if (this.from?.address && this.to?.address)
      return true;
    else
      return false;
  }

  private emitTaxiPriceUpdates(): void {
    if (!this.priceInfo) {
      this.log.error('Price info is undefined and should then fetched first before emitting the price updates')
      throw new Error('Price info is undefined and should then fetched first before emitting the price updates');
    }

    this.priceInfo.exchangeStrings = this.createExchangeStrings(this.priceInfo);
    this.priceInfo.taxiPriceExchangeStrings = this.createPayInTaxiExchangeStrings(this.priceInfo);

    this.taxiPriceValue = this.priceInfo;
    this.taxiPrice.emit(this.priceInfo);

    if (this.isLargeTaxi && this.priceInfo?.customerPriceForLargeTaxi) {
      this.largeTaxiPrice.emit({ price: this.priceInfo.customerPriceForLargeTaxi, currency: this.priceInfo.currency });
    } else {
      this.largeTaxiPrice.emit();
    }
  }

  private createExchangeStrings(priceInfo: IPriceInfo): IPriceInfoExchangeStrings {
    const priceInfoExchangeStrings: IPriceInfoExchangeStrings = {};

    if (priceInfo.customerPrice) {
      if (!priceInfo.exchangePricesTable) throw new Error("Exchange prices table is empty or undefined");
      priceInfoExchangeStrings.priceValue = priceInfo.customerPrice;

      if (!priceInfo.exchangePricesTable[priceInfo.currency] || !priceInfo.exchangePricesTable["EUR"]) {
        this.log.error("Currency not found in price table", priceInfo);
        throw new Error('Currency not found in price table');
      };

      priceInfoExchangeStrings.priceInLocalCurrency = new Price(priceInfo.exchangePricesTable[priceInfo.currency], priceInfo.currency);

      priceInfoExchangeStrings.priceEuro = new Price(priceInfo.exchangePricesTable["EUR"], "€");

    } else {
      // If the hotel does not calculate the price (not UsingFixedPriceRides), then do nothing and return default values

      priceInfoExchangeStrings.priceValue = 0;
      priceInfoExchangeStrings.priceInLocalCurrency = new Price(0, priceInfo.currency);
      priceInfoExchangeStrings.priceEuro = new Price(0, "€");
    }

    return priceInfoExchangeStrings;
  }

  private createPayInTaxiExchangeStrings(priceInfo: IPriceInfo): IPriceInfoExchangeStrings {
    const priceInfoExchangeStrings: IPriceInfoExchangeStrings = {};

    if (priceInfo.taxiPrice) {
      if (!priceInfo.taxiPriceExchangePricesTable) throw new Error("Exchange prices table is empty or undefined");

      priceInfoExchangeStrings.priceValue = priceInfo.taxiPrice;

      if (!priceInfo.taxiPriceExchangePricesTable[priceInfo.currency] || !priceInfo.taxiPriceExchangePricesTable["EUR"]) {
        this.log.error("Currency not found in price table", priceInfo);
        throw new Error('Currency not found in price table');
      };

      priceInfoExchangeStrings.priceInLocalCurrency = new Price(priceInfo.taxiPriceExchangePricesTable[priceInfo.currency], priceInfo.currency);
      priceInfoExchangeStrings.priceEuro = new Price(priceInfo.taxiPriceExchangePricesTable["EUR"], "€");

    } else {
      // If the hotel does not calculate the price (not UsingFixedPriceRides), then do nothing and return default values

      priceInfoExchangeStrings.priceValue = 0;
      priceInfoExchangeStrings.priceInLocalCurrency = new Price(0, priceInfo.currency);
      priceInfoExchangeStrings.priceEuro = new Price(0, "€");
    }

    return priceInfoExchangeStrings;
  }

  async presentLoading(message: string): Promise<void> {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant(message),
    });
    await this.loading.present();
  }

  async hideLoading(): Promise<void> {
    await this.loading.dismiss();
  }
}
