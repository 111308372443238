import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();

    return value.filter(function (data: any) {
      // return JSON.stringify(data).toLowerCase().includes(args);
      return data?.roomNumber?.toLowerCase().includes(args) || data?.phoneNumber?.toLowerCase().includes(args) || data?.bookingNumber?.toLowerCase().includes(args) || data?.guestName?.toLowerCase().includes(args)
    });
  }

}
