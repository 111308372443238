<div class="main">
   <h1 class="header">
      <img src="assets/images/logo.svg"
           alt="logo"
           class="logo">
      <span>Receipt</span>
   </h1>
   <div class="main__data"
        *ngIf="rideInfo">
      <div class="data-flex">
         <div *ngIf="rideInfo.taxiReceiptFor"
              class="first-line"><span>Taxi receipt for:&nbsp;</span><span>{{rideInfo.taxiReceiptFor}}</span>
         </div>
         <div *ngIf="rideInfo.orderId"
              class="second-line">
            <div><span>Order:&nbsp;</span>{{rideInfo.orderId}}</div>
            <div><span>Date:&nbsp;</span>{{rideInfo.date.split('T')[0]}}</div>
         </div>
      </div>
      <div><span>Room number: </span>{{rideInfo.roomNumber}}</div>
      <div><span>From:&nbsp;</span>{{rideInfo.fromAddress}}</div>
      <div><span>To:&nbsp;</span>{{rideInfo.toAddress}}</div>
      <div class="phone">
         <div *ngIf="rideInfo.phoneNumber">{{rideInfo.phoneNumber}}</div>
      </div>

      <div class="data-flex">
         <div><span>Service</span></div>
         <div><span>Price</span></div>
      </div>

      <div class="data-flex bord">
         <div>Taxi ride</div>
         <div>{{rideInfo.price}} {{rideInfo.currency}}</div>
      </div>

      <div class="left-data">
         <div>Sub-total: {{rideInfo.subTotal}} {{rideInfo.currency}}</div>
         <div class="bord"><span>Total: {{rideInfo.price}} {{rideInfo.currency}}</span></div>
      </div>
   </div>

   <div class="m-70">
      <span class="thank">THANK YOU!</span>
   </div>
   <a href="{{getUrl()}}"
      download="">Download</a>
   <footer>
      <div class="footer">
         <div>CommutApp Aps</div>
         <div>Sct. Mathias Gade 38, 2 8800 Viborg</div>
         <div>+ 45 40 500 720</div>
      </div>
   </footer>


</div>