<app-header></app-header>
<div class="taxiHotel">
    <div class="container">
        <div class="bill">
            <div class="item">
                <div class="box">
                    <div>
                        <b *ngIf="room?.bookingNumber">{{'resNo' | translate}}: {{room?.bookingNumber}}</b>
                    </div>
                    <div>
                        <b *ngIf="room?.roomNumber">{{'roomNo2' | translate}}: {{room?.roomNumber}}</b>
                    </div>
                    <div>
                        <b *ngIf="room?.guestName">{{'bill-guestName' | translate}}: {{room?.guestName}}</b>
                    </div>
                    <div>
                        <b *ngIf="room?.phoneNumber">{{'tel' | translate}}: {{room?.phoneNumber}}</b>
                    </div>
                    <div>
                        <b *ngIf="room?.flightNumber">{{'bill-flightNumber' | translate}}: {{room?.flightNumber}}</b>
                    </div>
                    <div>
                        <b *ngIf="room?.flightArrivalTime">{{'bill-flightArrivalTime' | translate}}: {{room?.flightArrivalTime | date:'dd/MM/yy HH:mm'}}</b>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="box">
                </div>
            </div>
        </div>
        <div class="korsel">
            <h3>{{'trip' | translate}}</h3>
            <div class="item-container" *ngFor="let ride of room?.rides">
            <div class="item">
                <div class="box">
                    <button mat-button
                            color="primary"
                            *ngIf="ride.applied==null && !ride.prepaid && canApply()"
                            (click)="applyRide(ride)"
                            title="{{ 'applyToBillDescription' | translate }}">
                        <mat-icon>check</mat-icon>
                    </button>
                    <button mat-button
                            color="primary"
                            *ngIf="(ride.applied!=null || ride.prepaid) && canApply()"
                            (click)="applyRide(ride)"
                            title="{{ 'appliedToBillDescription' | translate }}">
                        <mat-icon>check_circle</mat-icon>
                    </button>
                    <button mat-button
                            color="primary"
                            *ngIf="canApply()"
                            (click)="deleteRide(ride)"
                            title="{{ 'removeFromBillDescription' | translate }}">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                </div>
                <div class="box">
                    <span class="date">{{ride?.pickUpTime + 'z' | date:'dd/MM/yy HH:mm'}}<br /><span class="small">{{ 'bill-rideCreatedAt' | translate }} {{ride?.createdAt + 'z' | date:'dd/MM/yy HH:mm'}}</span></span>
                </div>
                <div class="box">
                    <span class="price">{{ride?.customerPrice}} {{currency}}</span>
                </div>
                <div class="box"
                     *ngIf="ride?.sharedRideSavings != null">
                    <span class="text blue">taxi {{'shared' | translate}}</span>
                </div>
                <div class="box"
                     *ngIf="ride?.sharedRideSavings != null">
                    <span class="price blue">-{{ride?.sharedRideSavings}} {{currency}}</span>
                </div>
            </div>
                <div class="item-total">
                    <div>Count of car: {{ride?.numberOfCars}}</div>
                    <div>Total price: {{ride?.totalPrice}} {{currency}}</div>
                </div>
            </div>
        </div>

        <div class="total">
            <div class="container">
                <div class="item">
                    <div class="box">
                        <b>Total =</b>
                    </div>
                    <div class="box">
                        <b>{{total}} {{currency}}</b>
                    </div>
                </div>
                <div class="action">
                    <div class="box">
                        <button mat-flat-button
                                color="primary"
                                (click)="goToBillHistory()">
                            <mat-icon>keyboard_arrow_left</mat-icon>
                            {{'back' | translate}}
                        </button>
                    </div>
                    <div class="box">
                        <button *ngIf="!room?.applied && !room?.prepaid && canApply()"
                                mat-stroked-button
                                color="primary"
                                (click)="applyRides()">{{'confirmApplyToRoom' | translate}}</button>
                        <span *ngIf="room?.applied && !room?.appliedByReceptionistInitials"
                              class="confirm">{{'isAddedToRoom' | translate | replace: "date":(room?.rides[0].applied ? room?.rides[0].applied : currentDate + 'z' | date:'dd/MM/yy HH:mm')}}</span>
                        <span *ngIf="room?.applied && room?.appliedByReceptionistInitials"
                              class="confirm">{{'isAddedToRoomBy' | translate | replace: "initials":room?.appliedByReceptionistInitials | replace: "date":(room?.rides[0].applied + 'z' | date:'dd/MM/yy HH:mm') }}</span>
                        <span  *ngIf="room?.prepaid"
                              class="confirm">{{'isPrepaid' | translate}}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
