import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoadingController } from '@ionic/angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RideService } from 'src/app/services/ride.service';

export interface PaidPopupEmailComponentArgs {
  rideId: string;
}

@Component({
  selector: 'app-paid-popup-email',
  templateUrl: './paid-popup-email.component.html',
  styleUrls: ['./paid-popup-email.component.scss']
})

export class PaidPopupEmailComponent {
  formGroup: FormGroup = new FormGroup({ email: new FormControl('', [Validators.email]) });

  loading!: HTMLIonLoadingElement;
  rideId: string = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: PaidPopupEmailComponentArgs, public dialogRef: MatDialogRef<PaidPopupEmailComponent>, private rideService: RideService, private loadingController: LoadingController, private translate: TranslateService) {
    this.rideId = data.rideId;
  }

  onClick() {
    this.presentLoading();
    this.rideService.sendReceiptEmail(this.rideId, this.formGroup.get('email')?.value).subscribe(async (data) => {
      await this.hideLoading();
    }, async (error) => {
      await this.hideLoading();
    });
  }
  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: this.translate.instant('pleaseWait'),
    });
    await this.loading.present();
  }

  async hideLoading() {
    await this.loading.dismiss();
  }

}
